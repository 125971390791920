import { useQuery } from "@tanstack/react-query";
import { Button } from "antd";
import { useContext } from "react";
import { Term } from "./models";
import { SummaryServiceContext } from "./ProgressSummary";
import { exportToXLSX } from "./utils/export";
import {usePublicClientConfig} from "./services/PublicClientConfigService";
import { format } from 'date-fns';

interface TermSummaryExportProps {
    term: Term
}

export default function TermSummaryExport({ term }: TermSummaryExportProps) {

    const summaryService = useContext(SummaryServiceContext);
    const publicClientConfig = usePublicClientConfig();

    const { refetch, isFetching } = useQuery(["summaryForTerm", term?.canvasId],
        () => {
            return summaryService?.getCourseProgressForTerm(term?.canvasId, publicClientConfig.includedInTermSummaryReportRoles);
        }, {
            enabled: false,
            onSuccess(courses) {
                if (courses) {
                    const localISODate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
                    exportToXLSX(courses, term?.sisTermId + '_CourseProgress_' + localISODate);
                }
            },
        });

    return (
        <Button onClick={(term)? () => refetch() : undefined} loading={isFetching}>Download course progress data</Button>
    )
}