import {useRef, useState} from "react";
import {Button} from "antd";
import useIsOverflowing from "../utils/useIsOverflowing";
import './ExpandableElement.css';

type ExpandableElementProps = React.PropsWithChildren<{}> &{
    overflowHeight?: string;
    overflowWidth?: string;
}

const ExpandableElement = (props: ExpandableElementProps) => {
    const [expanded, setExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const isOverflowing = useIsOverflowing(ref);
    
    const elementClassName = expanded
        ? "expandable-element-content-expanded"
        : isOverflowing
            ? "expandable-element-content-collapsed"
            : "";
    
    return (
        <div className={"expandable-element"}>
            <div
                style={!expanded ? { maxWidth: props.overflowWidth, maxHeight: props.overflowHeight } : {}}
                className={elementClassName}
                ref={ref}>
                {props.children}
            </div>
            <div className={"expandable-element-expand-btn"}>
                {isOverflowing && <Button type={"link"} size={'small'} onClick={() => setExpanded(true)}>Expand</Button>}
            </div>
        </div>
    );
}

export default ExpandableElement;