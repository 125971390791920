import {Button, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {CheckCircleOutlined, TeamOutlined} from "@ant-design/icons";
import "./courseadmintoollist.css";
import Message from "./Message";

type CourseAdminToolListProps = {
    courseId: number,
}

const CourseAdminToolList = (props: CourseAdminToolListProps) => {
    const navigate = useNavigate();

    return <Space direction="vertical" size="large" className="course-admin-tool-list">
        <div>The following tools are available to you to help manage your course:</div>
        <Space direction="horizontal">
            <Button
                size="large"
                icon={<TeamOutlined/>}
                onClick={() => {
                    navigate(`/managestaff/${props.courseId}`)
                }}>Manage Staff</Button>
            <Button
                size="large"
                icon={<CheckCircleOutlined/>}
                onClick={() => {
                    navigate(`/availability/${props.courseId}`)
                }}>Publish the Course</Button>
        </Space>
       <Message messageKey={"contactInformation"}/>
    </Space>
}

export default CourseAdminToolList;