import {Alert, List, Space} from "antd";
import "./copycontentconfirm.css"

export interface CopyContentConfirmProps {
    fromName: string,
    fromTerm?: string,
    fromSisId?: string,
    toName: string,
    toTerm?: string,
    toSisId?: string,
    error?: string,
}

export const CopyContentConfirm = ({...props}: CopyContentConfirmProps) => {
    return <Space direction="vertical" className="copy-content-confirm">
        {props.error ? <Alert type="error" message={props.error} showIcon/> : <></>}
        <div className="copy-content-confirm-to-label">To course:</div>
        <List>
            <List.Item>
                <List.Item.Meta title={props.toName}
                                description={
                                    <><span>{props.toTerm}</span><span>{props.toSisId}</span></>
                                }/>
            </List.Item>
        </List>
        <div className="copy-content-confirm-from-label">From course:</div>
        <List>
            <List.Item>
                <List.Item.Meta title={props.fromName}
                                description={
                                    <><span>{props.fromTerm}</span><span>{props.fromSisId}</span></>
                                }/>
            </List.Item>
        </List>
    </Space>
}