import {AuditOutlined, PieChartOutlined, PlusCircleOutlined, SettingOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Alert, Button, Layout, Menu, Result, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import './App.css';
import CourseService from './services/CourseService'
import {useSession} from './services/SessionService'
import CourseSetupOverview from "./CourseSetupOverview";
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import ReferenceDataService from "./services/ReferenceDataService";
import {LocalDataService} from "./services/LocalDataService";
import AccountService from "./services/AccountService";
import CourseAvailabilityService from "./services/CourseAvailabilityService";
import UserService from "./services/UserService";
import RoleService from "./services/RoleService";
import StaffEnrolment from "./StaffEnrolment";
import {useQuery} from "@tanstack/react-query";
import {AdminRole, Course, CourseMenuItem, PublicClientConfig, SystemAdminRole} from "./models";
import {CourseAvailabilityAgreementList} from "./CourseAvailabilityAgreementList";
import {SiteManagement} from "./SiteManagement"
import StandaloneCourseManager from "./StandaloneCourseManager";
import CourseAdminToolList from "./CourseAdminToolList";
import {AuditLogs} from "./AuditLogs";
import AuditLogsService from "./services/AuditLogsService";
import {FeatureFlag, initFeatures} from "./featureflags";
import {Summary} from './ProgressSummary';
import SummaryService from './services/SummaryService';
import {CoursesListProvider, useCoursesListDispatch} from "./CoursesContext";
import {calculateCourseMenuItemIcon} from "./icons/icons";
import CourseStateMessage from './CourseStateMessage';
import CourseStateTag from "./components/CourseStateTag";
import Message from "./Message";
import SectionUploadNotifications from "./components/SectionUploadNotifications";
import {SectionUploadsProvider} from "./SectionUploadsContext";

const {Content, Footer, Sider} = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    onClick: () => void,
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        onClick,
    } as MenuItem;
}


const samFlags: FeatureFlag[] = [
    {name: 'addmirroredsection', description: 'Add a Mirrored section to a course.', active: false},
];

const calculateCourseMenuKey = (name: string) => {
    return `courses-${name.toLowerCase().replace(' ', '-')}`;
}

const useSelectedMenuKey = (courseMenu: CourseMenuItem[]) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const path = location.pathname;
    if (path.startsWith("/courses")) {
        const siteTypes = searchParams.get("siteTypes");
        const courseMenuItem = courseMenu.find((m => m.siteTypes.join(",") === siteTypes));
        if (courseMenuItem) {
            return calculateCourseMenuKey(courseMenuItem.name);
        }
    }
    if (path.startsWith("/auditlogs")) {
        return "auditlogsmenu";
    }
    if (path.startsWith("/summary")) {
        return "summarymenu"
    }
    if (path.startsWith("/sitemanagement")) {
        return "sitemanagementmenu";
    }
    return undefined;
};


type AppProps = {
    courseService: CourseService;
    accountService: AccountService;
    userService: UserService;
    roleService: RoleService;
    referenceDataService: ReferenceDataService;
    localDataService: LocalDataService;
    courseAvailabilityService: CourseAvailabilityService;
    auditLogsService: AuditLogsService;
    summaryService: SummaryService;
    publicClientConfig: PublicClientConfig;
}


function getCourseMenuItemForKey(courseMenu: CourseMenuItem[], selectedMenuKey: string | undefined) {
    if (!selectedMenuKey) {
        return undefined;
    }
    for (const courseMenuItem of courseMenu) {
        if (selectedMenuKey === calculateCourseMenuKey(courseMenuItem.name)) {
            return courseMenuItem;
        }
    }
    return undefined;
}

export default function App(props: AppProps) {
    initFeatures(samFlags);

    const courseMenu = props.publicClientConfig.courseMenu ?? [];
    const selectedMenuKey = useSelectedMenuKey(courseMenu);
    const selectedCourseMenuItem: CourseMenuItem | undefined = getCourseMenuItemForKey(courseMenu, selectedMenuKey);

    return (
        <SectionUploadsProvider>
            <SectionUploadNotifications>
                <CoursesListProvider>
                    <Routes>
                        <Route path={"/"} element={<RootContent{...{...props, selectedMenuKey: selectedMenuKey}} />}>
                            <Route index={true} element={<CourseRedirect {...props} />}></Route>
                            <Route path={"courses"} element={<CourseSetupOverview {...{
                                ...props,
                                hideYearAndTermFilters: selectedCourseMenuItem?.hideYearAndTermFilters
                            }} />}>
                                <Route path={":courseId/:operation"}/>
                                <Route path={":courseId/sections/:sectionId/:operation"}/>
                            </Route>
                            <Route path={"course/:courseId"} element={<ManageCourseContent
                                embeddedInCanvas={false} {...props} urlStem="/course"/>}>
                                <Route path={":operation"}/>
                                <Route path={"sections/:sectionId/:operation"}/>
                            </Route>
                            <Route path={"sitemanagement"} element={<SiteManagementContent {...props} />}/>
                            <Route path={"summary"} element={<Summary summaryService={props.summaryService}/>}></Route>
                            <Route path={"auditlogs"} element={<AuditLogs auditLogsService={props.auditLogsService}/>}/>
                        </Route>
                        {/* LTI Tool paths */}
                        <Route path={"/availability/:courseId"} element={<AvailabilityContent {...props} />}/>
                        <Route path={"/managestaff/:courseId"} element={<ManageStaffContent {...props} />}/>
                        <Route path={"/managecourse/:courseId"}
                               element={<ManageCourseContent embeddedInCanvas={true} {...props}  urlStem="/managecourse"/>}>
                            <Route path={":operation"}/>
                            <Route path={"sections/:sectionId/:operation"}/>
                        </Route>
                    </Routes>
                </CoursesListProvider>
            </SectionUploadNotifications>
        </SectionUploadsProvider>
    );
}

const calculateCourseMenuItemPath = (item: CourseMenuItem, basepath: string) => {
    const siteTypesQueryString = item.siteTypes.join(',');
    let query = `siteTypes=${siteTypesQueryString}`;
    if (item.hideYearAndTermFilters) {
        query += '&selectedYear=allyears&selectedTerm=allterms';
    }
    if (item.defaultAccountId) {
        query += `&selectedAccount=${item.defaultAccountId}`;
    }
    return `${basepath}?${query}`;
}

const RootContent = (props: AppProps & { selectedMenuKey?: string }) => {

    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const session = useSession();

    const courseMenu = props.publicClientConfig.courseMenu ?? [];


    const buildMenuItemFromCourseMenuItem = (item: CourseMenuItem, basepath: string): MenuItem => {
        const key = calculateCourseMenuKey(item.name);
        const url = calculateCourseMenuItemPath(item, basepath);
        const icon = calculateCourseMenuItemIcon(item.icon);
        return getItem(
            () => {
                navigate(url);
            },
            item.name,
            key,
            icon,
        );
    };

    const items: MenuItem[] = [
        ...courseMenu.map(item => buildMenuItemFromCourseMenuItem(item, '/courses')),
        getItem(() => {
            navigate('/summary');
        }, 'Progress Summary', 'summarymenu', <PieChartOutlined/>),
        getItem(() => {
            navigate('/auditlogs');
        }, 'Audit Records', 'auditlogsmenu', <AuditOutlined/>),
        getItem(() => {
            navigate('/sitemanagement');
        }, 'Add new site', 'sitemanagementmenu', <PlusCircleOutlined/>),
        ...(session.samRoles.includes(SystemAdminRole) ? [getItem(() => {
            window.location.href = '/admin/';
        }, 'System Admin', 'systemadmin', <SettingOutlined/>)] : [])
    ];

    return <Layout className="app-layout">
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
            <div className="logo"/>
            <Menu theme="dark" selectedKeys={props.selectedMenuKey ? [props.selectedMenuKey] : undefined} mode="inline"
                  items={items}/>
        </Sider>
        <Layout className="content-layout">
            <Content>
                <Outlet/>
            </Content>
            <Footer>©2023 All the Ducks Pty Ltd</Footer>
        </Layout>
    </Layout>;
}

const CourseRedirect = (props: AppProps) => {
    const courseMenu = props.publicClientConfig.courseMenu ?? [];
    const topItem = courseMenu?.[0];
    const url = calculateCourseMenuItemPath(topItem, "/courses");

    return <Navigate to={url} replace={true}/>;
}

const ManageStaffContent = (props: AppProps) => {
    const {courseId} = useParams();

    const {data: course, isFetching: fetchingCourse} = useQuery<Course, Error>(["course", courseId],
        () => {
            return props.courseService.getCourse(+courseId!);
        });

    return <Content>
        <Space direction="vertical" size="large">
            {course?.setupState && <CourseStateTag state={course.setupState} roles={[AdminRole]} />}
            <CourseStateMessage course={course} />
            <Alert
                description={<Message messageKey={"staffEnrolmentToolHelpText"}/>}
                type="info"
                showIcon
            />
            <StaffEnrolment fetchingCourse={fetchingCourse}
                            canvasCourse={course}
                            type="standalone"
                            courseService={props.courseService}
                            userService={props.userService}
                            roleService={props.roleService}
            />
        </Space>
    </Content>;
}

const AvailabilityContent = (props: AppProps) => {
    const {courseId} = useParams();

    const {data: course, refetch: refetchCourse} = useQuery<Course, Error>(["course", courseId],
        () => {
            return props.courseService.getCourse(+courseId!);
        });

    return <Content>
        <Space direction={"vertical"} size="large">
            {course?.setupState && <CourseStateTag state={course.setupState} roles={[AdminRole]}/>}
            <CourseAvailabilityAgreementList
                courseAvailabilityService={props.courseAvailabilityService}
                courseService={props.courseService}
                course={course}
                type={"standalone"}
                onCourseMadeAvailable={() => refetchCourse()}
            />
        </Space>
    </Content>

}

const SiteManagementContent = (props: AppProps) => {
    return <SiteManagement courseService={props.courseService}
                           courseAvailabilityService={props.courseAvailabilityService}
                           roleService={props.roleService}
                           userService={props.userService}/>
}

const ManageCourseContent = (props: AppProps & { urlStem: string, embeddedInCanvas: boolean }) => {

    const {courseId} = useParams();
    const session = useSession();

    const location = useLocation();
    const showCreationSuccessMessage = !!location?.state?.showCreationSuccessMessage;

    const navigate = useNavigate();

    const courseListDispatch = useCoursesListDispatch()

    useEffect(() => {
        if (!courseId || Number.isNaN(courseId)) {
            return;
        }
        const courseIdNum = Number.parseInt(courseId)
        courseListDispatch({name: "SetExpandedCourseId", courseId: courseIdNum});
    }, [courseId, courseListDispatch])

    let content;
    if (!courseId) {
        content = <Result status="error"/>;
    } else if (session.samRoles.includes(AdminRole)) {
        content = <Space direction="vertical" style={{width: "100%"}}>
            {showCreationSuccessMessage ? <Alert
                message="Success"
                description="Site was created successfully"
                type="success"
                showIcon
                className="site-management-alert"
                action={<Button onClick={() => {
                    navigate("/sitemanagement");
                }}>Add another site</Button>}
                closable={true}
                onClose={() => {
                    navigate(location, {replace: true, state: {...location.state, showCreationSuccessMessage: false}});
                }}
            /> : <></>}
            <StandaloneCourseManager courseId={+courseId}
                                     courseAvailabilityService={props.courseAvailabilityService}
                                     courseService={props.courseService}
                                     userService={props.userService}
                                     roleService={props.roleService}
                                     embeddedInCanvas={props.embeddedInCanvas}
                                     urlStem={props.urlStem}/>
        </Space>
    } else {
        content = <CourseAdminToolList courseId={+courseId}/>
    }

    return content;

}