import StaffEnrolmentListUsers from './StaffEnrolmentListUsers';
import CourseService from "./services/CourseService";
import {Course, Enrollment, Role, User} from './models';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Button, Modal, Result, Space, Spin} from 'antd';
import UserService from './services/UserService';
import {PlusOutlined} from "@ant-design/icons";
import {ReactNode, useReducer} from "react";
import StaffEnrolmentManageEnrolment from "./StaffEnrolmentManageEnrolment";
import RoleService from "./services/RoleService";
import ErrorAlert from './ErrorAlert';
import './staffenrolment.css'
import SuccessAlert from './SuccessAlert';
import Message from "./Message";

export type StaffEnrolmentProps = CommonProps & (ModalProps | StandaloneProps)

type CommonProps = {
    fetchingCourse: boolean;
    canvasCourse?: Course,
    courseService: CourseService;
    userService: UserService;
    roleService: RoleService;
    onClose?: () => void;
}

type ModalProps = {
    type: "modal",
    open: boolean,
    onCloseAnimationComplete?: () => void;
}


type StandaloneProps = {
    type: "standalone"
}

type StaffEnrolmentState = {
    operation: "list" | "add" | "edit"
    userSearchTerm?: string;
    selectedCanvasUserId?: number;
    selectedCanvasRoleId?: number;
    deletingEnrollmentIds: Set<number>;
    editingEnrolment?: Enrollment;
    isCreateEnrolmentSuccess: boolean;
    isDeleteEnrolmentSuccess: boolean;
    isChangeEnrolmentSuccess: boolean;
}

type DisplayListUsers = {
    name: "DisplayListUsers";
}
type DisplayAddUser = {
    name: "DisplayAddUser";
}
type DisplayChangeRole = {
    name: "DisplayChangeRole";
    enrolment: Enrollment;
}
type SearchUsers = {
    name: "SearchUsers";
    searchTerm: string | undefined;
}
type SelectUser = {
    name: "SelectUser";
    canvasUserId?: number;
}
type SelectRole = {
    name: "SelectRole";
    canvasRoleId?: number;
}
type AddDeletingEnrollment = {
    name: "AddDeletingEnrollment",
    enrollmentId: number;
}
type RemoveDeletingEnrollment = {
    name: "RemoveDeletingEnrollment",
    enrollmentId: number;
}
type StaffEnrolmentAction =
    | DisplayListUsers
    | DisplayAddUser
    | DisplayChangeRole
    | SearchUsers
    | SelectUser
    | SelectRole
    | AddDeletingEnrollment
    | RemoveDeletingEnrollment

function staffEnrolmentReducer(state: StaffEnrolmentState, action: StaffEnrolmentAction): StaffEnrolmentState {
    switch (action.name) {
        case "DisplayListUsers":
            return {
                ...state,
                operation: "list",
                userSearchTerm: undefined,
                selectedCanvasUserId: undefined,
                selectedCanvasRoleId: undefined,
                editingEnrolment: undefined,
            }
        case "DisplayAddUser":
            return {
                ...state,
                operation: "add",
                isCreateEnrolmentSuccess: false,
                isDeleteEnrolmentSuccess: false,
                isChangeEnrolmentSuccess: false
            }
        case "DisplayChangeRole":
            return {
                ...state,
                operation: "edit",
                editingEnrolment: action.enrolment,
                selectedCanvasRoleId: action.enrolment.roleId,
                selectedCanvasUserId: action.enrolment.userId,
                isCreateEnrolmentSuccess: false,
                isDeleteEnrolmentSuccess: false,
                isChangeEnrolmentSuccess: false
            }
        case "SearchUsers":
            return {
                ...state,
                userSearchTerm: action.searchTerm,
            }
        case "SelectUser":
            return {
                ...state,
                selectedCanvasUserId: action.canvasUserId,
            }
        case "SelectRole":
            return {
                ...state,
                selectedCanvasRoleId: action.canvasRoleId,
            }
        case "AddDeletingEnrollment":
            const addingSet = new Set(state.deletingEnrollmentIds);
            addingSet.add(action.enrollmentId)
            return {
                ...state,
                deletingEnrollmentIds: addingSet,
                isCreateEnrolmentSuccess: false,
                isDeleteEnrolmentSuccess: false,
                isChangeEnrolmentSuccess: false
            }
        case "RemoveDeletingEnrollment":
            const removingSet = new Set(state.deletingEnrollmentIds);
            removingSet.delete(action.enrollmentId)
            return {
                ...state,
                deletingEnrollmentIds: removingSet,
                isCreateEnrolmentSuccess: false,
                isDeleteEnrolmentSuccess: true,
                isChangeEnrolmentSuccess: false
            }
    }
}

export default function StaffEnrolment(props: StaffEnrolmentProps) {
    const initialState: StaffEnrolmentState = {
        operation: "list",
        deletingEnrollmentIds: new Set(),
        isCreateEnrolmentSuccess: false,
        isDeleteEnrolmentSuccess: false,
        isChangeEnrolmentSuccess: false
    };

    const [state, dispatch] = useReducer(staffEnrolmentReducer, initialState);
    let errors: Error[] = [];

    const canvasCourseId = props.canvasCourse?.canvasId;
    const {
        data: enrollments,
        isFetching: fetchingEnrollments,
        refetch: refetchEnrolments,
        isError: isGetEnrollmentsError,
        error: getEnrollmentsError
    } = useQuery<Enrollment[], Error>(
        ["enrollments", canvasCourseId],
        async () => {
            if (canvasCourseId) {
                return await props.courseService.getCourseEnrollments(canvasCourseId);
            } else {
                throw new Error("Current course ID is null");
            }
        }, {
            enabled: !!canvasCourseId,
        }
    );
    getEnrollmentsError && errors.push(getEnrollmentsError);

    const {
        data: roles,
        isFetching: fetchingRoles,
        isError: isGetRolesError,
        error: getRolesError
    } = useQuery<Role[], Error>(
        ["roles", props.canvasCourse?.canvasId],
        async () => {
            return await props.roleService.getAllRolesForAccount(props.canvasCourse!.canvasId);
        }, {
            enabled: !!props.canvasCourse?.canvasId,
        }
    );
    getRolesError && errors.push(getRolesError);

    const {
        data: searchUsers,
        isFetching: fetchingUsers,
        isError: isSearchUsersError,
        error: searchUsersError
    } = useQuery<User[], Error>
    (["usersearch", state.userSearchTerm],
        async () => state.userSearchTerm && state.userSearchTerm.length >= 2 ? props.userService.searchUsers(state.userSearchTerm) : [],
    );
    searchUsersError && errors.push(searchUsersError);

    const {
        mutate: createEnrolment,
        isLoading: createEnrolmentIsLoading,
        isError: isCreateEnrolmentError,
        error: createEnrolmentError
    } = useMutation(
        ["createEnrolment", props.canvasCourse?.canvasId],
        (vars: {
            selectedCanvasUserId: number,
            selectedCanvasRoleId: number
        }) => props.courseService.addCourseEnrollment(props.canvasCourse!.canvasId, vars.selectedCanvasUserId, vars.selectedCanvasRoleId),
    );
    createEnrolmentError && createEnrolmentError instanceof Error && errors.push(createEnrolmentError);

    const {
        mutate: deleteEnrolment,
        isLoading: deleteEnrolmentIsLoading,
        isError: isDeleteEnrollmentError,
        error: deleteEnrollmentError
    } = useMutation(
        ["deleteEnrolment", props.canvasCourse?.canvasId],
        (vars: { enrollmentId: number }) => props.courseService.deleteCourseEnrollment(props.canvasCourse!.canvasId, vars.enrollmentId),
    );
    deleteEnrollmentError && deleteEnrollmentError instanceof Error && errors.push(deleteEnrollmentError);

    let filteredSearchUsers: User[] = [];
    if (enrollments && searchUsers) {
        const enrolmentUsers = new Set(enrollments.map(e => e.userId));
        filteredSearchUsers = searchUsers.filter(u => !enrolmentUsers.has(u.canvasId));
    }

    let contactInformationMessage: ReactNode = props.type === "standalone" ? <Message messageKey={"contactInformation"} />: null;
    let body;
    let buttons = props.onClose ? <Button type="primary" onClick={props.onClose}>Done</Button> : <></>;
    if (props.fetchingCourse || !props.canvasCourse) {
        body = <Spin/>
    } else if (props.canvasCourse.concluded) {
        body = <>
            <Result status="warning">{props.canvasCourse.name} is concluded.<br /><Message messageKey={"staffEnrollmentToolsCourseConcluded"} /></Result>
            {contactInformationMessage}
        </>;
    } else {
        switch (state.operation) {
            case "list":
                body = <>
                    <SuccessAlert isSuccess={state.isCreateEnrolmentSuccess} message={"User successfully added"}/>
                    <SuccessAlert isSuccess={state.isDeleteEnrolmentSuccess} message={"User successfully removed"}/>
                    <SuccessAlert isSuccess={state.isChangeEnrolmentSuccess}
                                  message={"User's role successfully changed"}/>
                    <ErrorAlert
                        isError={isGetEnrollmentsError || isGetRolesError}
                        errorSummary={'Failed to access course staff users due to an error in Canvas'}
                        source={'StaffEnrollment->list'} errors={errors}
                    />
                    <ErrorAlert
                        isError={isDeleteEnrollmentError}
                        errorSummary={'Failed to remove staff user from the course due to an error in Canvas'}
                        source={'StaffEnrollment->list'} errors={errors}
                    />
                    <div className='course-name'>
                        {props.type === "modal" ? <span>{props.canvasCourse.name}</span> : <></>}
                        <Button
                            type="primary"
                            onClick={() => dispatch({name: "DisplayAddUser"})}>
                            <PlusOutlined/> Add Staff
                        </Button>
                    </div>
                    {fetchingEnrollments || fetchingRoles ?
                        <Spin/> :
                        <StaffEnrolmentListUsers
                            enrollments={enrollments}
                            roles={roles}
                            onChangeEnrollment={(enrolment) => dispatch({name: "DisplayChangeRole", enrolment})}
                            onDeleteEnrollment={(enrollmentId) => {
                                dispatch({name: 'AddDeletingEnrollment', enrollmentId: enrollmentId});
                                deleteEnrolment({enrollmentId},
                                    {
                                        onSuccess: async () => {
                                            await refetchEnrolments();
                                            dispatch({
                                                name: 'RemoveDeletingEnrollment',
                                                enrollmentId: enrollmentId
                                            });
                                        }
                                    });
                            }}
                            deletingEnrollmentIds={state.deletingEnrollmentIds}
                        />}
                </>

                break;

            case "add":
                body = <Space direction="vertical">
                    <ErrorAlert
                        isError={isGetRolesError}
                        errorSummary={'Failed to access course roles due to an error in Canvas'}
                        source={'StaffEnrollment->add'} errors={errors}
                    />
                    <ErrorAlert
                        isError={isCreateEnrolmentError}
                        errorSummary={'Failed to add staff user to the course due to an error in Canvas'}
                        source={'StaffEnrollment->add'} errors={errors}
                    />
                    <ErrorAlert
                        isError={isSearchUsersError}
                        errorSummary={'Failed to search for staff users due to an error in Canvas'}
                        source={'StaffEnrollment->add'} errors={errors}
                    />
                    {props.type === "modal" ?
                        <div className='course-name'>
                            <span>{props.canvasCourse.name}</span>
                        </div> : <></>}
                    <StaffEnrolmentManageEnrolment
                        mode="add"
                        fetchingRoles={fetchingRoles}
                        roles={roles}
                        fetchingUserSearchResults={fetchingUsers}
                        userSearchResults={filteredSearchUsers}
                        onUserSearch={searchTerm => dispatch({name: "SearchUsers", searchTerm})}
                        onUserSelect={canvasUserId => dispatch({name: "SelectUser", canvasUserId})}
                        onRoleSelect={role => dispatch({name: "SelectRole", canvasRoleId: role.roleId})}
                    />
                </Space>

                buttons = <Space>
                    <Button onClick={() => dispatch({name: "DisplayListUsers"})}
                            disabled={createEnrolmentIsLoading || fetchingEnrollments}>Cancel</Button>
                    <Button disabled={!state.selectedCanvasUserId || !state.selectedCanvasRoleId}
                            type="primary"
                            onClick={() => createEnrolment({
                                selectedCanvasUserId: state.selectedCanvasUserId!,
                                selectedCanvasRoleId: state.selectedCanvasRoleId!,
                            }, {
                                onSuccess: async () => {
                                    state.isCreateEnrolmentSuccess = true;
                                    await refetchEnrolments();
                                    dispatch({name: "DisplayListUsers"});
                                }
                            })}
                            loading={createEnrolmentIsLoading || fetchingEnrollments}>
                        <PlusOutlined/> Add Staff User
                    </Button>
                </Space>
                break;

            case "edit":
                body = <Space direction="vertical">
                    <ErrorAlert
                        isError={isGetRolesError}
                        errorSummary={'Failed to access course roles due to an error in Canvas'}
                        source={'StaffEnrollment->edit'} errors={errors}
                    />
                    <ErrorAlert
                        isError={isDeleteEnrollmentError || isCreateEnrolmentError}
                        errorSummary={'Failed to update staff user in the course due to an error in Canvas'}
                        source={'StaffEnrollment->edit'} errors={errors}
                    />
                    {props.type === "modal" ?
                        <div className='course-name'>
                            <span>{props.canvasCourse.name}</span>
                        </div> : <></>}
                    <StaffEnrolmentManageEnrolment
                        mode="edit"
                        fetchingRoles={fetchingRoles}
                        roles={roles}
                        onRoleSelect={role => dispatch({name: "SelectRole", canvasRoleId: role.roleId})}
                        userDisplayName={state.editingEnrolment!.displayName}
                        selectedRoleId={state.selectedCanvasRoleId ?? state.editingEnrolment?.roleId}
                    />
                </Space>

                buttons = <Space>
                    <Button onClick={() => dispatch({name: "DisplayListUsers"})}
                            disabled={createEnrolmentIsLoading || deleteEnrolmentIsLoading || fetchingEnrollments}>Cancel</Button>
                    <Button disabled={!state.selectedCanvasUserId || !state.selectedCanvasRoleId}
                            type="primary"
                            onClick={() => {
                                if (state.editingEnrolment!.roleId === state.selectedCanvasRoleId) {
                                    dispatch({name: "DisplayListUsers"});
                                    return;
                                }
                                deleteEnrolment({enrollmentId: state.editingEnrolment!.id}, {
                                    onSuccess: () => {
                                        createEnrolment({
                                            selectedCanvasUserId: state.selectedCanvasUserId!,
                                            selectedCanvasRoleId: state.selectedCanvasRoleId!,
                                        }, {
                                            onSuccess: async () => {
                                                state.isChangeEnrolmentSuccess = true;
                                                await refetchEnrolments();
                                                dispatch({name: "DisplayListUsers"});
                                            }
                                        })
                                    }
                                });
                            }}
                            loading={createEnrolmentIsLoading || deleteEnrolmentIsLoading || fetchingEnrollments}>
                        <PlusOutlined/> Change User Role</Button>
                </Space>
                break;
        }
    }


    return props.type === "modal" ?
        <Modal title="Manage Staff"
               open={props.open}
               footer={buttons}
               destroyOnClose={true}
               onCancel={props.onClose}
               className="staff-enrolment"
               afterClose={props.onCloseAnimationComplete}>
            {body}
        </Modal>
        :
        <Space direction="vertical"
               size="large"
               className="staff-enrolment">
            {body}
            {buttons}
        </Space>;
}