import {Term} from "../models";

export enum DataItemNames {
    SELECTED_YEAR = "SelectedYear",
    SELECTED_TERM = "SelectedTerm",
}

export class LocalDataService {

    getSelectedYearFilter() : string|null {
        return localStorage.getItem(DataItemNames.SELECTED_YEAR);
    }

    saveSelectedYearFilter(year?: string) {
        if (year) {
            localStorage.setItem(DataItemNames.SELECTED_YEAR, year);
        } else {
            localStorage.removeItem(DataItemNames.SELECTED_YEAR);
        }

    }

    getSelectedTermFilter() : Term|null {
        const termStr = localStorage.getItem(DataItemNames.SELECTED_TERM);
        if (termStr) {
            return JSON.parse(termStr);
        }
        return null;
    }

    saveSelectedTermFilter(term?: Term) {
        if (term) {
            localStorage.setItem(DataItemNames.SELECTED_TERM, JSON.stringify(term));
        } else {
            localStorage.removeItem(DataItemNames.SELECTED_TERM);
        }

    }

}