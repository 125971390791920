import { Alert } from "antd";
import {getMiddleWareErrorObj} from "./utils/errorUtils";

type ErrorAlertProps = {
    isError: boolean;
    errorSummary: string;    
    source: string;
    errors?: Error[];
}

export default function ErrorAlert(props: ErrorAlertProps) {
    
    if (!props.isError) return null;

    //TODO: Log UI error to application monitoring service
    console.group(`Error source: ${props.source}`);
    console.log(`Summary: ${props.errorSummary}`);
    let clientError: any;

    let errorDetail: React.ReactNode;
    let errorDetailContent: React.ReactNode;
    if (props.errors && props.errors.length > 0) {

        props.errors.forEach((error, index) => {
            clientError = getMiddleWareErrorObj(error);
            if (clientError.errorMessage.length > 0){
                if (clientError.errorId.length > 0){
                    errorDetailContent = <li>{`${clientError.errorMessage} | ${clientError.errorId} | ${clientError.errorDateTime}`}</li>;
                }else{
                    errorDetailContent = <li>{clientError.errorMessage}</li>
                }
                console.error(`Error detail ${index+1}`,clientError);
            }else{
                errorDetailContent = <li>{`${error.message}`}</li>;
                console.error(`Error detail ${index+1}`,error);
            }
        });

        errorDetail = <ul>{errorDetailContent}</ul>
    }
    console.groupEnd();

  return (
        <Alert
            message={props.errorSummary}
            description= {errorDetail}
            type="error"
            showIcon
        />
  )
}
