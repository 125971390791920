import { Button, ButtonProps } from "antd";
import CopyOutlined from "@ant-design/icons/lib/icons/CopyOutlined";

type CopyToClipboardButtonProps = Omit<ButtonProps, "onClick"> & {
    textToCopy: string;
};

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => (
    <Button
        onClick={() => navigator.clipboard.writeText(props.textToCopy)}
        icon={<CopyOutlined />}
        size="small"
        {...props}
    />
);

export default CopyToClipboardButton;