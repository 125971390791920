import {UserOutlined, EditOutlined, DeleteOutlined, LoadingOutlined} from '@ant-design/icons';
import {Button, Empty, List, Popconfirm} from "antd";
import {Enrollment, Role} from './models';
import './staffenrolmentlistusers.css';

export interface StaffUserListProps {
    enrollments?: Enrollment[];
    roles?: Role[];
    onChangeEnrollment?: (enrollment: Enrollment) => void;
    onDeleteEnrollment?: (enrollmentId: number) => void;
    deletingEnrollmentIds?: Set<number>;
}

export default function StaffUserList(props: StaffUserListProps) {
    if (!props.enrollments || props.enrollments.length === 0 || !props.roles || props.roles.length === 0) {
        return <Empty description="No enrollments"/>;
    }

    props.enrollments.sort(createEnrolmentCompare(props.roles));

    const roleMap = new Map<string, Role>();
    props.roles.forEach((role) => {
        roleMap.set(role.role, role);
    });

    let lastRole: string = '';
    return <List
        itemLayout="horizontal"
        dataSource={props.enrollments}
        renderItem={item => (
            <>
                {item.role !== lastRole && (
                    <h3 className='role'>{roleMap.get(item.role)?.label ?? item.role}</h3>
                )}
                {(() => {
                    if (item.role !== undefined) lastRole = item.role;
                })()}

                <List.Item>
                    <List.Item.Meta
                        avatar={<UserOutlined/>}
                        title={item.displayName}
                    />
                    {roleMap.get(item.role)?.userCanEdit ?
                        <>
                            <Button type="link" onClick={() => props.onChangeEnrollment?.(item)}><EditOutlined className='edit-btn' /></Button>
                            {props.deletingEnrollmentIds?.has(item.id) ?
                                <Button type="link"><LoadingOutlined className='edit-btn'/></Button> : // Button to ensure the spacing is right.
                                <Popconfirm title={`Remove ${item.displayName} from the course?`}
                                            icon={<DeleteOutlined/>}
                                            onConfirm={() => props.onDeleteEnrollment?.(item.id)}
                                            okText="Remove staff user"
                                            cancelText="Cancel"
                                            className="staff-enrolment-delete-confirm">
                                    <Button type="link"><DeleteOutlined className='delete-btn'/></Button>
                                </Popconfirm>}
                        </>
                        : <></>
                    }
                </List.Item>
            </>
        )}
    />;
}

// Presumes roles is sorted
const createEnrolmentCompare = (roles: Role[]) => {
    const roleOrderMap = new Map<string, number>();
    roles.forEach((role, index) => {
        roleOrderMap.set(role.role, index + 1);
    });

    return (l: Enrollment, r: Enrollment) => {
        const lIndex = roleOrderMap.get(l.role);
        const rIndex = roleOrderMap.get(r.role);

        if (!rIndex && lIndex) {
            return -1;
        }
        if (!lIndex && rIndex) {
            return 1;
        }
        if (lIndex && rIndex && lIndex !== rIndex) {
            return lIndex - rIndex;
        }

        return l.displayName.localeCompare(r.displayName);
    }
}
