import { useQuery } from "@tanstack/react-query"
import { createContext, useReducer } from "react";
import { Term } from "./models";
import { TermSummaryList } from "./TermSummaryList";
import { Checkbox, Row, Select, Typography } from "antd";
import "./progresssummarypage.css"
import SummaryService from "./services/SummaryService";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;
const { Title } = Typography;

export const SummaryServiceContext = createContext<SummaryService | undefined>(undefined);

const termSummaryPageStateReducer = (state: SummaryPageState, action: SummaryPageStateAction): SummaryPageState => {
    switch(action.name) {
        case "SetSelectedYear":
            return {
                ...state,
                selectedYear: action.year,
                terms: undefined,
            };
        case 'SetTerms':
            return {
                ...state,
                terms: action.terms
            }
        case "SetYears":
            let selectedYear = state.selectedYear;
            if (!state.selectedYear) {
                const today = new Date();
                selectedYear = today.getUTCFullYear().toString();
            }
            return {
                ...state,
                years: action.years,
                selectedYear: selectedYear,
            };
        case "TogglePercentages":
            return {
                ...state,
                showPercentages: action.showPercentages
            };            
    }
}

interface SetSelectedYear {
    name: "SetSelectedYear";
    year: string | undefined;
}

interface SetTerms {
    name: "SetTerms";
    terms: Term[];
}

interface SetYears {
    name: "SetYears";
    years: string[];
}

interface TogglePercentages {
    name: "TogglePercentages";
    showPercentages: boolean;
}

export interface SummaryPageProps {
    summaryService: SummaryService;
}

interface SummaryPageState {
    selectedYear?: string;
    terms?: Term[];
    years?: string[];
    showPercentages?: boolean;
}

export type SummaryPageStateAction =
    | SetTerms
    | SetSelectedYear
    | SetYears
    | TogglePercentages

export const Summary = (props: SummaryPageProps) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const initialState: SummaryPageState = { selectedYear: searchParams.get("year") ?? new Date().getFullYear().toString()};

    const [state, dispatch] = useReducer(termSummaryPageStateReducer, initialState);

    const { data: years, isFetching: fetchingYears} = useQuery<string[], Error>(["allyears"],
        () => {
            return props.summaryService.getYears();
        }, {
            enabled: !state.years,
            onSuccess: (data: string[]) => {
                dispatch({name: "SetYears", years: data});
            }
        });

    const { data: terms, isFetching: fetchingTerms, error: termFetchError } = useQuery<Term[], Error>(["termsByYear", state.selectedYear],
        () => {
            if (state.selectedYear) {
                return props.summaryService.getTermsByYear(state.selectedYear);
            }
            return []
        }, {
            onSuccess: (data: Term[]) => {
                dispatch({name: "SetTerms", terms: data});
            },
        });

    const togglePercentages = () => {
        dispatch({ name: "TogglePercentages", showPercentages: !state.showPercentages });
    }

    return <>
        <Title className={"summarypage-title"}>Summary</Title>
        <div className={'summarypagefilter-container'}>
            <SummaryFilter
                selectedYear={state.selectedYear}
                years={state.years ?? years}
                fetchingYears={fetchingYears}
                onClear={() => {
                    searchParams.delete("year");
                    setSearchParams(searchParams);
                    dispatch({name: "SetSelectedYear", year: undefined});
                }}
                onChange={(year: string) => {
                    if (year) searchParams.set("year", year);
                    else searchParams.delete("year")
                    setSearchParams(searchParams);
                    dispatch({name: "SetSelectedYear", year})
                }}/>
            <Checkbox onChange={togglePercentages}>Show percentages</Checkbox>           
        </div>
        <SummaryServiceContext.Provider value={props.summaryService}>
            <TermSummaryList
                terms={state.terms ?? terms}
                fetchingTerms={fetchingTerms}
                error={termFetchError}
                showPercentages={state.showPercentages ?? false}
                />
        </SummaryServiceContext.Provider>
    </>
}

interface SummaryFilterProps {
    selectedYear?: string;
    terms?: Term[];
    years?: string[];
    fetchingYears: boolean;

    onClear: () => void;
    onChange: (x: string) => void;
}

const SummaryFilter = (props: SummaryFilterProps) => <Row className={'summarypagefilter-yearselect'}>
    <Select placeholder={"Select Year"}
            value={props.selectedYear}
            allowClear={true}
            onClear={props.onClear}
            onChange={props.onChange}
            bordered={false} loading={props.fetchingYears}>
        {props.years ? (props.years.map((year:string) => {return <Option key={year} value={year}>{year}</Option>} )) : (<></>) }
    </Select>
</Row>