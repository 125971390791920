import {ReactElement} from "react";

export type FeatureFlag = {
    name: string, description: string, active: boolean
}

export const initFeatures = (flags: FeatureFlag[]) => {

    const existingFlagsJson = localStorage.getItem('feature-flags');
    if (!existingFlagsJson) {
        localStorage.setItem('feature-flags', JSON.stringify(flags));
    } else {
        const existingFlags: FeatureFlag[] = JSON.parse(existingFlagsJson);
        if (existingFlags.length !== flags.length) {

            flags.forEach((flag) => {
                if (!existingFlags.find((ef) => {
                    return ef.name === flag.name
                })) {
                    existingFlags.push(flag);
                }
            })
            localStorage.setItem('feature-flags', JSON.stringify(existingFlags));
        }
    }
};


export const featureIsActive = (featureName: string) : boolean => {
    const featuresStr = localStorage.getItem('feature-flags');
    if (!featuresStr) {
        return false;
    }
    const features: FeatureFlag[] = JSON.parse(featuresStr);
    const feature = features.find(f => f.name === featureName);

    return !!feature && feature.active;
}


export type FeatureProps = { name: string, children: ReactElement  }
export const Feature = (props: FeatureProps): ReactElement => {
    const featuresStr = localStorage.getItem('feature-flags');
    if (!featuresStr) {
        return <></>
    }
    const features: FeatureFlag[] = JSON.parse(featuresStr);
    const feature = features.find(f => f.name === props.name);

    if (feature && feature.active) {
        return props.children;
    }

    return <></>;
};

