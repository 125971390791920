import CourseAvailabilityService from "./services/CourseAvailabilityService";
import CourseService from "./services/CourseService";
import UserService from "./services/UserService";
import RoleService from "./services/RoleService";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {Course, Operation, Section} from "./models";
import {CopyContentModal} from "./CopyContentModal";
import {AddMirroredSectionModal} from "./AddMirroredSectionModal";
import {CourseReleaseModal} from "./CourseReleaseModal";
import {CourseAvailabilityAgreementList} from "./CourseAvailabilityAgreementList";
import StaffEnrolment from "./StaffEnrolment";
import { AddStaticStudentSectionModal } from "./AddStaticStudentSectionModal";
import { UpdateStaticStudentSectionModal } from "./UpdateStaticStudentSectionModal";
import { AddStudentToStaticStudentSectionModal } from "./AddStudentToStaticStudentSectionModal";

const mapStringToOperation = (str: string | undefined): Operation | null => {
    if (!str) {
        return null;
    }
    switch (str.toLowerCase()) {
        case "copy":
            return "copy";
        case "makeavailable":
            return "makeAvailable";
        case "release":
            return "release";
        case "manageusers":
            return "manageUsers"
        case "addmirroredsection":
            return "addMirroredSection";
        case "addstaticstudentsection":
            return "addStaticStudentSection";
        case "updatestaticstudentsection":
            return "updateStaticStudentSection";
        case "addstudenttostaticstudentsection":
            return "addStudentToStaticStudentSection";            
        default:
            throw new Error("Unknown operation");
    }
}

type CourseModalsProps = {
    onCancel: () => void
    onSuccess: () => void

    courseAvailabilityService: CourseAvailabilityService
    courseService: CourseService
    userService: UserService
    roleService: RoleService
}


export const CourseModals = (props: CourseModalsProps) => {

    const [modalOpenForOperation, setModalOpenForOperation] = useState<Operation>();

    const {
        courseId: courseIdParam,
        sectionId: sectionIdParam,
        operation: operationParam
    } = useParams();
    const operationFromUrl = mapStringToOperation(operationParam);
    const courseId = courseIdParam ? +courseIdParam : undefined;
    const sectionId = sectionIdParam ? +sectionIdParam : undefined;

    useEffect(() => {
        if (operationFromUrl) {
            setModalOpenForOperation(operationFromUrl);
        }
    }, [operationFromUrl])

    const {data: currentCourse, isLoading: fetchingCourse} = useQuery<Course, Error>(["course", courseId],
        () => {
            if (courseId) {
                return props.courseService.getCourse(courseId);
            } else {
                throw new Error("Current course ID is null");
            }
        }, {
            enabled: !!operationFromUrl && !!courseId,
        });

    const {data: currentSection, isLoading: fetchingSection } = useQuery<Section, Error>(["course", courseId, "section", sectionId],
        () => {
            if (courseId && sectionId) {
                return new Promise(async (resolve, reject) => {
                    const sections = await props.courseService.getSections(courseId);
                    const section = sections.find(section => section.canvasId === sectionId);
                    if (section) {
                        resolve(section);
                    }
                    reject(new Error("Section not found")); 
                });
            } else {
                throw new Error("Current course ID or section ID is null");
            }
        }, {
            enabled: !!operationFromUrl && !!courseId && !!sectionId,
        });

    const onCloseAnimationComplete = () => setModalOpenForOperation(undefined);

    const modalOpen = modalOpenForOperation === operationFromUrl;

    return <>
        {modalOpenForOperation === "copy" ?
            <CopyContentModal
                open={modalOpen}
                courseService={props.courseService}
                fetchingCourse={fetchingCourse}
                currentCourse={currentCourse}
                onCancel={props.onCancel}
                onCopyOperationPosted={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}
        {modalOpenForOperation === "release" ?
            <CourseReleaseModal
                open={modalOpen}
                courseService={props.courseService}
                fetchingCourse={fetchingCourse}
                currentCourse={currentCourse}
                onCancel={props.onCancel}
                onReleaseOperationPosted={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}
        {modalOpenForOperation === "makeAvailable" ?
            <CourseAvailabilityAgreementList
                type="modal"
                open={modalOpen}
                courseService={props.courseService}
                courseAvailabilityService={props.courseAvailabilityService}
                onCancel={props.onCancel}
                course={currentCourse}
                onCourseMadeAvailable={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}
        {modalOpenForOperation === "manageUsers" ?
            <StaffEnrolment
                fetchingCourse={fetchingCourse}
                canvasCourse={currentCourse}
                courseService={props.courseService}
                userService={props.userService}
                roleService={props.roleService}
                type="modal"
                open={modalOpen}
                onClose={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}
        {modalOpenForOperation === "addMirroredSection" ?
            <AddMirroredSectionModal
                open={modalOpen}
                courseService={props.courseService}
                fetchingCourse={fetchingCourse}
                currentCourse={currentCourse}
                onCancel={props.onCancel}
                onAddMirroredSectionOperationPosted={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}
        {modalOpenForOperation === "addStaticStudentSection" ?
            <AddStaticStudentSectionModal
                open={modalOpen}
                courseService={props.courseService}
                fetchingCourse={fetchingCourse}
                currentCourse={currentCourse}
                onCancel={props.onCancel}
                onAddStaticStudentSectionOperationPosted={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}
        {modalOpenForOperation === "updateStaticStudentSection" ?
            <UpdateStaticStudentSectionModal
                open={modalOpen}
                courseService={props.courseService}
                fetchingCourse={fetchingCourse}
                currentCourse={currentCourse}
                fetchingSection={fetchingSection}
                currentSection={currentSection}
                onCancel={props.onCancel}
                onUpdateStaticStudentSectionOperationPosted={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}
        {modalOpenForOperation === "addStudentToStaticStudentSection" ?
            <AddStudentToStaticStudentSectionModal
                open={modalOpen}
                courseService={props.courseService}
                roleService={props.roleService}
                fetchingCourse={fetchingCourse}
                currentCourse={currentCourse}
                fetchingSection={fetchingSection}
                currentSection={currentSection}
                onCancel={props.onCancel}
                onAddStudentToStaticStudentSectionOperationPosted={props.onSuccess}
                onCloseAnimationComplete={onCloseAnimationComplete}
            /> : <></>}            
    </>
}