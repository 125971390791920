import {useSession} from "../services/SessionService";
import {CourseSetupState, SamRole} from "../models";
import {Tag} from "antd";
import {mapCourseStateToColourCode, mapCourseStateToLabel} from "../utils/CourseSetupState";

type CourseStatusIndicatorProps = {
    state: CourseSetupState,
    roles?: SamRole[],
}
const CourseStateTag: React.FC<React.PropsWithoutRef<CourseStatusIndicatorProps>> = (props) => {

    const session = useSession();
    if(props.roles && !props.roles.some(r => session.samRoles.includes(r))) {
        return <></>;
    }

    return <Tag color={mapCourseStateToColourCode(props.state)}>{mapCourseStateToLabel(props.state)}</Tag>;

}
export default CourseStateTag;