/*
 * This file is "owned" by the UI package, and then copied into the Admin UI package.  If you need to modify them,
 * please do so in the UI package, and then copy the modified file into the Admin UI package using the shared-files.sh
 * script.
 */


import {CourseMenuItemIcon} from "../models";
import {
    AimOutlined,
    BankOutlined,
    BookOutlined,
    BulbOutlined,
    CalculatorOutlined,
    CameraOutlined,
    CheckSquareOutlined,
    CodeOutlined,
    CommentOutlined,
    CompassOutlined,
    CopyOutlined,
    DashboardOutlined,
    DiffOutlined,
    ExperimentOutlined,
    FundProjectionScreenOutlined,
    GlobalOutlined,
    HistoryOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    LayoutOutlined,
    LockOutlined,
    MedicineBoxOutlined,
    NodeIndexOutlined,
    NotificationOutlined,
    PlayCircleOutlined,
    QuestionCircleOutlined,
    ReadOutlined,
    RocketOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    StarOutlined,
    SwapOutlined,
    TagOutlined,
    TeamOutlined,
    ToolOutlined,
    TrophyOutlined,
    UnlockOutlined,
    UserOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";

export const calculateCourseMenuItemIcon = (icon?: CourseMenuItemIcon) => {
    switch (icon) {
        case CourseMenuItemIcon.Read:
            return <ReadOutlined />;
        case CourseMenuItemIcon.NodeIndex:
            return <NodeIndexOutlined />;
        case CourseMenuItemIcon.Shop:
            return <ShopOutlined />;
        case CourseMenuItemIcon.Diff:
            return <DiffOutlined />;
        case CourseMenuItemIcon.Rocket:
            return <RocketOutlined />;
        case CourseMenuItemIcon.Swap:
            return <SwapOutlined />;
        case CourseMenuItemIcon.PlayCircle:
            return <PlayCircleOutlined />;
        case CourseMenuItemIcon.QuestionCircle:
            return <QuestionCircleOutlined />;
        case CourseMenuItemIcon.CheckSquare:
            return <CheckSquareOutlined />;
        case CourseMenuItemIcon.InfoCircle:
            return <InfoCircleOutlined />;
        case CourseMenuItemIcon.Copy:
            return <CopyOutlined />;
        case CourseMenuItemIcon.Aim:
            return <AimOutlined />;
        case CourseMenuItemIcon.Bank:
            return <BankOutlined />;
        case CourseMenuItemIcon.Calculator:
            return <CalculatorOutlined />;
        case CourseMenuItemIcon.Bulb:
            return <BulbOutlined />;
        case CourseMenuItemIcon.Camera:
            return <CameraOutlined />;
        case CourseMenuItemIcon.Comment:
            return <CommentOutlined />;
        case CourseMenuItemIcon.Code:
            return <CodeOutlined />;
        case CourseMenuItemIcon.Compass:
            return <CompassOutlined />;
        case CourseMenuItemIcon.Dashboard:
            return <DashboardOutlined />;
        case CourseMenuItemIcon.Experiment:
            return <ExperimentOutlined />;
        case CourseMenuItemIcon.FundProjectionScreen:
            return <FundProjectionScreenOutlined />;
        case CourseMenuItemIcon.Global:
            return <GlobalOutlined />;
        case CourseMenuItemIcon.History:
            return <HistoryOutlined />;
        case CourseMenuItemIcon.Layout:
            return <LayoutOutlined />;
        case CourseMenuItemIcon.MedicineBox:
            return <MedicineBoxOutlined />;
        case CourseMenuItemIcon.Notification:
            return <NotificationOutlined />;
        case CourseMenuItemIcon.ShoppingCart:
            return <ShoppingCartOutlined />;
        case CourseMenuItemIcon.Shopping:
            return <ShoppingOutlined />;
        case CourseMenuItemIcon.Star:
            return <StarOutlined />;
        case CourseMenuItemIcon.Team:
            return <TeamOutlined />;
        case CourseMenuItemIcon.Tag:
            return <TagOutlined />;
        case CourseMenuItemIcon.Tool:
            return <ToolOutlined />;
        case CourseMenuItemIcon.Trophy:
            return <TrophyOutlined />;
        case CourseMenuItemIcon.User:
            return <UserOutlined />;
        case CourseMenuItemIcon.VideoCamera:
            return <VideoCameraOutlined />;
        case CourseMenuItemIcon.Unlock:
            return <UnlockOutlined />;
        case CourseMenuItemIcon.Lock:
            return <LockOutlined />;
        case CourseMenuItemIcon.Home:
            return <HomeOutlined />;

        case CourseMenuItemIcon.Book:
        case undefined:
            return <BookOutlined />;
    }
}    
