import {ActivityLogsPage} from '../models'
import {SessionService} from "./SessionService";
import {wsErrorFromResponse} from "../errors";
import {encodeQueryParams} from "../utils/stringtemplatetags";

function decodeResponse(response: Response) {
    if (!response.ok) {
        throw wsErrorFromResponse(response);
    }
    if(response.status === 204) {
        return Promise.resolve();
    }
    return response.json();
}

export default class AuditLogsService {

    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async getAuditLogs(lastEvaluatedLogEntryId?: number|undefined,
                       targetCourseId?: string,
                       targetSectionId?: string,
                       targetUserId?: string,
                       principalId?: string,
                       eventSource?: string,
                       targetSystem?: string,
                       excludeIntegrationUser?: boolean,
                       ): Promise<ActivityLogsPage> {
        const queryParams: string[] = [];
        if (lastEvaluatedLogEntryId) {
            queryParams.push(encodeQueryParams`lastEvaluatedLogEntryId=${'' + lastEvaluatedLogEntryId}`)
        }
        if (targetCourseId) {
            queryParams.push(encodeQueryParams`targetSisCourseId=${targetCourseId}`)
        }
        if (targetSectionId) {
            queryParams.push(encodeQueryParams`targetSisSectionId=${targetSectionId}`)
        }
        if (targetUserId) {
            queryParams.push(encodeQueryParams`targetSisUserId=${targetUserId}`)
        }
        if (principalId) {
            queryParams.push(encodeQueryParams`principalId=${principalId}`)
        }
        if (eventSource) {
            queryParams.push(encodeQueryParams`eventSource=${eventSource}`)
        }
        if (targetSystem) {
            queryParams.push(encodeQueryParams`targetSystem=${targetSystem}`)
        }
        if (queryParams.length === 0) {
            return {logs: []};
        }

        queryParams.push(encodeQueryParams`excludeIntegrationUser=${excludeIntegrationUser ? "true" : "false"}`)

        const response = await fetch(`${this.apiBaseUrl}/api/activitylogs?${queryParams.join('&')}`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });
        const logsPage: ActivityLogsPage = await decodeResponse(response);
        logsPage.logs = logsPage.logs.map((dto) => {
            return {...dto, logTimeJsDate: dto.logTimestamp ? new Date(dto.logTimestamp) : undefined}
        });
        return logsPage;
    }

}

