import { Alert, AlertProps, Button, Form, Input, Modal,Spin } from "antd";
import { Course, Section } from "./models";
import { useReducer } from 'react';
import CourseService from "./services/CourseService";
import RoleService from "./services/RoleService";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";

export interface AddStudentToStaticStudentSectionModelProps {
    courseService: CourseService;
    roleService: RoleService;
    open: boolean;
    fetchingCourse: boolean;
    currentCourse?: Course;
    fetchingSection: boolean;
    currentSection?: Section;
    onCancel: () => void;
    onAddStudentToStaticStudentSectionOperationPosted: () => void;
    onCloseAnimationComplete?: () => void;
}

export interface AddStudentToStaticStudentSectionModelState {
    alerts: NotificationSettingState;
    isAddStudentDisabled: boolean;
}

type NotificationSettingState = {
    message?: React.ReactNode,
    type?: AlertProps["type"],
}

interface SetAlert {
    type: "SetAlert";
    payload: NotificationSettingState;
}

interface SetAddStudentDisabled {
    type: "SetAddStudentDisabled";
    payload: boolean;
}

export type AddStudentToStaticStudentSectionModalAction = 
    | SetAlert
    | SetAddStudentDisabled;

function AddStudentToStaticStudentSectionStateReducer(
    state: AddStudentToStaticStudentSectionModelState, 
    action: AddStudentToStaticStudentSectionModalAction
): AddStudentToStaticStudentSectionModelState {
    switch (action.type) {
        case "SetAlert":
            return {
                ...state,
                alerts: action.payload
            }
        case "SetAddStudentDisabled":
        return {
            ...state,
            isAddStudentDisabled: action.payload
        };            
        default:
            return state;
    }    
}

const initialState: AddStudentToStaticStudentSectionModelState = {
    alerts: { message: undefined, type: undefined },
    isAddStudentDisabled: true
};

interface CreateEnrollmentParams {
    sectionId: number;
    studentId: string;
}

export const AddStudentToStaticStudentSectionModal = (props: AddStudentToStaticStudentSectionModelProps) => {
    const [form] = Form.useForm();
    const [state, dispatch] = useReducer(AddStudentToStaticStudentSectionStateReducer, initialState);

    const { mutateAsync: postSectionEnrollment, isLoading: isSaving } = useMutation(
        ({ sectionId, studentId }: CreateEnrollmentParams) => {
            return props.courseService.postSectionEnrollment(sectionId, studentId);
    }, {});

    const onOk = async (): Promise<void> => {
        if (!props.currentSection) {
            throw new Error("No section loaded");
        }    
        const studentToEnroll: CreateEnrollmentParams = {
            sectionId: props.currentSection.canvasId,
            studentId: form.getFieldValue('studentId')
        };            
        try {
            await postSectionEnrollment(studentToEnroll);
            dispatch({ type: "SetAlert", payload: { message: "Student successfully added to the section", type: "success" } });
        } catch (e) {
            console.error(e);
            dispatch({ type: "SetAlert", payload: { message: "Error adding student to the section", type: "error" } });
        }
    };        

    const handleFormValuesChange = () => {
        const studentId = form.getFieldValue('studentId');
        if (studentId && studentId.trim() !== '') {
            dispatch({ type: "SetAddStudentDisabled", payload: false })
        } else {
            dispatch({ type: "SetAddStudentDisabled", payload: true })
        }
    };

    let content, okButtonText, okButtonFcn, footer;

    if (!props.currentCourse || !props.currentSection || props.fetchingCourse || props.fetchingSection || isSaving) {
        content = <Spin/>;
        footer = null;
    } else if (props.currentCourse.concluded) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Course {props.currentCourse.name} has ended.</div>
            <div>Course end date: {moment(props.currentCourse.endAt).format("MM/DD/YYYY")}</div>
        </div>
        footer = null;
    } else if (state.alerts.message) {
        content = <Alert
            message={state.alerts.message}
            type={state.alerts.type}
            showIcon
        />
        footer = <Button onClick={props.onAddStudentToStaticStudentSectionOperationPosted}>Close</Button>
    } else {
        content = <Form
            form={form}
            layout={"vertical"}
            onValuesChange={handleFormValuesChange}>            
            <div style={{fontWeight: "700", fontSize: "16px"}}>{props.currentCourse.name}</div>
            <div style={{fontWeight: "500", marginBottom: "1em", fontSize: "14px"}}>{props.currentSection.name}</div>
            <Form.Item name='studentId' label="Student ID">
                <Input />
            </Form.Item>
        </Form>
        okButtonText = <><PlusOutlined/> Add Student</>;
        okButtonFcn = onOk;
        footer = undefined;
    }

    return <Modal
        title={"Add Student to Section"}
        destroyOnClose={true}
        open={props.open}
        footer={footer}
        onCancel={props.onCancel}
        onOk={okButtonFcn}
        okButtonProps={{ disabled: state.isAddStudentDisabled }}
        okText={okButtonText}
        afterClose={props.onCloseAnimationComplete}>
            {content}
    </Modal>
}