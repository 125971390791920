import {Role} from '../models'
import {SessionService} from "./SessionService";

function decodeResponse(response: Response) {
    if (!response.ok) {
        throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
    }
    if(response.status === 204) {
        return Promise.resolve();
    }
    return response.json();
}

export default class RoleService {
    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async getAllRolesForAccount(accountId: number): Promise<Role[]> {
        const roles: Role[] = await fetch(`${this.apiBaseUrl}/api/courses/${accountId}/roles`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);

        roles.sort(roleCompare);
        return roles;
    }
}

const roleCompare = (l: Role, r: Role) => {
    if(!r.sortOrder && l.sortOrder) {
        return -1;
    }
    if(!l.sortOrder && r.sortOrder) {
        return 1;
    }
    if(l.sortOrder && r.sortOrder && l.sortOrder !== r.sortOrder) {
        return l.sortOrder - r.sortOrder;
    }
    return l.label.localeCompare(r.label);
}
