import {CourseSetupState} from "../models";

export const mapCourseStateToLabel = (state: CourseSetupState): string => {
    switch (state) {
        case CourseSetupState.AWAITING_COPY:
            return "Awaiting Content";
        case CourseSetupState.AWAITING_REVIEW:
            return "Awaiting Review";
        case CourseSetupState.RELEASED:
            return "Released to coordinator";
        case CourseSetupState.AVAILABLE:
            return "Published";
        case CourseSetupState.COMPLETED:
            return "Completed";
        case CourseSetupState.DELETED:
            return "Deleted";
    }
}

export const mapCourseStateToColourCode = (state: CourseSetupState): string => {
    switch (state) {
        case CourseSetupState.AWAITING_COPY:
            return "#FFB000";
        case CourseSetupState.AWAITING_REVIEW:
            return "#DC267F";
        case CourseSetupState.RELEASED:
            return "#785EF0";
        case CourseSetupState.AVAILABLE:
            return "#277500";
        case CourseSetupState.COMPLETED:
            return "#a3a3a3";
        case CourseSetupState.DELETED:
            return "#363636";
    }
}
