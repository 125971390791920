import {CourseComment} from "../models";
import {formatDistance, differenceInWeeks, isPast, format} from "date-fns";
import React, {useEffect, useState} from "react";
import "./CourseComments.css";
import NameAvatar from "./NameAvatar";
import {useCoursesListDispatch} from "../CoursesContext";
import {Alert, Button, Form, List, Popconfirm, ConfigProvider, Typography, Space} from "antd";
import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    MessageOutlined,
    SaveOutlined,
    SendOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Markdown from 'marked-react';
import ExpandableElement from "./ExpandableElement";


type CourseCommentsProps = {
    canvasCourseId: number;
    comments: CourseComment[];
    isFetchingComments?: boolean;
    isAddingComment?: boolean;
    isDeletingComment?: boolean;
    isUpdatingComment?: boolean;
    isSuccessfulSubmit?: boolean;
    isSubmitError?: boolean;
    isUpdateError?: boolean;
    commentIdBeingEdited?: string;
    onAddComment: (comment: string) => void;
    onUpdateComment: (commentId: string, comment: string) => void;
    onDeleteComment: (commentId: string) => void;
    maxDisplayedComments?: number;
};

const CourseComments = (props: CourseCommentsProps) => {
    const [newCommentForm] = Form.useForm();
    const [editCommentForm] = Form.useForm();
    const [isShowingAll, setIsShowingAll] = useState(false);
    const coursesListDispatch = useCoursesListDispatch();
    
    const isEditMode = (commentId: string) => {
        return props.commentIdBeingEdited === commentId;
    };

    useEffect(() => {
        if (!props.isSuccessfulSubmit) {
            return;
        }
        newCommentForm.resetFields();
    }, [props.isSuccessfulSubmit, newCommentForm]);

    const handleAddNewComment = (values: Partial<CourseComment>) => {
        if (!props.onAddComment) {
            return;
        }
        props.onAddComment(values.content!);
    };

    const handleUpdateComment = (
        commentId: string,
        values: Partial<CourseComment>
    ) => {
        if (!props.onUpdateComment) {
            return;
        }
        props.onUpdateComment(commentId, values.content!);
    };

    const formatTimestamp = (timestamp: string) => {
        const targetDate = new Date(timestamp);
        if (isPast(targetDate) && differenceInWeeks(new Date(), targetDate) > 1) {
            return format(targetDate, "dd MMMM yyyy hh:mm a");
        } else {
            return formatDistance(new Date(timestamp), new Date()) + " ago";
        }
    };

    const setEditing = (commentId: string) => {
        coursesListDispatch({
            name: "SetCommentIdBeingEdited",
            courseId: props.canvasCourseId,
            commentId,
        });
        editCommentForm.setFieldValue("content", props.comments.find((comment) => comment.commentId === commentId)?.content);
    };
    const cancelEditing = () => {
        coursesListDispatch({
            name: "ClearCommentIdBeingEdited",
        });
    };

    const getActionButtons = (comment: CourseComment) => {
        const actions = [];

        if(comment.editable) {
            actions.push(<Button
                type={"text"}
                icon={<EditOutlined/>}
                size={"small"}
                disabled={!comment.editable}
                onClick={() => setEditing(comment.commentId)}
            />);
        }

        if(comment.deletable) {
            actions.push(<Popconfirm
                title={`Are you sure you want to delete this comment?`}
                disabled={!comment.deletable}
                onConfirm={() => props.onDeleteComment(comment.commentId)}
            >
                <Button
                    type={"text"}
                    icon={<DeleteOutlined/>}
                    size={"small"}
                    disabled={!comment.deletable}
                />
            </Popconfirm>);
        }

        return actions;
    }


    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
            switch (event.currentTarget.id) {
                case "new-comment-input":
                    newCommentForm.submit();
                    break;
                case "edit-comment-input":
                    editCommentForm.submit();
                    break;
            }
        }
    };

    const noCommentsMessage = (
        <>
            No comments <MessageOutlined/>
        </>
    );

    const currentCommentsList: CourseComment[] = isShowingAll || props.comments.length <= 6
        ? props.comments
        : props.comments.slice(props.comments.length - 6, props.comments.length);

    return (
        <ConfigProvider
            theme={{
                components: {
                    List: {
                        titleMarginBottom: 0,
                        metaMarginBottom: 0,
                        fontSize: 12,
                        itemPaddingSM: "8px 8px"
                    },
                },
            }}>

            <Typography className={"show-all-comments-wrapper"}>
                {props.comments.length > 0 && `Showing ${currentCommentsList.length} of ${props.comments.length} comments.`}

                {props.comments.length > 6 &&
                    <Button type={"link"} onClick={() => setIsShowingAll(!isShowingAll)}>
                        {isShowingAll ? "Show fewer" : "Show all"}
                    </Button>}
            </Typography>

            <List
                itemLayout="vertical"
                size="small"
                loading={props.isFetchingComments || props.isDeletingComment}
                locale={{emptyText: noCommentsMessage}}
                dataSource={currentCommentsList}
                renderItem={(comment) => (
                    <List.Item
                        key={comment.commentId}>
                        <List.Item.Meta
                            avatar={<NameAvatar name={comment.name}/>}
                            title={<Space>{comment.name}{getActionButtons(comment)}</Space>}
                            description={<>
                                {comment.edited ? "Edited" : "Posted"}{" "}
                                {formatTimestamp(comment.timestamp)}
                            </>}/>
                        {isEditMode(comment.commentId) ? (
                            <Form
                                initialValues={comment}
                                onFinish={(values) =>
                                    handleUpdateComment(comment.commentId, values)
                                }
                                form={editCommentForm}
                                key={`edit-comment-form-${comment.commentId}`}
                            >
                                <Form.Item
                                    name="content"
                                    rules={[{required: true, message: "Please enter a comment"}]}
                                    className={"edit-comment-wrapper"}
                                >
                                    <TextArea autoSize id={"edit-comment-input"} onKeyDown={handleKeyPress}/>
                                </Form.Item>

                                <Button
                                    size={"small"}
                                    icon={<SaveOutlined/>}
                                    onClick={editCommentForm.submit}
                                    loading={props.isUpdatingComment}
                                >Save</Button>
                                &nbsp;
                                <Button
                                    size={"small"}
                                    icon={<CloseOutlined />}
                                    onClick={cancelEditing}
                                >Cancel</Button>

                                {props.isUpdateError && (
                                    <Alert
                                        message="Error updating comment"
                                        type="error"
                                        showIcon
                                        style={{height: "1.5rem"}}
                                    />
                                )}
                            </Form>
                        ) : (
                            <ExpandableElement overflowHeight={"8rem"}>
                                <Markdown breaks={true}>{comment.content}</Markdown>
                            </ExpandableElement>
                        )}
                    </List.Item>
                )}
                footer={
                    <Form
                        initialValues={{content: ""}}
                        onFinish={handleAddNewComment}
                        form={newCommentForm}
                        className={"new-comment-wrapper"}
                        key={`new-comment-form`}
                    >
                        <Form.Item
                            name="content"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a comment",
                                    whitespace: true,
                                },
                            ]}
                            style={{width: "95%"}}
                        >
                            <TextArea autoSize placeholder="Add a comment" id={"new-comment-input"}
                                      onKeyDown={handleKeyPress}/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                htmlType="submit"
                                loading={props.isAddingComment}
                                icon={<SendOutlined/>}
                            />
                        </Form.Item>

                        {props.isSubmitError && (
                            <Alert message="Error adding comment" type="error" showIcon/>
                        )}
                    </Form>
                }
            />
        </ConfigProvider>
    );
};

export default CourseComments;
