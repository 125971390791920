import {Section} from "./models";
import {
    BlockOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    InfoCircleOutlined,
    TeamOutlined,
    UserAddOutlined,
    UsergroupAddOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {Avatar, Button, List, Popconfirm, Spin, Tooltip} from "antd";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import CourseService from "./services/CourseService";
import {useState} from "react";

export interface CourseCardSectionListItemProps {
    section: Section;
    courseService: CourseService;
    onInitiateUpdateStaticSection: (canvasSectionId: number) => void;
    onInitiateAddStudentToStaticSection: (canvasSectionId: number) => void;
}


interface DeleteCourseSectionsVars {
    courseId: number,
    sectionId: number,
}

export const CourseCardSectionListItem = ({
                                              section,
                                              courseService,
                                              onInitiateUpdateStaticSection,
                                              onInitiateAddStudentToStaticSection
                                          }: CourseCardSectionListItemProps) => {
    // const courseListDispatch = useCoursesListDispatch();
    const queryClient = useQueryClient();
    
    const [downloadIsLoading, setDownloadIsLoading] = useState(false);
    
    const {
        mutate: deleteSection,
        isLoading: deletionRequestIsLoading,
        error: deletionError
    } = useMutation(
        ["deletecoursesections", section.canvasId],
        (vars: DeleteCourseSectionsVars) => courseService.deleteSection(vars.courseId, vars.sectionId),
        {
            onSuccess: async (updatedSections: Section[], vars: DeleteCourseSectionsVars) => {
                queryClient.setQueryData(["sections", vars.courseId], updatedSections);
            }
        }
    );
    
    let addStudentIcon = <UserAddOutlined/>;
    
    let deleteButtonIcon = <DeleteOutlined/>;
    if (deletionRequestIsLoading) {
        deleteButtonIcon = <Spin size={"small"}/>
    } else if (deletionError || section.deletingStatus === 'error') {
        deleteButtonIcon = <WarningOutlined/>
    }
    
    const downloadButtonIcon = (downloadIsLoading) ? <Spin size={"small"}/> : <DownloadOutlined/>;
    
    const updateButtonIcon = <EditOutlined/>;
    
    const sectionIcon = section.sisSectionId?.startsWith('MIRR.') ? <BlockOutlined/> :
        section.sisSectionId?.startsWith('STATIC.') ? <UsergroupAddOutlined/> : <TeamOutlined/>;
    
    let actions = undefined;
    if (section.sisSectionId?.startsWith('MIRR.')) {
        actions = [
            <Popconfirm title={`Remove mirrored section from the course?`}
                        icon={<DeleteOutlined className='delete-btn'/>}
                        onConfirm={async () => {
                            deleteSection({courseId: section.canvasCourseId, sectionId: section.canvasId});
                        }}
                        okText="Remove mirrored section"
                        cancelText="Cancel"
                        disabled={section.deletingStatus === 'deleting'}
            >
                <Button disabled={section.deletingStatus === 'deleting'} type="text">{deleteButtonIcon}</Button>
            </Popconfirm>
        ];
    } else if (section.sisSectionId?.startsWith('STATIC.')) {
        actions = [
            <Button type="text"
                    onClick={() => onInitiateAddStudentToStaticSection(section.canvasId)}
                    disabled={section.deletingStatus === 'deleting'}>{addStudentIcon}</Button>,
            <Button type="text"
                    onClick={() => onInitiateUpdateStaticSection(section.canvasId)}
                    disabled={section.deletingStatus === 'deleting'}>{updateButtonIcon}</Button>,
            <Button type="text"
                    disabled={section.deletingStatus === 'deleting'}
                    onClick={async () => {
                setDownloadIsLoading(true);
                await courseService.downloadStaticSection(section);
                setDownloadIsLoading(false);
            }}>{downloadButtonIcon}</Button>,
            <Popconfirm title={`Remove static section from the course?`}
                        icon={<DeleteOutlined className='delete-btn'/>}
                        onConfirm={async () => {
                            deleteSection({courseId: section.canvasCourseId, sectionId: section.canvasId});
                        }}
                        okText="Remove static section"
                        cancelText="Cancel"
                        disabled={section.deletingStatus === 'deleting'}
            >
                <Button type="text" disabled={section.deletingStatus === 'deleting'}>{deleteButtonIcon}</Button>
            </Popconfirm>
        ];
    }
    
    return <List.Item key={section.canvasId} actions={actions}>
        
        <List.Item.Meta title={section.deletingStatus === 'deleting' ? <>
            {section.name}
            <i> (deleting) </i>
            <Tooltip title={"This section is currently being deleted. This can take a while."}>
                <InfoCircleOutlined/>
            </Tooltip>
        
        </> : section.name}
                        avatar={<Avatar icon={sectionIcon}/>}
                        description={
                            <>
                                <span>{section.sisSectionId ?? '-- no SIS ID available --'}</span><br/>
                            </>
                        }/>
    </List.Item>
    
    
}