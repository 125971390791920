import CourseService from "./services/CourseService";

import CourseList from "./CourseList";
import CourseListFilters from "./CourseListFilters";
import ReferenceDataService from "./services/ReferenceDataService";
import {InfiniteData, useInfiniteQuery, useQuery, useQueryClient} from "@tanstack/react-query";
import {Account, Course, CourseSetupState, CoursesPage, Operation, Term, User} from "./models";
import {useCallback, useEffect, useReducer} from "react";
import {LocalDataService} from "./services/LocalDataService";
import {useNavigate, useSearchParams} from "react-router-dom";
import AccountService from "./services/AccountService";
import CourseAvailabilityService from "./services/CourseAvailabilityService";
import UserService from "./services/UserService";
import RoleService from "./services/RoleService";
import {CourseModals} from "./CourseModals";
import {useCoursesListDispatch} from "./CoursesContext";

type CourseSetupOverviewProps = {
    courseService: CourseService;
    accountService: AccountService;
    userService: UserService;
    roleService: RoleService;
    referenceDataService: ReferenceDataService;
    localDataService: LocalDataService;
    courseAvailabilityService: CourseAvailabilityService;
    hideYearAndTermFilters?: boolean;
}

interface CourseSetupOverviewState {
    selectedYear?: string;
    selectedTerm?: Term;
    selectedAccount?: Account;
    expandedCourse?: number;
    searchTerm?: string;
    editedSearchText?: string ;
    staffSearchTerm?: string;
    selectedStaffId?: number;
    selectedState?: CourseSetupState;
    selectedSiteType?: string;
    includeDeletedSites?: boolean;
    includeConcludedSites?: boolean;
}

interface SetSelectedYear {
    name: "SetSelectedYear";
    year: string | undefined;
}

interface SetSelectedAccount {
    name: "SetSelectedAccount";
    account?: Account;
}

interface SetSelectedTerm {
    name: "SetSelectedTerm";
    term?: Term;
}

interface SetSearchTerm {
    name: "SetSearchTerm";
    searchTerm: string | undefined;
}
interface SetEditedSearchText {
    name: "SetEditedSearchText";
    editedSearchText: string | undefined;
}
interface SetStaffSearchTerm {
    name: "SetStaffSearchTerm";
    staffSearchTerm: string | undefined;
}

interface SetSelectedStaffUsername {
    name: "SetSelectedStaffUsername";
    selectedStaffId: number | undefined;
}

interface SetSelectedState {
    name: "SetSelectedState";
    selectedState: CourseSetupState | undefined;
}
interface SetSelectedSiteType {
    name: "SetSelectedSiteType";
    selectedSiteType: string | undefined;
}

interface SetCheckedIncludeDeleted {
    name: "SetCheckedIncludeDeleted",
    checkedIncludeDeleted: boolean
}

interface SetCheckedIncludeConcluded {
    name: "SetCheckedIncludeConcluded",
    checkedIncludeConcluded: boolean
}

export type CourseSetupOverviewStateAction =
    | SetSelectedYear
    | SetSelectedTerm
    | SetSelectedAccount
    | SetSearchTerm
    | SetStaffSearchTerm
    | SetSelectedStaffUsername
    | SetSelectedState
    | SetSelectedSiteType
    | SetEditedSearchText
    | SetCheckedIncludeDeleted
    | SetCheckedIncludeConcluded

function courseSetupOverviewStateReducer(state: CourseSetupOverviewState, action: CourseSetupOverviewStateAction): CourseSetupOverviewState {
    switch (action.name) {
        case "SetSelectedYear":
            return {
                ...state,
                selectedYear: action.year,
                selectedTerm: undefined,
            };
        case "SetSelectedTerm":
            return {
                ...state,
                selectedTerm: action.term,
            };
        case "SetSelectedAccount":
            return {
                ...state,
                selectedAccount: action.account,
            };
        case "SetSearchTerm":
            return {
                ...state,
                searchTerm: action.searchTerm,
                editedSearchText: undefined,
            };
        case "SetEditedSearchText":
            return {
                ...state,
                editedSearchText: action.editedSearchText,
            };
        case "SetSelectedStaffUsername":
            return {
                ...state,
                selectedStaffId: action.selectedStaffId,
                staffSearchTerm: undefined,
            }
        case "SetStaffSearchTerm":
            return {
                ...state,
                staffSearchTerm: action.staffSearchTerm
            }
        case "SetSelectedState":
            return {
                ...state,
                selectedState: action.selectedState,
            }
        case "SetSelectedSiteType":
            return {
                ...state,
                selectedSiteType: action.selectedSiteType,
            }
        case "SetCheckedIncludeDeleted":
            return {
                ...state,
                includeDeletedSites: action.checkedIncludeDeleted,
            }
        case "SetCheckedIncludeConcluded":
            return {
                ...state,
                includeConcludedSites: action.checkedIncludeConcluded,
            }
    }
}

type SearchParamKeys = 'selectedYear' | 'selectedTerm' | 'selectedAccount' | 'selectedStaffId' | 'searchTerm' |
    'selectedSiteType' | 'selectedState' | 'includeDeleted' | 'includeConcluded';

function calculateSelectedYear(selectedYear: string|undefined, yearFromLocalData: string | undefined) {
    if (selectedYear) {
        return selectedYear;
    } else {
        if (yearFromLocalData) {
            return yearFromLocalData;
        } else {
            return "allyears"
        }
    }
}

export default function CourseSetupOverview(props: CourseSetupOverviewProps) {

    const queryClient = useQueryClient();

    const [searchParams, setSearchParams] = useSearchParams();
    
    const getSearchParam = (key: SearchParamKeys) => searchParams.get(key);
    const updateSearchParam = useCallback((key: SearchParamKeys, value: string | undefined) => {
        const updatedParams = new URLSearchParams();
        searchParams.forEach((v, k) => updatedParams.set(k, v));
        updatedParams.delete(key);
        if (value && value.trim() !== '') {
            updatedParams.set(key, value);
        }
        setSearchParams(updatedParams);
    }, [searchParams, setSearchParams]);

    const selectedYear = getSearchParam('selectedYear') ?? undefined;
    const selectedTerm = getSearchParam('selectedTerm') ?? undefined;
    const selectedAccount = getSearchParam('selectedAccount') ?? undefined;
    const selectedStaffUsername = getSearchParam('selectedStaffId') ?? undefined;
    const selectedSearchTerm = getSearchParam('searchTerm') ?? undefined;
    const selectedSiteType = getSearchParam('selectedSiteType') ?? undefined;
    const selectedState = getSearchParam('selectedState') ?? undefined;
    const checkedIncludeDeleted = getSearchParam('includeDeleted') ?? undefined;
    const checkedIncludeConcluded = getSearchParam('includeConcluded') ?? undefined;



    const coursesListDispatch = useCoursesListDispatch();

    const yearFromLocalData = props.localDataService.getSelectedYearFilter() ?? undefined;
    const termFromLocalData = props.localDataService.getSelectedTermFilter() ?? undefined;

    const calculatedYearValue = calculateSelectedYear(selectedYear, yearFromLocalData)
    const calculatedTermValue = selectedTerm === 'allterms' ? undefined : termFromLocalData;
    
    const initialState: CourseSetupOverviewState = {
        selectedYear: calculatedYearValue, 
        selectedTerm: calculatedTermValue,
    };

    const [state, dispatch] = useReducer(courseSetupOverviewStateReducer, initialState)

    // useEffect(() => {
    //     props.localDataService.saveSelectedTermFilter(state.selectedTerm)
    // }, [state.selectedTerm, props.localDataService]);
    const siteTypes = searchParams.get('siteTypes')?.split(',') ?? [];

    const navigate = useNavigate();

    const {data: years, isFetching: fetchingYears} = useQuery<string[], Error>(["allyears"],
        () => {
            return props.referenceDataService.getYears();
        }, );

    const {data: terms, isFetching: fetchingTerms} = useQuery<Term[], Error>(["termsforyear", calculatedYearValue],
        () => {
            if (calculatedYearValue && calculatedYearValue !== 'allyears') {
                return props.referenceDataService.getTermsForYear(calculatedYearValue);
            } else {
                return props.referenceDataService.getAllTerms();
            }
        });

    const {data: accounts, isFetching: fetchingAccounts} = useQuery<Account[], Error>(["accounts"],
        () => {
            return props.accountService.getAllAccounts();
        });

    const getCoursesQueryKey = ["allcourses", calculatedYearValue, calculatedTermValue, state.selectedAccount, state.searchTerm, state.selectedStaffId, state.selectedState, siteTypes, state.selectedSiteType, state.includeConcludedSites, state.includeDeletedSites];
    const {
        data: coursePages,
        isFetching: fetchingCourses,
        error: courseFetchError,
        isLoading: loadingCourses,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery<CoursesPage, Error>(
        getCoursesQueryKey,
        ({pageParam: lastEvaluatedCourseId}) => {
            return props.courseService.getAllCourses(
                (calculatedYearValue === 'allyears' ? undefined : calculatedYearValue),
                calculatedTermValue?.canvasId,
                state.selectedAccount?.treePath,
                state.searchTerm,
                state.selectedStaffId,
                state.selectedState,
                state.includeDeletedSites,
                state.includeConcludedSites !== false,
                state.selectedSiteType ? [state.selectedSiteType] : siteTypes,
                lastEvaluatedCourseId);
        }, {
            refetchInterval: (data) => {
                const courses = data?.pages?.flatMap(c => c.courses);
                if (!courses) {
                    return false;
                }
                return courses.findIndex(c => c.copyInProgress) !== -1 ? 5000 : false
            },
            getNextPageParam: lastPage => lastPage.lastEvaluatedCourseId,
            refetchOnMount: true,
        });
    const refreshCourses = () => queryClient.invalidateQueries(getCoursesQueryKey);

    const courses = coursePages?.pages.flatMap(c => c.courses);

    useEffect(() => {
        if(!hasNextPage && !fetchingCourses) {
            const courseCount = courses?.length ?? 0;
            if(courseCount !== undefined) {
                console.log(`Last page of courses loaded. ${courseCount} courses listed.`);
            }
        }
    }, [hasNextPage, fetchingCourses, courses]);


    useEffect(()=>{
        // dispatch({name: "SetSelectedTerm", term: terms?.find((term: Term) => term.canvasId === parseInt(selectedTerm ?? ''))});
        dispatch({name: "SetSelectedAccount", account: accounts?.find((account: Account) => account.canvasId === parseInt(selectedAccount ?? ''))});
        dispatch({name: "SetSearchTerm", searchTerm: selectedSearchTerm});
        dispatch({name: "SetSelectedStaffUsername", selectedStaffId: selectedStaffUsername ? parseInt(selectedStaffUsername) : undefined});
        dispatch({name: "SetSelectedState", selectedState: getSelectedCourseSetupState(selectedState)});
        dispatch({name: "SetSelectedSiteType", selectedSiteType});
        dispatch({name: "SetCheckedIncludeDeleted", checkedIncludeDeleted: checkedIncludeDeleted === "true"});
        dispatch({name: "SetCheckedIncludeConcluded", checkedIncludeConcluded: checkedIncludeConcluded === "true"});
    }, [calculatedYearValue, selectedTerm, selectedAccount, selectedSearchTerm, selectedSiteType, selectedState, selectedStaffUsername,
        accounts, terms, checkedIncludeDeleted, checkedIncludeConcluded]);

    useEffect(() => {
        if (!selectedYear) {
            updateSearchParam("selectedYear", calculatedYearValue);
        }
        if (!selectedTerm) {
            updateSearchParam("selectedTerm", calculatedTermValue?.canvasId?.toString() ?? "allterms");
        }
        if (!checkedIncludeConcluded) {
            updateSearchParam("includeConcluded", state.includeConcludedSites?.toString() ?? "true");
        }
    }, [calculatedYearValue, selectedYear, calculatedTermValue?.canvasId, updateSearchParam, selectedTerm, state.includeConcludedSites, checkedIncludeConcluded]);

    const {data: userSearchResults, isFetching: fetchingUsers} = useQuery<User[], Error>(["usersearch", state.staffSearchTerm, state.selectedStaffId],
            async () => {
                if (state.selectedStaffId && !(state.staffSearchTerm && state.staffSearchTerm.length >= 2)) {
                    return [await props.userService.getUserByCanvasId(state.selectedStaffId)];
                } else if (state.staffSearchTerm && state.staffSearchTerm.length >= 2) {
                    return props.userService.searchUsers(state.staffSearchTerm)
                } else {
                    return []
                }
            });

    const closeModal = () => navigate({
        pathname: `/courses`,
        search: searchParams.toString()
    });

    const initiateOperation = (canvasCourseId: number, operation: Operation) => {
        navigate({
            pathname: `/courses/${canvasCourseId}/${operation}`,
            search: searchParams.toString()
        });
    };

    const initiateSectionOperation = (canvasCourseId: number, canvasSectionId: number, operation: Operation) => {
        navigate({
            pathname: `/courses/${canvasCourseId}/sections/${canvasSectionId}/${operation}`,
            search: searchParams.toString()
        });
    };

    const updateCourseInCache = (updatedCourse: Course) => {
        queryClient.setQueryData(getCoursesQueryKey, (oldData: InfiniteData<CoursesPage> | undefined) => {
            const oldInfiniteData = oldData as InfiniteData<CoursesPage> | undefined
            return oldInfiniteData ? {
                pages: oldInfiniteData.pages.map(page => {
                    return {
                        ...page,
                        courses: page.courses?.map(c => c.canvasId === updatedCourse.canvasId ? updatedCourse : c) ?? []
                    }
                }),
                pageParams: oldInfiniteData.pageParams,
            } as InfiniteData<CoursesPage> : undefined;
        })
    }

    return <>
        <CourseListFilters selectedYear={calculatedYearValue}
                           selectedTerm={calculatedTermValue}
                           selectedAccount={state.selectedAccount}
                           selectedSiteType={state.selectedSiteType}
                           selectedState={state.selectedState}
                           selectedStaffId={state.selectedStaffId}
                           loadingTerms={fetchingTerms}
                           loadingYears={fetchingYears}
                           loadingAccounts={fetchingAccounts}
                           accounts={accounts}
                           years={years}
                           terms={terms}
                           onYearChange={(year: string | undefined) => {
                               dispatch({name: "SetSelectedYear", year: year});
                               props.localDataService.saveSelectedYearFilter(year);
                               props.localDataService.saveSelectedTermFilter(undefined);
                               updateSearchParam("selectedTerm", undefined);
                               updateSearchParam("selectedYear", year);
                           }}
                           onTermChange={(termId: number | undefined) => {
                               const term = terms?.find(term => termId === term.canvasId);
                               props.localDataService.saveSelectedTermFilter(term);
                               dispatch({name: "SetSelectedTerm", term:term});
                               updateSearchParam("selectedTerm", (termId ? '' + termId : undefined));
                           }}
                           onAccountChange={(accountId: number | undefined) => {
                               updateSearchParam("selectedAccount", (accountId ? '' + accountId : undefined));
                           }}
                           searchText={state.editedSearchText ?? state.searchTerm}
                           onSearch={(searchTerm: string | undefined) => updateSearchParam("searchTerm", searchTerm)}
                           onSearchTextChange={(editedSearchText: string | undefined) => dispatch({name: "SetEditedSearchText", editedSearchText})}
                           onStaffSearch={(staffSearchTerm: string | undefined) => dispatch({name: "SetStaffSearchTerm",staffSearchTerm})}
                           onStaffChange={(selectedStaffId) =>
                               updateSearchParam("selectedStaffId", (selectedStaffId ? '' + selectedStaffId : undefined))
                           }
                           onStateChange={(selectedState) => updateSearchParam("selectedState", selectedState)}
                           userSearchResults={userSearchResults}
                           loadingStaffSearchResults={fetchingUsers}
                           siteTypes={siteTypes}
                           onSiteTypeChange={(siteType: string | undefined) => {
                               updateSearchParam('selectedSiteType', siteType)
                           }}
                           hideYearAndTermFilters={props.hideYearAndTermFilters}
                           onIncludeDeletedSites={(isIncluded: boolean) => updateSearchParam("includeDeleted", isIncluded.toString())}
                           onIncludeConcludedSites={(isIncluded: boolean) => updateSearchParam("includeConcluded", isIncluded.toString())}
                           includeDeletedSites={state.includeDeletedSites}
                           includeConcludedSites={state.includeConcludedSites}
        />
        <CourseList courseService={props.courseService}
                    courses={courses}
                    fetchingCourses={loadingCourses}
                    error={courseFetchError}
                    onInitiateCopyContent={(canvasCourseId) => initiateOperation(canvasCourseId, "copy")}
                    onInitiateCourseRelease={(canvasCourseId) => initiateOperation(canvasCourseId, "release")}
                    onInitiateMakeAvailable={(canvasCourseId) => initiateOperation(canvasCourseId, "makeAvailable")}
                    onInitiateManageUsers={(canvasCourseId) => initiateOperation(canvasCourseId, "manageUsers")}
                    onInitiateAddMirroredSection={(canvasCourseId) => initiateOperation(canvasCourseId, "addMirroredSection")}
                    onInitiateAddStaticSection={(canvasCourseId) => initiateOperation(canvasCourseId, "addStaticStudentSection")}
                    onInitiateUpdateStaticSection={(canvasCourseId, canvasSectionId) => initiateSectionOperation(canvasCourseId, canvasSectionId,"updateStaticStudentSection")}
                    onInitiateAddStudentToStaticSection={(canvasCourseId, canvasSectionId) => initiateSectionOperation(canvasCourseId, canvasSectionId,"addStudentToStaticStudentSection")}
                    onCourseUpdated={updateCourseInCache}
                    onSkipCopy={refreshCourses}
                    hasMoreCourses={!!hasNextPage}
                    loadMoreCourses={() => fetchNextPage()}
        />
        <CourseModals
            onCancel={closeModal}
            onSuccess={async () => {
                closeModal();
                await refreshCourses();
                coursesListDispatch({name: "ResetExpandedCourseDetails"});
            }}
            courseAvailabilityService={props.courseAvailabilityService}
            courseService={props.courseService}
            userService={props.userService}
            roleService={props.roleService}
        />
    </>;

}

const getSelectedCourseSetupState = (selectedStateString: string|undefined): CourseSetupState | undefined  => {
    for (let enumKey of Object.keys(CourseSetupState) as (keyof typeof CourseSetupState)[]) {
        if (CourseSetupState[enumKey] === selectedStateString) {
            return CourseSetupState[enumKey];
        }
    }
    return undefined;
}