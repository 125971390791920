/*
 * This file is "owned" by the UI package, and then copied into the Admin UI package.  If you need to modify them,
 * please do so in the UI package, and then copy the modified file into the Admin UI package using the shared-files.sh
 * script.
 */

import {MessagesConfig} from "../models";

export const defaultMessages: Required<MessagesConfig> = {
    ltiUnauthorized: "You do not have access to this tool.",
    contactInformation: "For further assistance, please contact your support team.",
    courseStateDescriptionAwaitingCopy: "The site has been created and will soon have content copied into it from a previous offering",
    courseStateDescriptionAwaitingReview: "Content has been copied into the site and is awaiting an initial review",
    courseStateDescriptionReleased: "The site has been made available to staff within the site and the coordinator(s) has been notified.",
    courseStateDescriptionAvailable: "<p>The site has been made available to students by the coordinator or a manager within the site.</p>\n <p>Note: Once a site has been published, if students have made submissions, the site cannot be unpublished.</p>",
    courseStateDescriptionCompleted: "The teaching period has expired and the course dates have passed. The course is now in a read only state.",
    staffEnrollmentToolsCourseConcluded: "Canvas does not permit enrolment changes to concluded course sites.",
    coursePublishInvalidStaffWarningConfirm: "No course coordinator is currently enrolled in this course. Are you sure you wish to proceed?",
    coursePublishInvalidStaffWarning: "Course sites cannot be published to students without a course coordinator assigned to the course.",
    staticStudentFileHelp: "<p>The import file for a Static Student list should be a CSV formatted as below:</p><pre>UserSisId<br />user1<br />user2</pre><p>The headers are required to be included in the file.</p><p>UserSisId is the <em>sis_user_id</em> within Canvas.</p>",
    courseReleaseModalTitle: "<p>Release Course</p>",
    courseReleaseModalDescription: "Email to the coordinators of {{courseTitle}}",
    courseReleaseModalReleaseError: "Failed to release the course to the coordinator",
    courseCardActionButtonSkip: "Skip Copy",
    courseCardActionButtonCopy: "Copy Content",
    courseCardActionButtonRelease: "Release Course",
    courseCardActionButtonPublish: "Publish the Course",
    courseCardActionButtonManageStaff: "Manage Staff",
    courseCardActionButtonManageStudents: "Manage Students",
    expiredSessionTitle: "You've been logged out as your session has expired.",
    expiredSessionLti: "SAM has been launched in a new window. Close the window and launch it again.",
    staffEnrolmentToolHelpText: "Manage the staff in your course. Some roles may not be activated until after the course has been released."
}