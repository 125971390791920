import './auditlogfilters.css';
import {Button, Checkbox, Input, Select, Space} from "antd";

import {SearchOutlined} from "@ant-design/icons";

const { Option } = Select;

export interface AuditLogFiltersProps {

    onSearchClicked?: () => void;
    onExportClicked?: () => void;
    onCourseIdChanged?: (courseSisId: string) => void;
    onSectionIdChanged?: (sectionSisId: string) => void;
    onTargetUserIdChanged?: (targetUserUsername: string) => void;
    onPrincipalIdChanged?: (principalUsername: string) => void;
    onEventSourceChanged?: (eventSource: string) => void;
    onTargetSystemChanged?: (targetSystem: string) => void;
    onExcludeIntegrationUserChanged?: (excludeIntegrationUser: boolean) => void;

    courseId?: string,
    sectionId?: string,
    userId?: string,
    principalId?: string,
    eventSource?: string,
    targetSystem?: string,
    excludeIntegrationUser: boolean;
}


export default function AuditLogFilters(props: AuditLogFiltersProps) {
    return <div className={'auditlogfilters-container'}>
            <Space direction="vertical" size="small" className={"auditlogfilters-fields"}>
                <Input className="auditlogs-searchinput" placeholder={"Course SIS ID"}
                       onChange={(e) => props.onCourseIdChanged?.(e.target.value)} allowClear value={props.courseId}></Input>
                <Input className="auditlogs-searchinput" placeholder={"Section SIS ID"}
                       onChange={(e) => props.onSectionIdChanged?.(e.target.value)} allowClear value={props.sectionId}></Input>
                <Input className="auditlogs-searchinput" placeholder={"Target User Login Id (username)"}
                       onChange={(e) => props.onTargetUserIdChanged?.(e.target.value)} allowClear value={props.userId}></Input>
                <Input className="auditlogs-searchinput" placeholder={"Initiated By Login Id (username)"}
                       onChange={(e) => props.onPrincipalIdChanged?.(e.target.value)} allowClear value={props.principalId}></Input>
                <Select className="auditlogs-searchinput" placeholder={"Event Source"}
                       onChange={(val: string) => props.onEventSourceChanged?.(val)} allowClear value={props.eventSource}>
                    <Option value={"CANVAS"}>CANVAS</Option>
                    <Option value={"SAM"}>SAM</Option>
                </Select>
                <Select className="auditlogs-searchinput" placeholder={"Target System"}
                       onChange={(val: string) => props.onTargetSystemChanged?.(val)} allowClear value={props.targetSystem}>
                    <Option value={"CANVAS"}>CANVAS</Option>
                    <Option value={"SAM"}>SAM</Option>
                </Select>
                <Checkbox onChange={(e) => props.onExcludeIntegrationUserChanged?.(e.target.checked)} checked={props.excludeIntegrationUser}>Exclude Integration User Records</Checkbox>
                <Button type="primary" icon={<SearchOutlined />} onClick={props.onSearchClicked} disabled={!props.courseId && !props.sectionId && !props.userId && !props.principalId && !props.eventSource && !props.targetSystem}>
                    Search
                </Button>
            </Space>
    </div>;

}
