import './auditlogentry.css';
import {Col, Row} from "antd";
import {
    InfoCircleOutlined,
    MessageOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import {ActivityLogEntry} from "./models";
import Markdown from 'marked-react';
import {AuditLogUserDto} from "./dtos";


export interface AuditLogEntryComponentProps {
    auditLogEntry: ActivityLogEntry;
}
export const AuditLogEntryComponent = ({auditLogEntry} : AuditLogEntryComponentProps) => {

    let infoAreaClassname = ""
    let titleIcon = <WarningOutlined />
    switch (auditLogEntry.logLevel) {
        case "ERROR":
            titleIcon = <WarningOutlined />;
            infoAreaClassname = "auditlogentry-errorinfo";
            break;
        case "INFO":
            titleIcon = <MessageOutlined />;
            infoAreaClassname = "auditlogentry-infoinfo";
            break;
        case "WARNING":
            titleIcon = <InfoCircleOutlined />;
            infoAreaClassname = "auditlogentry-warninginfo";
            break;
    }

    const userDom = targetUserDom(auditLogEntry);
    const sectionDom = targetSectionDom(auditLogEntry);
    const courseDom = targetCourseDom(auditLogEntry);

    let userCourseRow = undefined;
    if (userDom || courseDom) {
        userCourseRow = <Row>
            <Col span={8}>{userDom ?? <></>}</Col>
            <Col span={16}>{courseDom ?? <></>}</Col>
        </Row>
    } else {
        userCourseRow = <></>
    }
    let sectionRow = undefined;
    if (sectionDom) {
        sectionRow = <Row>
            <Col span={24}>{sectionDom ?? <></>}</Col>
        </Row>
    } else {
        sectionRow = <></>
    }

    const targetSystem = auditLogEntry.targetSystem === "CANVAS" ? "Canvas" : auditLogEntry.targetSystem === "SAM" ? "SAM" : "UNKNOWN";

    return <div className={"auditlogentry"}>
        <div className={"auditlogentry-titlebar"}>{titleIcon}<h1>{targetSystem} - {messageForActivityLogAction(auditLogEntry)}</h1></div>
        <div className={"auditlogentry-summaryarea"}>
            <Row>
                <Col span={8}><><b>Date: </b>{auditLogEntry.logTimeJsDate ? auditLogEntry.logTimeJsDate.toLocaleString() : 'No Date Available.'}</></Col>
                <Col span={8}><b>Initiated By: </b>{mapPrincipalToLabel(auditLogEntry.principal)}</Col>
                <Col span={8}><b>Event Source: </b>{auditLogEntry.eventSource}</Col>
            </Row>
        </div>
        <div className={infoAreaClassname}>

            {userCourseRow}
            {sectionRow}
            <Row>
                <Col span={24}><b>Details: </b> <Markdown>{auditLogEntry.message}</Markdown></Col>
            </Row>
        </div>
    </div>

}

const targetUserDom = (entry: ActivityLogEntry) => {
    const dom = <b>User: </b>;
    switch (entry.action) {
        case "ADD_USER_TO_SECTION":
        case "ADD_USER":
        case "ENROL_STAFF_MEMBER":
        case "UNENROL_STAFF_MEMBER":
        case "REMOVE_USER":
        case "RELEASE_COURSE_TO_COORDINATOR":
        case "STAFF_ACTIVATED_FOR_COURSE":
        case "ADD_STAFF_TO_COURSE":
        case "ADD_USER_TO_COURSE":
        case "UPDATE_USER":
        case "REMOVE_USER_FROM_SECTION":
            return <>{dom}{entry.targetUser.displayName} ({entry.targetUser.sisUserId})</>;
        case "COURSE_CREATED":
        case "COURSE_UPDATED":
        case "COURSE_DELETED":
        case "SKIP_COURSE_COPY":
        case "INITIATE_CONTENT_COPY":
        case "CONTENT_COPY_COMPLETE":
        case "MAKE_COURSE_AVAILABLE":
        case "USER_LOGGED_IN":
        case "SECTION_CREATED":
        case "SECTION_CROSSLISTED":
        case "SECTION_MIRRORED_FROM":
        case "SECTION_MIRRORED_TO":
        case "MIRRORED_SECTION_SYNC":
        case "COURSE_SECTION_CREATED":
        case "COURSE_SECTION_DELETED":
        case "COURSE_SECTION_UPDATED":
            return undefined;
    }
}

const targetCourseDom = (entry: ActivityLogEntry) => {
    const dom = <b>Course: </b>;
    switch (entry.action) {
        case "ADD_USER_TO_SECTION":
        case "ENROL_STAFF_MEMBER":
        case "UNENROL_STAFF_MEMBER":
        case "RELEASE_COURSE_TO_COORDINATOR":
        case "STAFF_ACTIVATED_FOR_COURSE":
        case "COURSE_CREATED":
        case "COURSE_UPDATED":
        case "COURSE_DELETED":
        case "SKIP_COURSE_COPY":
        case "ADD_STAFF_TO_COURSE":
        case "ADD_USER_TO_COURSE":
        case "INITIATE_CONTENT_COPY":
        case "CONTENT_COPY_COMPLETE":
        case "MAKE_COURSE_AVAILABLE":
        case "SECTION_CREATED":
        case "SECTION_CROSSLISTED":
        case "SECTION_MIRRORED_FROM":
        case "SECTION_MIRRORED_TO":
        case "COURSE_SECTION_CREATED":
        case "COURSE_SECTION_DELETED":
        case "COURSE_SECTION_UPDATED":
        case "REMOVE_USER_FROM_SECTION":
        case "MIRRORED_SECTION_SYNC":
            return <>{dom} {entry.targetCourse.title} ({entry.targetCourse.sisCourseId})</>;
        case "ADD_USER":
        case "REMOVE_USER":
        case "UPDATE_USER":
        case "USER_LOGGED_IN":
            return undefined;
    }
}

const targetSectionDom = (entry: ActivityLogEntry) => {
    const dom = <b>Section: </b>;
    switch (entry.action) {
        case "ADD_USER_TO_SECTION":
        case "ENROL_STAFF_MEMBER":
        case "UNENROL_STAFF_MEMBER":
        case "SECTION_CREATED":
        case "SECTION_CROSSLISTED":
        case "SECTION_MIRRORED_FROM":
        case "SECTION_MIRRORED_TO":
        case "COURSE_SECTION_CREATED":
        case "COURSE_SECTION_DELETED":
        case "COURSE_SECTION_UPDATED":
        case "MIRRORED_SECTION_SYNC":
            return <>{dom} {entry.targetSection.title} ({entry.targetSection.sisSectionId})</>;
        case "RELEASE_COURSE_TO_COORDINATOR":
        case "STAFF_ACTIVATED_FOR_COURSE":
        case "COURSE_CREATED":
        case "COURSE_UPDATED":
        case "COURSE_DELETED":
        case "SKIP_COURSE_COPY":
        case "ADD_STAFF_TO_COURSE":
        case "ADD_USER_TO_COURSE":
        case "INITIATE_CONTENT_COPY":
        case "CONTENT_COPY_COMPLETE":
        case "MAKE_COURSE_AVAILABLE":
        case "ADD_USER":
        case "REMOVE_USER":
        case "UPDATE_USER":
        case "USER_LOGGED_IN":
        case "REMOVE_USER_FROM_SECTION":
            return undefined;
    }
}
const messageForActivityLogAction = (entry: ActivityLogEntry): string => {
    switch (entry.action) {
        case "ADD_USER_TO_SECTION":
            return "User Added to Section";
        case "ADD_USER":
            return "User Added to Canvas";
        case "COURSE_CREATED":
            return "Course Created";
        case "COURSE_UPDATED":
            return "Course Updated";
        case "COURSE_DELETED":
            return "Course Deleted";
        case "SKIP_COURSE_COPY":
            return "Skip Course Copy";
        case "ENROL_STAFF_MEMBER":
            return "Staff Member Enrolled in Course";
        case "UNENROL_STAFF_MEMBER":
            return "Staff Member Unenrolled from Course";
        case "INITIATE_CONTENT_COPY":
            return "Copy Content Initiated";
        case "CONTENT_COPY_COMPLETE":
            return "Copy Content Completed";
        case "MAKE_COURSE_AVAILABLE":
            return "Course Published";
        case "RELEASE_COURSE_TO_COORDINATOR":
            return "Course Released";
        case "STAFF_ACTIVATED_FOR_COURSE":
            return "Staff Activated for Course"
        case "ADD_STAFF_TO_COURSE":
            return "Staff Added to Course";
        case "ADD_USER_TO_COURSE":
            return "User Added to Course";
        case "USER_LOGGED_IN":
            return "User Logged in to SAM"
        case "REMOVE_USER":
            return "User Removed from Canvas";
        case "UPDATE_USER":
            return "User Updated in Canvas";
        case "SECTION_CREATED":
            return "Section created in course";
        case "SECTION_CROSSLISTED":
            return "Section Cross-listed";
        case "SECTION_MIRRORED_FROM":
            return "Section Mirrored From...";
        case "SECTION_MIRRORED_TO":
            return "Section Mirrored To...";
        case "COURSE_SECTION_CREATED":
            return "Course Section Created";
        case "COURSE_SECTION_DELETED":
            return "Course Section Deleted";
        case "COURSE_SECTION_UPDATED":
            return "Course Section Updated";
        case "REMOVE_USER_FROM_SECTION":
            return "User Removed from Section";
        case "MIRRORED_SECTION_SYNC":
            return "Mirrored Section Synchronised";
    }
}

const mapPrincipalToLabel = (principal: AuditLogUserDto) => {
    switch (principal.userType) {
        case "REAL_USER":
            return `${principal.displayName} (${principal.sisUserId})`;
        case "INTEGRATION_USER":
            return 'Integration User';
        case "DATA_LOAD":
            return 'Data Load';
    }
}