import {ReactNode, useState} from "react";
import {Course, CourseAvailStatement, CourseSetupState, PublicClientConfig} from "./models";
import {useMutation, useQuery} from "@tanstack/react-query";
import CourseAvailabilityService from "./services/CourseAvailabilityService";
import {Alert, Button, ButtonProps, Modal, Result, Space, Spin} from "antd";
import {AgreementList} from "./AgreementList";
import CourseService from "./services/CourseService";
import "./courseavailabilityagreementlist.css";
import { usePublicClientConfig } from "./services/PublicClientConfigService";
import Message from "./Message";

type CommonProps = {
    courseAvailabilityService: CourseAvailabilityService;
    courseService: CourseService;
    course?: Course;
    onCourseMadeAvailable?: (courseId: number) => void;
}

type ModalProps = {
    type: "modal";
    open: boolean;
    onCancel: () => void;
    onCloseAnimationComplete?: () => void;
}

type StandaloneProps = {
    type: "standalone";
}

export type CourseAvailabilityAgreementListProps = CommonProps & (ModalProps | StandaloneProps)

interface UpdateCourseSetupStateVars {
    courseId: number,
    setupState: CourseSetupState,
}

export function CourseAvailabilityAgreementList(props: CourseAvailabilityAgreementListProps) {

    const [allAgreed, setAllAgreed] = useState(false);
    const [samAdminPublishConfirm, setSamAdminPublishConfirm] = useState(false);

    const clientConfig = usePublicClientConfig();
    console.log("clientConfig: ", clientConfig);
    
    const {
        data: statements,
        isFetching: fetchingStatements,
        isError: isFetchingStatementsError
    } = useQuery<CourseAvailStatement[], Error>(["statements", props.course?.siteType],
        () => {
            return props.courseAvailabilityService.getCourseAvailabilityStatementsByType(props.course!.siteType);
        }, {
            enabled: !!props.course && props.course.setupState === CourseSetupState.RELEASED,
        });


    const {
        mutate: updateCourseSetupState,
        isLoading: updateStateIsLoading,
        isError: updateCourseSetupStateError
    } = useMutation(
        ["updatecoursesetupstate"],
        (vars: UpdateCourseSetupStateVars) => props.courseService.updateCourseSetupState(vars.courseId, vars.setupState),
        {
            onSuccess: (course: Course) => props.onCourseMadeAvailable?.(course.canvasId),
        }
    );

    let contactInformationMessage: ReactNode = props.type === "standalone" ? <Message messageKey={"contactInformation"} />: null;

    const canPublishCourse = (course: Course, clientConfig: PublicClientConfig): boolean => {
        const siteTypeRole = clientConfig.siteTypeToPublishRoleMap[course.siteType];
        if (!siteTypeRole) return true;
        return course.teachers?.some(teacher => teacher.role === siteTypeRole) ?? false;
    }    

    let body: ReactNode;
    if(!props.course) {
        body = <Spin/>;     
    } else if (props.course.concluded) {        
        body = <>
            <Result status="warning">{props.course.name} is concluded.<br /><Message messageKey={"staffEnrollmentToolsCourseConcluded"}/></Result>
            {contactInformationMessage}
        </>;
    } else if (props.course.setupState !== CourseSetupState.RELEASED) {
        if (props.course.setupState === CourseSetupState.AVAILABLE) {
            body = <Result status="success">{props.course.name} has been published.</Result>;
        } else {
            body = <Result status="warning">{props.course.name} is not ready to be published.</Result>;
        }
        body = <>
            {body}
            {contactInformationMessage}
        </>;
    } else if (!canPublishCourse(props.course, clientConfig) && !samAdminPublishConfirm) {        
        if (props.type === "modal") { // SAM tool
            body = (
                <Result 
                    status="error" 
                    title={<Message messageKey={"coursePublishInvalidStaffWarningConfirm"}/>}
                    extra={<Button type="primary" size="large" onClick={() => setSamAdminPublishConfirm(true)}>Yes, I am sure</Button>}
                />
            );            
        } else { // Make Available LTI tool
            body = <>
                <Result status="warning" ><Message messageKey={"coursePublishInvalidStaffWarning"}/></Result>
                {contactInformationMessage}
            </>;        
        }
    } else if (isFetchingStatementsError) {
        body = <Result status="error" title="Failed to load statements." />;
    } else if(fetchingStatements || !statements) {
        body = <Spin/>;           
    } else {
        body = <Space direction={"vertical"}>
            { !allAgreed ? <Alert message="You must agree to all items before you can publish the course." type="info" showIcon /> : <></> }
            { props.type === "modal" ?
                <div className="course-avail-heading">{props.course.name}</div> : <></> }
            { updateCourseSetupStateError ? <Alert type="error" message="Failed to publish the course" showIcon /> : <></> }
            <AgreementList key={`agreementList-${props.course.canvasId}`} statements={statements.map(s => s.description)} onAllAgreementChange={setAllAgreed}/>
        </Space>;
    }

    const submitButtonText = "Publish the course";
    const submitButtonProperties: ButtonProps = {
        disabled: !allAgreed,
        loading: updateStateIsLoading,
        onClick: () => {
            if (props.course) {
                updateCourseSetupState({courseId: props.course?.canvasId, setupState: CourseSetupState.AVAILABLE})
            }
        }
    }

    if (props.type === "modal") {
        return <Modal
            open={props.open}
            okText={submitButtonText}
            okButtonProps={submitButtonProperties}
            title="Publish the Course"
            onCancel={props.onCancel}
            afterClose={props.onCloseAnimationComplete}>
            {body}
        </Modal>
    } else {
        return <Space direction="vertical" size="large">
            {body}
            {props.course?.setupState === CourseSetupState.RELEASED ?
                <Button type="primary" {...submitButtonProperties}>{submitButtonText}</Button> : <></>}
        </Space>;
    }
}