/*
 * This file is "owned" by the UI package, and then copied into the Admin UI package.  If you need to modify them,
 * please do so in the UI package, and then copy the modified file into the Admin UI package using the shared-files.sh
 * script.
 */

import {PublicClientConfig} from '../models'
import {createContext, useContext} from "react";

function decodeResponse(response: Response) {
    if (!response.ok) {
        throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
    }
    if(response.status === 204) {
        return Promise.resolve();
    }
    return response.json();
}

export default class PublicClientConfigService {

    private readonly apiBaseUrl: string;

    constructor(apiBaseUrl?: string) {
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    async getPublicClientConfig(): Promise<PublicClientConfig> {
        return fetch(`${this.apiBaseUrl}/api/config`).then(decodeResponse);
    }
}

export const PublicClientConfigContext = createContext<PublicClientConfig>({
    canvasHostDomain: "",
    releaseNotifyRoles: [],
    siteTypeToPublishRoleMap: {},
    includedInTermSummaryReportRoles: [],
    courseMenu: [],
    userCreatableSiteTypes: [],
    messages: {}
});

export const usePublicClientConfig = () => {
    return useContext(PublicClientConfigContext);
}