import {Empty, Select, Spin} from 'antd';
import {Radio, Space} from "antd";
import {Role, User} from './models';
import './staffenrolmentmanageenrolment.css';
import {UserOutlined} from "@ant-design/icons";

type CommonProps = {
    fetchingRoles: boolean,
    roles?: Role[],
    onRoleSelect?: (role: Role) => void,
    selectedRoleId?: number,
}

type AddEnrolmentProps = {
    mode: "add"
    onUserSelect?: (userId: number | undefined) => void;
    onUserSearch?: (searchTerm: string | undefined) => void;
    fetchingUserSearchResults: boolean;
    userSearchResults?: User[];
}

type EditEnrolmentProps = {
    mode: "edit"
    userDisplayName: string;
}

type StaffEnrolmentManageEnrolmentProps = CommonProps & (AddEnrolmentProps | EditEnrolmentProps);


export default function StaffEnrolmentManageEnrolment(props: StaffEnrolmentManageEnrolmentProps) {
    return <Space direction="vertical" size="middle" className='staff-enrolment-add-user-main'>
        <div className='staff-enrolment-add-user-field-label'>User</div>
        <Space className="staff-enrolment-manage-enrolment-user">
            {props.mode === "add" ?
                <Select placeholder={"Search for staff..."}
                        showSearch={true}
                        showArrow={props.fetchingUserSearchResults}
                        loading={props.fetchingUserSearchResults}
                        options={props.userSearchResults?.map((d) => ({
                            value: d.canvasId,
                            label: `${d.name} (${d.sisUserId})`,
                        }))}
                        filterOption={false}
                        onSearch={props.onUserSearch}
                        onChange={(userId: number) => props.onUserSelect?.(userId)}
                        notFoundContent={null}
                        allowClear={true}
                        onClear={() => props.onUserSelect?.(undefined)}
                        className="staff-enrolment-staff-search-field"
                /> :
                <div><UserOutlined/> {props.userDisplayName}</div>}
        </Space>

        <div className='staff-enrolment-add-user-field-label'>Role</div>
        <Radio.Group onChange={(e) => props.onRoleSelect?.(e.target.value)}
                     {...(props.selectedRoleId ? {value: props.roles?.find(r => r.roleId === props.selectedRoleId)} : {})}>
            <Space direction="vertical">
                {props.fetchingRoles ?
                    <Spin/> :
                    !props.roles || props.roles.length === 0 ?
                        <Empty description="No Roles Found"/> :
                        props.roles.map((r) => <Radio
                            value={r}
                            disabled={!r.userCanEdit}
                            key={`role-${r.role}`}>{r.label}</Radio>)}
            </Space>
        </Radio.Group>

    </Space>;
}