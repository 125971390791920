export const handleClientError = async (response: Response) => {
    if (!response.ok) {

        if(response.status === 401 || response.status === 403) {
            throw Error();
        }

        const errJson = await response.json();

        if (errJson && errJson.message) {
            throw new Error(JSON.stringify(errJson));
        } else {
            throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
        }
    }
}