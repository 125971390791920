import React, {useRef} from "react";
import {EditOutlined, EnterOutlined} from "@ant-design/icons";
import {Button, DatePicker, Spin, Tooltip} from "antd";
import "./editablefield.css";
import dayjs from "dayjs";
import useIsOverflowing from "./utils/useIsOverflowing";

export type EditableFieldValue = string | number | Date | null;

export interface EditableFieldProps {
    value: EditableFieldValue;
    type?: "text" | "number" | "date";
    dateFormat?: string;
    className?: string;
    onContainerClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    editedValue: EditableFieldValue;
    setEditedValue: (editedValue: EditableFieldValue) => void;
    onEditComplete: (value: EditableFieldValue) => Promise<void>;
    isSaving: boolean;
}

const EditableField: React.FC<EditableFieldProps> = ({
                                                         value,
                                                         type = "text",
                                                         dateFormat = "DD/MM/YYYY",
                                                         className,
                                                         onContainerClick,
                                                         isEditing,
                                                         setIsEditing,
                                                         editedValue,
                                                         setEditedValue,
                                                         onEditComplete,
                                                         isSaving,
                                                     }) => {

    const formattedValue = (() => {
        const displayValue = isSaving ? editedValue : value;
        switch (type) {
            case "date":
                return displayValue ? dayjs(displayValue).format(dateFormat) : "";
            default:
                return displayValue?.toString() || "";
        }
    })();

    const handleTextNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue =
            type === "number" ? parseFloat(e.target.value) : e.target.value;
        setEditedValue(newValue);
    };

    const editComplete = async (editedValue: EditableFieldValue) => {
        if (value !== editedValue) {
            await onEditComplete(editedValue);
        }
        setIsEditing(false);
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            await editComplete(editedValue);
        } else if (e.key === "Escape") {
            setEditedValue(value);
            setIsEditing(false);
        }
    };

    const handleDateChange = async (date: dayjs.Dayjs | null) => {
        const newValue = date ? date.toDate() : null;
        setEditedValue(newValue);
        await editComplete(newValue);
    };

    const renderInputField = () => {
        switch (type) {
            case "date":
                return (
                    <DatePicker
                        autoFocus
                        allowClear={false}
                        format={dateFormat}
                        value={editedValue ? dayjs(editedValue) : null}
                        onChange={handleDateChange}
                        onBlur={async () => {
                            await editComplete(editedValue);
                        }}
                        open={true}
                        className="editable-input-date"
                    />
                );
            case "text":
            case "number":
            default:
                return (
                    <div className="input-wrapper">
                        <input
                            autoFocus
                            type={type}
                            value={editedValue ? editedValue.toString() : ""}
                            onChange={handleTextNumberChange}
                            onKeyDown={handleKeyDown}
                            onBlur={async () => {
                                if (isEditing) await editComplete(editedValue);
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className="editable-input"
                        />
                        <EnterOutlined className="enter-icon"/>
                    </div>
                );
        }
    };

    const displayEl = useRef<HTMLDivElement>(null);
    const isOverflowing = useIsOverflowing(displayEl);

    return (
        <div
            className={`editable-field ${className} ${isSaving ? "editable-field-saving" : ""} ${isEditing ? "editable-field-editing" : ""}`}
            onClick={(e) => {
                if (!isEditing && onContainerClick) {
                    e.stopPropagation(); // Stop event propagation
                    onContainerClick(e);
                }
            }}
        >
            <div className={"editable-field-display-components"}>
                <Tooltip title={formattedValue} open={isOverflowing ? undefined : false} overlayStyle={{ maxWidth: "min(600px, 100%)" }}>
                    {/*This is being hidden with CSS so that the element being referenced always exists.*/}
                    <div className="editable-field-display" ref={displayEl}>{formattedValue}</div>
                </Tooltip>
                {!isEditing && isSaving ?
                    <Spin size={"small"}/> :
                    <Button
                        className="editable-field-editbutton"
                        type="text"
                        icon={<EditOutlined className="edit-icon"/>}
                        onClick={(e) => {
                            e.stopPropagation(); // Stop event propagation
                            setIsEditing(true);
                        }}
                    />}
            </div>
            {isEditing ?
                <div className={"editable-field-editing-components"}>
                    {renderInputField()}
                </div> : <></>}
        </div>
    );
};

export default EditableField;