import {User} from '../models'
import {SessionService} from "./SessionService";
import {wsErrorFromResponse} from "../errors";

function decodeResponse(response: Response) {
    if (!response.ok) {
        throw wsErrorFromResponse(response);
    }
    if(response.status === 204) {
        return Promise.resolve();
    }
    return response.json();
}

const usersCache = new Map<number, User>();
export default class UserService {

    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async searchUsers(searchTerm: string): Promise<User[]> {
        const usersResponse:User[] = await fetch(`${this.apiBaseUrl}/api/users?searchTerm=${searchTerm}`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);

        for (let user of usersResponse) {
            usersCache.set(user.canvasId, user);
        }

        return usersResponse;
    }
    async getUserByCanvasId(userId: number): Promise<User> {
        if (usersCache.has(userId)) {
            return usersCache.get(userId)!;
        }
        return fetch(`${this.apiBaseUrl}/api/users/${userId}`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);
    }
}
