import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CourseService from './services/CourseService'
import {SessionContext, SessionService} from './services/SessionService'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {BrowserRouter} from "react-router-dom";
import ReferenceDataService from "./services/ReferenceDataService";
import {LocalDataService} from "./services/LocalDataService";
import AccountService from "./services/AccountService";
import UserService from "./services/UserService";
import CourseAvailabilityService from "./services/CourseAvailabilityService";
import RoleService from "./services/RoleService";
import PublicClientConfigService, {PublicClientConfigContext} from "./services/PublicClientConfigService";
import {Button, Layout, Result, Space, Spin} from "antd";
import AuditLogsService from "./services/AuditLogsService";
import {SessionExpiredError} from "./errors";
import SummaryService from './services/SummaryService';
import {MessagesConfig} from "./models";
import {getMessageOrDefault} from "./utils/useMessages";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const publicClientConfigService = new PublicClientConfigService();


root.render(
    <Layout.Content style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        minWidth: "100vw"
    }}>
        <Spin size={"large"}/>
    </Layout.Content>
);

const handleExpiredSession = async (sessionService: SessionService, error: unknown, messages?: MessagesConfig) => {
    if (error instanceof SessionExpiredError) {
        let hint = await sessionService.getReauthenticationHint();

        let additionalContent : JSX.Element = <></>;
        if (hint) {
            switch (hint.type) {
                case "lti":
                    if(window.top === window.self) {
                        // Window
                        additionalContent = <div dangerouslySetInnerHTML={{__html: getMessageOrDefault(messages, "expiredSessionLti")}} />
                    } else {
                        // Frame
                        window.location.href = hint.reauthenticateUrl;
                        return;
                    }
                    break;
                case "oidc":
                    additionalContent = <Button type="primary" href={hint.reauthenticateUrl}>Login again</Button>
                    break;
                default:
                    throw new Error("Unexpected hint type");
            }
        }

        root.render(<Result
            status="warning"
            title="Your session has expired"
            subTitle={
                <Space direction="vertical">
                    <div dangerouslySetInnerHTML={{__html: getMessageOrDefault(messages, "expiredSessionTitle")}} />
                    {additionalContent}
                </Space>}
        />);
    }
}

publicClientConfigService
    .getPublicClientConfig()
    .then((publicClientConfig) => {
        const sessionService = new SessionService(publicClientConfig.canvasHostDomain);

        sessionService.getSession().then((session => {
            const courseService = new CourseService(sessionService);
            const accountService = new AccountService(sessionService);
            const userService = new UserService(sessionService);
            const referenceDataService = new ReferenceDataService(sessionService);
            const localDataService = new LocalDataService();
            const courseAvailabilityService = new CourseAvailabilityService(sessionService);
            const roleService = new RoleService(sessionService);
            const auditLogsService = new AuditLogsService(sessionService);
            const summaryService = new SummaryService(sessionService);

            const onQueryError = (error: unknown) => handleExpiredSession(sessionService, error, publicClientConfig.messages);
            
            const queryClient = new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnReconnect: false,
                        refetchOnWindowFocus: false,
                        refetchOnMount: false,
                        onError: onQueryError,
                        retry: false,
                        // NOTE: networkMode: "always" is a workaround for a bug in Chrome https://stackoverflow.com/questions/75538301/reactquery-queryfn-passed-to-usequery-is-never-run-happens-only-on-chrome
                        networkMode: "always"
                    },
                    mutations: {
                        onError: onQueryError,
                        networkMode: "always"
                    },
                }
            });

            root.render(
                <React.StrictMode>
                    <BrowserRouter>
                        <QueryClientProvider client={queryClient}>
                            <SessionContext.Provider value={session}>
                                <PublicClientConfigContext.Provider value={publicClientConfig}>
                                    <App courseService={courseService}
                                         accountService={accountService}
                                         localDataService={localDataService}
                                         referenceDataService={referenceDataService}
                                         userService={userService}
                                         courseAvailabilityService={courseAvailabilityService}
                                         roleService={roleService}
                                         auditLogsService={auditLogsService}
                                         summaryService={summaryService}
                                         publicClientConfig={publicClientConfig}/>
                                </PublicClientConfigContext.Provider>
                            </SessionContext.Provider>
                        </QueryClientProvider>
                    </BrowserRouter>
                </React.StrictMode>
            );
        })).catch((e) => {
            handleExpiredSession(sessionService, e, publicClientConfig.messages);
        });
    });
