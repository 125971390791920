import {Button, Modal, Spin} from "antd";
import "./copycontentsearch.css"
import {Course, CoursesPage, Section} from "./models";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import CourseService from "./services/CourseService";
import {useReducer} from "react";
import {AddMirroredSectionCourseSearch} from "./AddMirroredSectionCourseSearch";
import {AddMirroredSectionSectionSelect} from "./AddMirroredSectionSectionSelect";
import {AddMirroredSectionConfirm} from "./AddMirroredSectionConfirm";
import {ArrowLeftOutlined} from "@ant-design/icons";

export interface AddMirroredSectionModalProps {
    courseService: CourseService;
    fetchingCourse: boolean;
    currentCourse?: Course;
    onCancel: () => void;
    onAddMirroredSectionOperationPosted: () => void;
    onCloseAnimationComplete?: () => void;
    open: boolean;
}

interface SetItems {
    name: "SetCourses";
    courses: Course[];
}
interface SetSections {
    name: "SetSections";
    sections: Section[];
}

interface SetSourceCourse {
    name: "SetSourceCourse";
    sourceCourse: Course;
}

interface ClearSourceCourse {
    name: "ClearSourceCourse"
}

interface ConfirmCopy {
    name: "ConfirmCopy"
}

interface SetSearchTerm {
    name: "SetSearchTerm";
    searchTerm: string;
}
interface UpdateSelectedSections {
    name: "UpdateSelectedSections",
    section: Section,
}

interface SetSectionsToMirror {
    name: "SetSectionsToMirror"
}
interface ClearSectionsToMirror {
    name: "ClearSectionsToMirror";
}

export type AddMirroredSectionModalAction =
    | SetItems
    | SetSourceCourse
    | ClearSourceCourse
    | ConfirmCopy
    | SetSearchTerm
    | UpdateSelectedSections
    | SetSections
    | SetSectionsToMirror
    | ClearSectionsToMirror

function addMirroredSectionModalReducer(state: AddMirroredSectionModalState, action: AddMirroredSectionModalAction): AddMirroredSectionModalState {
    switch (action.name) {
        case "SetCourses":
            return {
                ...state,
                courses: action.courses,
            };
        case "SetSections":
            return {
                ...state,
                sections: action.sections,
            };
        case "SetSourceCourse":
            return {
                ...state,
                sourceCourse: action.sourceCourse,
            };
        case "ClearSourceCourse":
            return {...state, selectedSections: [], sourceCourse: undefined}
        case "ConfirmCopy":
            return {
                ...state,
                confirmed: true,
            }
        case "SetSearchTerm":
            return {
                ...state,
                searchTerm: action.searchTerm,
            }
        case "SetSectionsToMirror":
            return {...state, sectionsToMirror: [...state.selectedSections]}
        case "ClearSectionsToMirror":
            return {...state, sectionsToMirror: undefined};
        case "UpdateSelectedSections":
            if (!state.selectedSections?.find(s => s.canvasId === action.section.canvasId)) {
               const selectedSections:Section[] = [...(state.selectedSections ?? [])];
               selectedSections.push(action.section);
               return {...state, selectedSections}
            } else {
                const selectedSections = state.selectedSections?.filter(s => s.canvasId !== action.section.canvasId)
                return {...state, selectedSections};
            }
    }
}

interface AddMirroredSectionModalState {
    courses?: Course[],
    pageCount?: number,
    pageNumber?: number,
    sourceCourse?: Course,
    confirmed: boolean,
    searchTerm?: string,
    sections?: Section[],
    selectedSections: Section[],
    sectionsToMirror?: Section[],
}

interface PostMirrorOperationVars {
    destinationCourseId: number;
    sectionsToMirrorIds: number[];
}

export const AddMirroredSectionModal = ({...props}: AddMirroredSectionModalProps) => {
    const [state, dispatch] = useReducer(addMirroredSectionModalReducer, {confirmed: false, selectedSections: []})

    useQueryClient();


    const { data: courseSearchResults, isFetching: fetchingSearchCourses, isError: fetchingSearchCoursesError} = useQuery<CoursesPage, Error>(["copymodal-searchcourses", state.searchTerm, state.pageNumber],
        () => {
            return props.courseService.getAllCourses(undefined, undefined, undefined, state.searchTerm, undefined, undefined, undefined, true);
        }, {
            enabled: !!state.searchTerm,
            onSuccess: data => dispatch({name: "SetCourses", courses: data.courses.filter(c => props.currentCourse?.canvasId !== c.canvasId)}),
        });


    const { isFetching: fetchingSearchSections, isError: fetchingSearchSectionsError} = useQuery<Section[], Error>(
        ["copymodal-searchcourses", state.searchTerm, state.pageNumber, state.sourceCourse],
        () => {
            if (!state.sourceCourse) {throw Error();}
            return props.courseService.getSections(state.sourceCourse.canvasId);
        }, {
            enabled: !!state.sourceCourse,
            onSuccess: data => dispatch({name: "SetSections", sections: data}),
        });

    const { mutate: postMirrorOperation, isLoading: postMirrorOperationIsLoading, error: postMirrorOperationError} = useMutation(
        ["postmirroroperation", props.currentCourse?.canvasId, state.sectionsToMirror],
        (vars: PostMirrorOperationVars) => props.courseService.postMirrorSectionOperation(vars.destinationCourseId, vars.sectionsToMirrorIds),
        {
            onSuccess: () => {
                props.onAddMirroredSectionOperationPosted();
            },
        }
    );

    let content: JSX.Element;
    let displayFooter = false;
    if (props.fetchingCourse || !props.currentCourse || postMirrorOperationIsLoading) {

        content = <Spin/>
    } else if (!state.sourceCourse) {
        content = <AddMirroredSectionCourseSearch
            courseName={props.currentCourse.name}
            courseId={props.currentCourse.canvasId}
            fetchingItems={fetchingSearchCourses}
            errorFetchingItems={fetchingSearchCoursesError}
            items={courseSearchResults?.courses}
            onSelectSourceCourse={course => dispatch({name: "SetSourceCourse", sourceCourse: course})}
            onSearch={searchTerm => dispatch({name: "SetSearchTerm", searchTerm})}
        />
    } else if (!state.sectionsToMirror) {
        content = <AddMirroredSectionSectionSelect
            sourceCourseId={state.sourceCourse.canvasId}
            fetchingSection={fetchingSearchSections}
            selectedSections={state.selectedSections}
            onSelectSection={section => dispatch({name: "UpdateSelectedSections", section: section})}
            errorFetchingSections={fetchingSearchSectionsError}
            sourceCourseName={state.sourceCourse.name}
            destCourseName={props.currentCourse.name}
            destCourseMirroredSections={props.currentCourse.mirroredSections}
            sections={state.sections}
        />;
        displayFooter = true;
    } else {
        content = <AddMirroredSectionConfirm sectionsToMirror={state.sectionsToMirror}
                                             sourceCourseName={state.sourceCourse.name}
                                             sourceCourseSisId={state.sourceCourse?.sisCourseId ?? "-- No SIS Id set for this course --"}
                                             toName={props.currentCourse.name}
                                             toTerm={props.currentCourse.term?.name}
                                             toSisId={props.currentCourse.sisCourseId}
                                             error={(postMirrorOperationError as Error)?.message}
        />
        displayFooter = true;
    }

    const onOk = async () : Promise<any> => {
        if (!state.selectedSections) {
            throw new Error("No sections selected")
        } else if (!state.sectionsToMirror) {
            dispatch({name: "SetSectionsToMirror"})

        } else if (!props.currentCourse) {
            throw new Error("No destination course loaded")
        } else {
            return postMirrorOperation({
                sectionsToMirrorIds: state.sectionsToMirror.map(s => s.canvasId),
                destinationCourseId: props.currentCourse.canvasId
            })
        }
    }

    const onBack = ()  => {
        if (state.sectionsToMirror) {
            dispatch({name: "ClearSectionsToMirror" });
        } else if (state.sourceCourse) {
            dispatch({name: "ClearSourceCourse"});
        }
    }
    const backButton = state.sourceCourse ? <Button icon={<ArrowLeftOutlined />} type={"text"} onClick={onBack}/> : <></>;
    const titleText = !state.sourceCourse ? "Add Mirrored Section - Select Source Course" :
        (!state.sectionsToMirror ? "Add Mirrored Section - Select Sections" : "Add Mirrored Section - Confirm");

    return <Modal title={<>{backButton} {titleText}</>}
                  destroyOnClose={true}
                  open={props.open}
                  footer={displayFooter ? undefined : null}
                  onCancel={props.onCancel}
                  onOk={onOk}
                  okButtonProps={{disabled:!!(state.selectedSections.length === 0)}}
                  okText={state.selectedSections.length > 1 ? "Add Sections" : "Add Section"}
                  afterClose={props.onCloseAnimationComplete}>
        {content}
    </Modal>
}

