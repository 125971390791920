import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function flatten(obj: any) {
    const result: any = {};
    const recurse = (cur: any, prop: string) => {
        if (Object(cur) !== cur || cur instanceof Date) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            for(let i= 0; i<cur.length; i++) {
                recurse(cur[i], prop + "[" + i + "]");
            }
            if (cur.length === 0) {
                result[prop] = [];
            }
        } else {
            let isEmpty = true;
            for (let p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop+"."+p : p);
            }
            if (isEmpty && prop) {
                result[prop] = {};
            }
        }
    }
    recurse(obj, "");
    return result;
}

export function exportToXLSX(csvData: unknown[], filename: String) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const flatCsvData = csvData.map((obj: any) => flatten(obj));
    const ws = XLSX.utils.json_to_sheet(flatCsvData, {
        dateNF: 'd/mm/yyyy hh:mm:ss',
    });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, filename + fileExtension);
}

export function exportToCSV(csvData: unknown[], filename: String) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';
    const flatCsvData = csvData.map((obj: any) => flatten(obj));
    const ws = XLSX.utils.json_to_sheet(flatCsvData, {
        dateNF: 'd/mm/yyyy hh:mm:ss',
    });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, filename + fileExtension);
}