import React, {useCallback} from 'react';
import {notification, Progress} from 'antd';
import {SectionUploadJob, useSectionUploads} from "../SectionUploadsContext";
import {useBeforeUnload} from "react-router-dom";

type SectionUploadNotificationsProps = {
    children?: React.ReactNode;
};

const SectionUploadNotifications: React.FC<SectionUploadNotificationsProps> = ({ children }) => {
    const [notificationInstance, contextHolder] = notification.useNotification({
        placement: 'bottomRight',
        stack: {
            threshold: 3,
        }
    });
    const { uploadJobs, removeUploadJob } = useSectionUploads();
    
    const getNotificationFunc = useCallback((status: SectionUploadJob["status"]) => {
        switch (status) {
            case "uploading":
                return notificationInstance.info;
            case "complete":
                return notificationInstance.success;
            case "error":
                return notificationInstance.error;
        }
    }, [notificationInstance.info, notificationInstance.success, notificationInstance.error]);
    
    uploadJobs?.forEach((job) => {
        const showNotification = getNotificationFunc(job.status);
        console.log("showNotifificationFn", showNotification);
        showNotification({
            key: job.sectionId.toString(),
            ...(job.status === "uploading" ? { closeIcon: false } : {}), // use default close icon if not uploading
            message: job.sectionName,
            description: <Progress type="line" percent={job.progress} strokeColor={job.status === "error" ? "#ff4d4f" : ""} />,
            duration: 0, //
            onClose: () => removeUploadJob(job.sectionId),
        })
    });
    
    const isUploading = uploadJobs.some(job => job.status === 'uploading');
    useBeforeUnload(
        useCallback(
            (event) => {
                if(isUploading) {
                    event.preventDefault();
                    event.returnValue = "";
                }
            },
            [isUploading]
        ),
        { capture: true }
    );
    
    return (
        <>
            {contextHolder}
            {children}
       </>
    );
};

export default SectionUploadNotifications;
