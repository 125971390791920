export function getMiddleWareErrorObj(err: any): object {

    // Need to determine whether the error message being presented here, is indeed a JSON formatted one.  It should be, but this is a just in case.
    try {
        const jsonError: any = JSON.parse(err.message);
        //This is a convenience property for ui components, so that this condition doesn't need to be added to each client component that uses this function.
        const errorDesc: string = jsonError.errorId && jsonError.dateTime ? jsonError.errorId + '|' + jsonError.dateTime : '';

        return {
            "errorMessage": jsonError.message,
            "errorId": jsonError.errorId,
            "errorDateTime": jsonError.dateTime,
            "errorDesc": errorDesc,
        };
    } catch (e) {
        console.log(`getMiddleWareErrorObj error ${e}`)
    }

    return {
        "errorMessage": err.message,
        "errorId": '',
        "errorDateTime": '',
        "desc": '',
    };
}