import {Alert, Input, List, Space, Spin, Tag} from "antd";
import "./copycontentsearch.css"
import {Course} from "./models";
import {RightOutlined} from "@ant-design/icons";
import {mapCourseStateToColourCode, mapCourseStateToLabel} from "./utils/CourseSetupState";

export interface AddMirroredSectionCourseSearchProps {
    courseName: string,
    courseId: number,
    fetchingItems: boolean,
    errorFetchingItems: boolean,
    items?: Course[],
    onSearch?: (value: string) => void
    onSelectSourceCourse: (course: Course) => void;
}

export const AddMirroredSectionCourseSearch = ({...props}: AddMirroredSectionCourseSearchProps) => {
    let listContent;
    if(props.errorFetchingItems) {
        listContent = <Alert type="error" message="Failed to load courses." showIcon/>;
    } else if(props.fetchingItems) {
        listContent = <Spin/>;
    } else {
        listContent = <List
            size="large"
            dataSource={props.items?.filter(c => c.canvasId !== props.courseId)}
            renderItem={item => (
                <List.Item onClick={() => props.onSelectSourceCourse(item)}>
                    <List.Item.Meta title={item.name}
                                    description={
                                        <>
                                            <span>{item.term?.name}</span>
                                            <span>{item.sisCourseId}</span>
                                            <Tag color={mapCourseStateToColourCode(item.setupState)}>
                                                {mapCourseStateToLabel(item.setupState)}
                                            </Tag>
                                        </>
                                    }/>
                    <RightOutlined/>
                </List.Item>
            )}
        />
    }

    return <Space direction="vertical" className="copy-content-search">
        <div className="copy-content-search-heading">To {props.courseName}</div>
        <div className="copy-content-search-heading">From Course &#8230;</div>
        <Input.Search
            placeholder="Search for course to copy sections from..."
            allowClear
            enterButton
            onSearch={props.onSearch}
        />
        <div className="copy-content-search-list">
            {listContent}
        </div>
    </Space>
}