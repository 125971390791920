import {CourseCard} from "./CourseCard";
import {Course} from "./models";
import {Alert, Empty, Spin} from "antd";
import CourseService from "./services/CourseService";
import InfiniteScroll from "react-infinite-scroll-component";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {useCoursesListDispatch} from "./CoursesContext";


type CourseListProps = {
    courseService: CourseService;
    courses?: Course[];
    error?: Error | null;
    fetchingCourses: boolean;

    loadMoreCourses: () => void;
    hasMoreCourses: boolean;

    onInitiateCopyContent?: (canvasCourseId: number) => void;
    onSkipCopy?: (canvasCourseId: number) => void;
    onInitiateCourseRelease?: (canvasCourseId: number) => void;
    onInitiateMakeAvailable?: (canvasCourseId: number) => void;
    onInitiateManageUsers?: (canvasCourseId: number) => void;
    onInitiateAddMirroredSection?: (canvasCourseId: number) => void;
    onInitiateAddStaticSection?: (canvasCourseId: number) => void;
    onInitiateUpdateStaticSection?: (canvasCourseId: number, canvasSectionId: number) => void;
    onInitiateAddStudentToStaticSection?: (canvasCourseId: number, canvasSectionId: number) => void;
    onCourseUpdated?: (course: Course) => void;

}

export default function CourseList(props: CourseListProps) {

    const [searchParams] = useSearchParams();
    const expandedCourseParam = searchParams.get("expandedCourse");

    const courseListDispatch = useCoursesListDispatch();

    useEffect(() => {
        if (!expandedCourseParam || Number.isNaN(expandedCourseParam)) {
            return;
        }
        const expandedCourseId = Number.parseInt(expandedCourseParam)
        courseListDispatch({name: "SetExpandedCourseId", courseId: expandedCourseId});
    }, [searchParams, courseListDispatch, expandedCourseParam])

    let listContent;
    if (!props.courses || props.courses.length === 0) {
        listContent = <Empty/>;
    } else {
        listContent = <>
            <div>
                <InfiniteScroll
                    dataLength={props.courses.length}
                    next={props.loadMoreCourses}
                    hasMore={props.hasMoreCourses}
                    loader={<Spin />}
                >
                {props.courses.map((course: Course) => (
                    <CourseCard expanded={expandedCourseParam === '' + course.canvasId}
                                canToggleExpanded={true}
                                courseService={props.courseService}
                                canvasCourseId={course.canvasId}
                                courseCode={course.courseCode}
                                title={course.name}
                                expiresAt={course.expiresAt}
                                concluded={course.concluded}
                                accountName={course.account?.name}
                                state={course.setupState}
                                sisId={course.sisCourseId}
                                teachingPeriod={course.term?.name}
                                teachers={course.teachers}
                                canvasCourseUrl={course.canvasCourseUrl}
                                copyInProgress={course.copyInProgress}
                                key={course.canvasId}
                                onInitiateCopyContent={props.onInitiateCopyContent}
                                onInitiateSkipCopy={props.onSkipCopy}
                                onInitiateCourseRelease={props.onInitiateCourseRelease}
                                onInitiateMakeAvailable={props.onInitiateMakeAvailable}
                                onInitiateManageUsers={props.onInitiateManageUsers}
                                onInitiateAddMirroredSection={props.onInitiateAddMirroredSection}
                                onInitiateAddStaticSection={props.onInitiateAddStaticSection}
                                onInitiateUpdateStaticSection={props.onInitiateUpdateStaticSection}
                                onInitiateAddStudentToStaticSection={props.onInitiateAddStudentToStaticSection}
                                onCourseUpdated={props.onCourseUpdated}
                                embeddedInCanvas={false}
                                commentCount={course.commentCount}
                    />
                ))}
                </InfiniteScroll>
            </div>
        </>;
    }
    return <div>

        {props.fetchingCourses ? (
            <Spin />
        ) : props.error ? (
            <Alert
                message="Error"
                description={props.error?.message}
                type="error"
                showIcon
            />
        ) : (listContent)
        }
    </div>
}