import {Course, CourseSetupState} from "./models";
import {Alert} from "antd";
import './coursestatemessage.css'
import Message from "./Message";

const CourseStateMessage = (props: { course?: Pick<Course, "setupState"|"concluded"> }) => {
    if(props.course?.concluded) {
        return <Alert
            description={<Message messageKey={"courseStateDescriptionCompleted"} />}
            type="info"
            showIcon
            className="coursestatusmessage"
        />;
    }
    switch (props.course?.setupState) {
        case CourseSetupState.AWAITING_COPY:
            return <Alert
                description={<Message messageKey={"courseStateDescriptionAwaitingCopy"} />}
                type="info"
                showIcon
                className="coursestatusmessage"
            />;
        case CourseSetupState.AWAITING_REVIEW:
            return <Alert
                description={<Message messageKey={"courseStateDescriptionAwaitingReview"} />}
                type="info"
                showIcon
                className="coursestatusmessage"
            />;
        case CourseSetupState.RELEASED:
            return <Alert
                description={<Message messageKey={"courseStateDescriptionReleased"} />}
                type="info"
                showIcon
                className="coursestatusmessage"
            />;
        case CourseSetupState.AVAILABLE:
            return <Alert
                description={<Message messageKey={"courseStateDescriptionAvailable"} />}
                type="info"
                showIcon
                className="coursestatusmessage"
            />;
        case CourseSetupState.COMPLETED:
            return <Alert
                description={<Message messageKey={"courseStateDescriptionCompleted"} />}
                type="info"
                showIcon
                className="coursestatusmessage"
            />;

        case CourseSetupState.DELETED:
        case undefined:
            return <></>
    }
}

export default CourseStateMessage;