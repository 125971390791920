import './courselistfilters.css';
import {Checkbox, Col, Input, Row, Select, Tooltip} from "antd";
import {Account, CourseSetupState, Term, User} from "./models";
import { LabeledValue } from 'antd/lib/select';
import { TreeSelect } from 'antd';
import {mapCourseStateToLabel} from "./utils/CourseSetupState";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {useCallback} from "react";
import {InfoCircleOutlined} from "@ant-design/icons";

const { Option } = Select;

export interface CourseListFiltersProps {
    hideYearAndTermFilters?: boolean;
    years?: string[];
    selectedYear?: string;
    loadingYears?: boolean;
    terms?: Term[];
    selectedTerm?:Term;
    loadingTerms?: boolean;
    selectedAccount?: Account;
    accounts?: Account[];
    loadingAccounts?: boolean;
    selectedState?: CourseSetupState;
    onYearChange?: (year: string | undefined) => void;
    onTermChange?: (termId: number | undefined) => void;
    onAccountChange?: (accountid: number|undefined) => void;
    searchText?: string;
    onSearch?: (searchTerm: string) => void;
    onSearchTextChange?: (searchTerm: string) => void;
    userSearchResults?: User[];
    onStaffSearch?: (searchTerm: string|undefined) => void;
    onStaffChange?: (sisUserId: number|undefined) => void;
    onStateChange?: (status: CourseSetupState|undefined) => void;
    selectedStaffId?: number | undefined;
    loadingStaffSearchResults?: boolean;
    siteTypes?: string[];
    selectedSiteType?: string;  
    onSiteTypeChange?: (siteType: string | undefined) => void;
    onIncludeDeletedSites: (include: boolean) => void;
    onIncludeConcludedSites: (include: boolean) => void;
    includeDeletedSites?: boolean;
    includeConcludedSites?: boolean;
}


export interface FilterSettings {
    selectedYear?: string,
    selectedTerm?: Term,
}




export default function CourseListFilters(props: CourseListFiltersProps) {

    let selectedYear = props.selectedYear ?? new Date().getFullYear().toString();
    // if (props.years && props.years.length > 0 && !props.selectedYear) {
    //     selectedYear = props.years[0];
    // }

    let selectedTerm:LabeledValue | undefined = !props.selectedTerm ? undefined : {value: props.selectedTerm.canvasId, label: props.selectedTerm.name};
    // if (props.terms && props.terms.length > 0 && !props.selectedTerm) {
    //     selectedTerm = {value:props.terms[0].canvasId, label: props.terms[0].name};
    // }

    let selectedAccount:LabeledValue | undefined = !props.selectedAccount ? undefined : {value: props.selectedAccount.canvasId, label: props.selectedAccount.name};

    // const accountTree: DefaultOptionType[] = useMemo(() => {return generateAccountTree(props.accounts)}, [props.accounts])

    let selectedSiteType = props.selectedSiteType;
    const siteTypesVisible = props.siteTypes && props.siteTypes.length > 1;

    const yearAndTermFilterRows = <>
        <Row className={'courselistfilters-yearselect'}>
            <Select placeholder={"Select Year"}
                    popupMatchSelectWidth={false}
                    value={selectedYear}
                    allowClear={true}
                    onClear={() => {if (props.onYearChange) {props.onYearChange(undefined)}}}
                    onChange={(value:string) => {if (props.onYearChange) { props.onYearChange(value) } }}
                    bordered={false}
                    loading={props.loadingYears}>
                {[<Option key={0} value={"allyears"}>{"All Years"}</Option>, ...(props.years ? props.years.map((year:string) => {return <Option key={year} value={year}>{year}</Option>}) : [])]}
            </Select>
        </Row>
        <Row className={'courselistfilters-termselect'}>
            <Select placeholder={"Select Teaching Term"}
                    showSearch={true}
                    popupMatchSelectWidth={false}
                    optionFilterProp={'label'}
                    value={selectedTerm}
                    labelInValue
                    allowClear={true}
                    onClear={() => {if (props.onTermChange) {props.onTermChange(undefined)}}}
                    onChange={(value: LabeledValue ) => {
                        if (props.onTermChange && value && value.value) { props.onTermChange(parseInt(value.value as string)) } }}
                    bordered={false}
                    loading={props.loadingTerms}
                    options={(props.terms || []).map((d) => ({
                        value: d.canvasId.toString(),
                        label: d.name,
                    }))}
            />
        </Row>
    </>;
    
    const handleCheckboxChange = useCallback((e: CheckboxChangeEvent) => {
        const { value, checked } = e.target;
        switch (value) {
            case "includeDeleted":
                    props.onIncludeDeletedSites(checked);
                break;
            case "includeConcluded":
                    props.onIncludeConcludedSites(checked);
                break;
        }
    }, [props]);

    return <div className={'courselistfilters-container'}>

        {props.hideYearAndTermFilters ? <></>: yearAndTermFilterRows }

        <Row className={'courselistfilters-accountselect'}>
            <TreeSelect
                showSearch={true}
                allowClear
                onClear={() => {if (props.onAccountChange) {props.onAccountChange(undefined)}}}
                placeholder={'Account'}
                value={selectedAccount}
                treeNodeFilterProp={'name'}
                treeDefaultExpandAll
                onChange={(value: LabeledValue) => {
                    if (props.onAccountChange && value && value.value) { props.onAccountChange(parseInt(value.value as string)) } }}
                treeData={props.accounts}
                treeDataSimpleMode={{
                    id: 'canvasId',
                    pId: 'parentAccountId',
                }}
                fieldNames={{
                    label: 'name',
                    value: 'canvasId',
                }}
                labelInValue>
            </TreeSelect>
        </Row>

        <Row className={'courselistfilters-otherfilters'}>
            <Col span={props.siteTypes && siteTypesVisible ? 8 : 12}>
                <Select placeholder={"Staff"}
                        value={ !props.userSearchResults ? undefined : props.selectedStaffId }
                        showSearch={true}
                        showArrow={props.loadingStaffSearchResults}
                        options={(props.userSearchResults || []).map((d) => ({
                            label: `${d.name} (${d.sisUserId})`,
                            value: d.canvasId,
                        }))}
                        filterOption={false}
                        onSearch={props.onStaffSearch}
                        onChange={props.onStaffChange}
                        notFoundContent={null}
                        loading={props.loadingStaffSearchResults}
                        allowClear={true}
                        onClear={() => {if (props.onStaffChange) {props.onStaffChange(undefined)}}}
                    >

                </Select>
            </Col>
            <Col span={props.siteTypes && siteTypesVisible ? 8 : 12}>
                <Select placeholder="Status" onChange={props.onStateChange} value={props.selectedState} allowClear>
                    <Option value={CourseSetupState.AWAITING_COPY}>{mapCourseStateToLabel(CourseSetupState.AWAITING_COPY)}</Option>
                    <Option value={CourseSetupState.AWAITING_REVIEW}>{mapCourseStateToLabel(CourseSetupState.AWAITING_REVIEW)}</Option>
                    <Option value={CourseSetupState.RELEASED}>{mapCourseStateToLabel(CourseSetupState.RELEASED)}</Option>
                    <Option value={CourseSetupState.AVAILABLE}>{mapCourseStateToLabel(CourseSetupState.AVAILABLE)}</Option>
                    <Option value={CourseSetupState.COMPLETED}>{mapCourseStateToLabel(CourseSetupState.COMPLETED)} &nbsp;
                        <Tooltip title={"Courses in which someone has clicked \"End this Course\"."} >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Option>
                    {props.includeDeletedSites &&<Option value={CourseSetupState.DELETED}>{mapCourseStateToLabel(CourseSetupState.DELETED)}</Option>}
                </Select>
            </Col>
            {props.siteTypes && siteTypesVisible && (
            <Col span={8} className={'courselistfilters-sitetypeselect'}>
                <Select placeholder={"Site Type"}
                    value={selectedSiteType}
                    allowClear={true}
                    onClear={() => {if (props.onSiteTypeChange) {props.onSiteTypeChange(undefined)}}}
                    onChange={(value:string) => {if (props.onSiteTypeChange) { props.onSiteTypeChange(value) } }}
                >
                {props.siteTypes ? (props.siteTypes.map((siteType:string) => {return <Option key={siteType} value={siteType}>{siteType}</Option>} )) : (<></>) }
                </Select>                
            </Col>
             )}
        </Row>
        <Row>
            <Input.Search value={props.searchText}
                          onChange={(e) => {
                              if (props.onSearchTextChange) {
                                  props.onSearchTextChange(e.target.value)
                              }
                          }}
                          onSearch={props.onSearch}
                          placeholder="Search..." allowClear />
        </Row>
        <Row>
            <Col span={12}>
                <Checkbox
                    checked={props.includeDeletedSites}
                    value={"includeDeleted"}
                    onChange={e => handleCheckboxChange(e)}
                >Include deleted sites</Checkbox>
            </Col>
            <Col span={12}>
                <Checkbox
                    checked={props.includeConcludedSites}
                    value={"includeConcluded"}
                    onChange={e => handleCheckboxChange(e)}
                >Include concluded sites &nbsp;
                    <Tooltip title={"Courses with end dates (either course or term) that have passed, or have been ended in the course settings area."} >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Checkbox>
            </Col>
            
        </Row>
    </div>;

}
