import CourseService from "./services/CourseService";
import {useReducer} from "react";
import {
    Button,
    Select,
    Space,
    Input,
    DatePicker,
    DatePickerProps,
    SelectProps,
    Alert,
    notification,
    Typography,
    Card
} from 'antd';
import {ConfigProvider} from "antd/lib";
import "./sitemanagement.css";
import {isAfter} from "date-fns";
import dayjs from "dayjs";
import locale from 'antd/locale/en_GB';
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {usePublicClientConfig} from "./services/PublicClientConfigService";
import CourseAvailabilityService from "./services/CourseAvailabilityService";
import UserService from "./services/UserService";
import RoleService from "./services/RoleService";
import {getMiddleWareErrorObj} from "./utils/errorUtils";
import {useNavigate} from "react-router-dom";
import {Course} from "./models";

type SiteManagementProps = {
    courseAvailabilityService: CourseAvailabilityService
    courseService: CourseService
    userService: UserService
    roleService: RoleService
}

interface SiteManagementState {
    siteType?: string;
    siteName?: string;
    siteEndDate?: Date;
}

export type SiteManagementStateAction =
    | { name: "SetSiteType"; siteType?: string }
    | { name: "SetSiteName"; siteName?: string }
    | { name: "ResetForm" }
    | { name: "SetEndDate"; siteEndDate?: Date };


function StateReducer(state: SiteManagementState, action: SiteManagementStateAction): SiteManagementState {
    switch (action.name) {
        case "SetSiteType":
            return {
                ...state,
                siteType: action.siteType,
            };
        case "SetSiteName":
            return {
                ...state,
                siteName: action.siteName,
            };
        case "SetEndDate":
            return {
                ...state,
                siteEndDate: action.siteEndDate,
            };
        case "ResetForm":
            return {
                // Keep the site type and date, as these might be useful defaults for the next course.
                siteType: state.siteType,
                siteEndDate: state.siteEndDate,
            };
    }
}

interface PostCourseSetupStateVars {
    siteType: string,
    name: string,
    expiresAt: Date,
}

export function SiteManagement(props: SiteManagementProps) {

    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const dateFormat = 'DD/MM/YYYY';
    const siteEndDate: Date = new Date();
    siteEndDate.setMonth(siteEndDate.getMonth() + 12);
    siteEndDate.setHours(0, 0, 0, 0);
    const [validator, contextHolder] = notification.useNotification();

    const initialState: SiteManagementState = {siteEndDate};
    const [state, dispatch] = useReducer(StateReducer, initialState);

    const datePickerOnChange: DatePickerProps['onChange'] = (date) => {
        dispatch({name: "SetEndDate", siteEndDate: date?.toDate()});
    };

    const siteTypeOnChange = (value: string) => {
        dispatch({name: "SetSiteType", siteType: value});
    };

    const siteNameOnChange = (e: any) => {
        dispatch({name: "SetSiteName", siteName: e.target.value});
    };

    const onSubmit = () => {
        if (!state.siteName || state.siteName.trim() === '') {
            validator.open({
                key: 'invalidSiteName',
                placement: "topLeft",
                message: 'Site name is not valid',
                description: 'Please provide a valid site name.',
            });
            return;
        }
        if (!state.siteType || state.siteType.trim() === '') {
            validator.open({
                key: 'invalidSiteType',
                placement: "topLeft",
                message: 'Site type is not valid',
                description: 'Please provide a valid site type.',
            });
            return;
        }
        if (!state.siteEndDate || !isAfter(state.siteEndDate, new Date())) {
            validator.open({
                key: 'invalidEndDate',
                placement: "topLeft",
                message: 'Expiry date is not valid',
                description: 'Please provide a valid expiry date.',
            });
            return;
        }

        if (state.siteName && state.siteName.length > 0) {
            addCourse({
                siteType: state.siteType,
                name: state.siteName,
                expiresAt: state.siteEndDate,
            });
        } else {

        }
    };

    const config = usePublicClientConfig();

    const options: SelectProps['options'] = config.userCreatableSiteTypes
        .sort((l, r) => r.sortOrder - l.sortOrder)
        .map((siteType: { key: string, label: string, sortOrder: number }) => {
            return {
                value: siteType.key,
                label: `${siteType.label} (${siteType.key})`,
            }
        });

    const {
        mutate: addCourse,
        isLoading: addingCourse,
        isError: isAddCourseError,
        error: err,
    } = useMutation<Course, Error, PostCourseSetupStateVars>(
        ["addCourse"],
        (vars: PostCourseSetupStateVars) => props.courseService.postCourse(vars.siteType, vars.name, vars.expiresAt.toISOString()),
        {
           onSuccess: (course => {
                queryClient.setQueryData(["course", course.canvasId], course);
                navigate(`/course/${course.canvasId}`, { state: { showCreationSuccessMessage: true }});
            }),
        }
    );

    let content: JSX.Element;
    if (isAddCourseError) {
        const clientError: any = getMiddleWareErrorObj(err);
        content = <Space direction="vertical" className="site-management-alert-container">
            <Alert
                message={clientError.errorMessage}
                description={clientError.errorDesc}
                type="error"
                showIcon
            /></Space>
    } else {
        let dateValue: dayjs.Dayjs|undefined = dayjs(state.siteEndDate);
        if(!dateValue.isValid()) {
            dateValue = undefined;
        }
        content = <Card className="site-management-card" actions={[
            <Button type="primary" className="site-management-btn" onClick={onSubmit} loading={addingCourse}>Create
                Site</Button>
        ]}>
            <Space direction="vertical"></Space>
            <div className="site-management-label">Site Type</div>
            <Select onChange={siteTypeOnChange} options={options} value={state.siteType} />

            <div className="site-management-label">Site Name</div>
            <Input className="site-management-site-name"
                   onChange={siteNameOnChange}
                   value={state.siteName}
                   placeholder="Site Name" maxLength={255}/>

            <div className="site-management-label">Expiry Date</div>

            <ConfigProvider locale={locale}>
            <DatePicker
                format={dateFormat}
                defaultValue={dateValue}
                className="site-management-date-picker"
                value={dateValue}
                allowClear={false}
                onChange={datePickerOnChange}
            />
            </ConfigProvider>
        </Card>
    }

    return <>
        <Typography.Title>Add New Site</Typography.Title>
        {content}
        {contextHolder}
    </>
}