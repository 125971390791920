import {InboxOutlined} from "@ant-design/icons";
import {Button, Form, Input, message, Modal, Progress, Spin, Upload, UploadFile, UploadProps} from "antd";
import {Course, Section, StaticStudentSectionEnrol} from "./models";
import {read, utils} from "xlsx";
import {useReducer} from "react";
import CourseService from "./services/CourseService";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import moment from "moment";
import Message from "./Message";
import {useSectionUploads} from "./SectionUploadsContext";

const { Dragger } = Upload;

export interface AddStaticStudentSectionModelProps {
    courseService: CourseService;
    open: boolean;
    fetchingCourse: boolean;
    currentCourse?: Course;
    onCancel: () => void;
    onAddStaticStudentSectionOperationPosted: () => void;
    onCloseAnimationComplete?: () => void;
}

export interface AddStaticStudentSectionModelState {
    sectionName: string;
    students: StaticStudentSectionEnrol[];
    processedStudents: number;
    isHelpModalOpen: boolean;
    fileList: UploadFile[];
    results?: EnrollmentResults;
}

interface EnrollmentResults {
    enrollmentsAdded: StaticStudentSectionEnrol[];
    enrollmentErrors: StaticStudentSectionEnrol[];
}

interface SetFileList {
    name: "SetFileList";
    fileList: UploadFile[];
}

interface SetSectionName {
    name: "SetSectionName";
    sectionName: string;
}

interface SetStudents {
    name: "SetStudents";
    students: StaticStudentSectionEnrol[];
}

interface IncrementProcessedStudents {
    name: "IncrementProcessedStudents";
}

interface OpenHelpModal {
    name: "OpenHelpModal";
}

interface CloseHelpModal {
    name: "CloseHelpModal";
}

interface SetResults {
    name: "SetResults";
    results: EnrollmentResults;
}

export type AddStaticStudentSectionModalAction =
    | SetFileList
    | SetSectionName
    | SetStudents
    | IncrementProcessedStudents
    | OpenHelpModal
    | CloseHelpModal
    | SetResults;

function AddStaticStudentSectionStateReducer(state: AddStaticStudentSectionModelState, action: AddStaticStudentSectionModalAction): AddStaticStudentSectionModelState {
    switch (action.name) {
        case "SetSectionName":
            return {
                ...state,
                sectionName: action.sectionName
            }
        case "SetStudents":
            return {
                ...state,
                students: action.students
            }
        case "SetFileList":
            return {
                ...state,
                fileList: action.fileList
            }
        case "IncrementProcessedStudents":
            return {
                ...state,
                processedStudents: state.processedStudents + 1
            }
        case "SetResults":
            return {
                ...state,
                results: action.results
            }
        case "OpenHelpModal":
            return {
                ...state,
                isHelpModalOpen: true
            }
        case "CloseHelpModal":
            return {
                ...state,
                isHelpModalOpen: false
            }
        default:
            return state;
    }
}

interface CreateEnrollmentParams {
    sectionId: number;
    studentId: string;
}

type F = () => Promise<void>;

async function promisePool(functions: F[], n: number): Promise<any> {
    async function evaluateNext() {
        const fn = functions.shift();
        if (!fn){
            return;
        }
        await fn();
        await evaluateNext();
    }
    const nPromises = Array(n).fill(0).map(evaluateNext);
    await Promise.all(nPromises);
};

export const AddStaticStudentSectionModal = (props: AddStaticStudentSectionModelProps) => {
    
    const { addUploadJob, updateUploadJob } = useSectionUploads();
    
    const queryClient = useQueryClient();

    const [state, dispatch] = useReducer(AddStaticStudentSectionStateReducer, { fileList: [], sectionName: "", students: [], processedStudents: 0, isHelpModalOpen: false });
    
    const {
        data: section,
        mutateAsync: postStaticStudentSection,
        isLoading: postStaticStudentSectionLoading
    } = useMutation(
        () => {
            return props.courseService.postStaticStudentSection(props.currentCourse!.canvasId, state.sectionName);
        }, {
            onSuccess: async (newSection) => {
                queryClient.setQueryData(["sections", props.currentCourse!.canvasId], (oldSections: Section[]|undefined) => {
                    return oldSections ? [...oldSections, newSection] : [newSection];
                });
            }
        });

    const { mutateAsync: postSectionEnrollment } = useMutation(
    ({ sectionId, studentId }: CreateEnrollmentParams) => {
        return props.courseService.postSectionEnrollment(sectionId, studentId);
    }, {});

    const fileList = state.fileList;
    const fileUploadProps: UploadProps = {
        name: 'file',
        accept: '.csv',
        multiple: false,
        maxCount: 1,
        async beforeUpload(file) {
            const fileArray = await file.arrayBuffer();
            const wb = read(fileArray);
            const data = utils.sheet_to_json<StaticStudentSectionEnrol>(wb.Sheets[wb.SheetNames[0]]);
            const validFile = data.length > 0 && data.reduce((curr, student) => {
                if (!student.UserSisId) {
                    return false;
                }
                return curr;
            }, true)
            if (!validFile) {
                message.error("Invalid file format");
                return false;
            }
            dispatch({ name: "SetStudents", students: data });
            dispatch({ name: "SetFileList", fileList: [file] });
            return false;
        },
        onRemove(file) {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            dispatch({ name: "SetFileList", fileList: newFileList });
            dispatch({ name: "SetStudents", students: [] });
        },
        fileList
      };

    const onOk = async () : Promise<void> => {
        if (!state.sectionName) {
            throw new Error("No section name provided");
        } else if (!props.currentCourse) {
            throw new Error("No course loaded")
        } else if (!state.fileList || state.fileList.length === 0) {
            try {
                await postStaticStudentSection();

                const results: EnrollmentResults = {
                    enrollmentsAdded: [],
                    enrollmentErrors: []
                };

                dispatch({ name: "SetResults", results });

            } catch (e) {
                console.error(e);
                message.error("Error adding static student section");
                props.onCancel();
            }
        } else {
            try {
                const section = await postStaticStudentSection();
                
                addUploadJob({
                    sectionId: section.canvasId,
                    sectionName: section.name,
                    canvasCourseId: props.currentCourse.canvasId,
                    status: "uploading",
                    progress: 0,
                });

                const results: EnrollmentResults = {
                    enrollmentsAdded: [],
                    enrollmentErrors: []
                };

                await promisePool(state.students.map((student) => () => new Promise(async (resolve) => {
                    try {
                        await postSectionEnrollment({ sectionId: section.canvasId, studentId: student.UserSisId});
                        results.enrollmentsAdded.push(student);
                        updateUploadJob(state.sectionName, "uploading", Math.round((results.enrollmentsAdded.length / state.students.length) * 100));
                    } catch (e) {
                        console.error(e);
                        message.error("Error adding student to section");
                        results.enrollmentErrors.push(student);
                    } finally {
                        dispatch({ name: "IncrementProcessedStudents" });
                        resolve();
                    }
                })), 5);

                dispatch({ name: "SetResults", results });
                updateUploadJob(state.sectionName, "complete", 100);

            } catch (e) {
                console.error(e);
                message.error("Error adding static student section");
                updateUploadJob(state.sectionName, "error", Math.round((state.processedStudents / state.students.length) * 100));
                props.onCancel();
            }
        }
    }

    let content, okButtonText, okButtonFcn, footer;

    if (!props.currentCourse || props.fetchingCourse) {
        content = <Spin/>;
        footer = null;
    } else if (props.currentCourse.endAt && moment(props.currentCourse.endAt).isBefore(moment())) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Course {props.currentCourse.name} has ended.</div>
            <div>Course end date: {moment(props.currentCourse.endAt).format("MM/DD/YYYY")}</div>
        </div>
        footer = null;
    } else if (postStaticStudentSectionLoading) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Creating section {state.sectionName} in course {props.currentCourse.name}.</div>
            <Spin/>
        </div>
        footer = null;
    } else if (section && !state.results) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Adding students to section {state.sectionName} in course {props.currentCourse.name}.</div>
            <Progress type="line" percent={Math.round((state.processedStudents / state.students.length) * 100)} />
        </div>
        footer = null;
    } else if (state.results) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Section, {state.sectionName}, created in course, {props.currentCourse.name}.</div>
            <div style={{marginBottom: "1em"}}>Added {state.results.enrollmentsAdded.length} enrollments</div>
            <div style={{marginBottom: "1em"}}>Encountered {state.results.enrollmentErrors.length} errors</div>
            {state.results.enrollmentErrors.length > 0 && <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "14px"}}>Errors</div>
                <ul>
                    {state.results.enrollmentErrors.map((enrollment) => <li>{enrollment.UserSisId}</li>)}
                </ul>
            </div>}
        </div>
        footer = [<Button onClick={props.onAddStaticStudentSectionOperationPosted}>Close</Button>];
    } else {
        content = <Form
            layout={"vertical"}>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>{props.currentCourse.name}</div>
            <Form.Item name={"name"} label={"Section Name"}>
                <Input onChange={(e) => dispatch({name: "SetSectionName", sectionName: e.target.value})}/>
            </Form.Item>
            <Form.Item name={"student-list"} label={"Student List File"}>
                <Dragger {...fileUploadProps} >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Files must be in CSV format.
                    </p>
                </Dragger>
            </Form.Item>
            <Form.Item name={"file-help"}>
            <div style={{fontStyle: "italic"}}>For an example of the accepted file format, please <Button type="link" onClick={() => dispatch({name: "OpenHelpModal"})} style={{padding: 0, fontStyle: 'italic'}}>click here</Button>.</div>
                <Modal title="Import Static Student File Help" open={state.isHelpModalOpen} onCancel={() => dispatch({name: "CloseHelpModal"})} onOk={() => dispatch({name: "CloseHelpModal"})} footer={<Button type="primary" onClick={() => dispatch({name: "CloseHelpModal"})}>Ok</Button>}>
                    <Message messageKey="staticStudentFileHelp" />
                </Modal>
            </Form.Item>
        </Form>
        okButtonText = state.fileList.length ? "Upload" : "Create Empty Section";
        okButtonFcn = onOk;
        footer = undefined;
    }

    return <Modal
        title={"Add Static Student Section"}
        destroyOnClose={true}
        open={props.open}
        footer={footer}
        onCancel={props.onCancel}
        onOk={okButtonFcn}
        okButtonProps={ { disabled: state.sectionName.trim() === "" } }
        okText={okButtonText}
        afterClose={props.onCloseAnimationComplete}>
            {content}
    </Modal>
}