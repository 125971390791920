import {Alert, List, Space, Spin} from "antd";
import "./addmirroredsectionsectionselect.css"
import {MirroredSection, Section} from "./models";
// import {RightOutlined} from "@ant-design/icons";
import { Checkbox } from 'antd';

export interface AddMirroredSectionSectionSelectProps {
    sourceCourseName: string,
    destCourseName: string,
    destCourseMirroredSections?: {[sisSectionId: string]: MirroredSection},
    sourceCourseId: number,
    fetchingSection: boolean,
    errorFetchingSections: boolean,
    sections?: Section[],
    onSearch?: (value: string) => void
    onSelectSection: (section: Section) => void;
    selectedSections?: Section[];
}

export const AddMirroredSectionSectionSelect = ({...props}: AddMirroredSectionSectionSelectProps) => {
    let listContent;
    if(props.errorFetchingSections) {
        listContent = <Alert type="error" message="Failed to load sections." showIcon/>;
    } else if(props.fetchingSection) {
        listContent = <Spin/>;
    } else {
        listContent = <List
            size="large"
            dataSource={props.sections}
            renderItem={section => (
                <List.Item onClick={() => {if (!sectionAlreadyMirrored(section.sisSectionId, props.destCourseMirroredSections) && !!section.sisSectionId && !section.sisSectionId.startsWith("MIRR.")) { props.onSelectSection(section)}}} >
                    <Checkbox checked={sectionAlreadyMirrored(section.sisSectionId, props.destCourseMirroredSections) || !!props.selectedSections?.find(s => s.canvasId === section.canvasId)}
                              disabled={sectionAlreadyMirrored(section.sisSectionId, props.destCourseMirroredSections) || !section.sisSectionId || section.sisSectionId.startsWith("MIRR.")}
                    ></Checkbox>
                    <List.Item.Meta title={section.name}
                                    description={
                                        <>
                                            <span>{section.sisSectionId ?? "-- No Section ID --"}</span>
                                        </>
                                    }/>
                </List.Item>
            )}
        />
    }

    return <Space direction="vertical" className="select-sections-search">
        <div className="select-sections-search-heading">To {props.destCourseName}</div>
        <div className="select-sections-search-heading">From {props.sourceCourseName}</div>
        <div className="select-sections-search-list">
            {listContent}
        </div>
    </Space>
}

const sectionAlreadyMirrored = (selectedSisSectionId?: string | null, destCourseMirroredSections?: {[sisSectionId: string]: MirroredSection}) : boolean => {
    // TODO The "MIRR." string should be put in config probably.
    if (!destCourseMirroredSections) {
        return false;
    }
    return !!Object.values(destCourseMirroredSections).find(ms => ms.sourceSisSectionId === selectedSisSectionId);
}