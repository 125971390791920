import {Checkbox, Space} from "antd";
import {useEffect, useState} from "react";
import {CheckboxChangeEvent} from "antd/lib/checkbox";

export interface AgreementListProps {
    statements: string[];
    onAllAgreementChange: (allAgreed: boolean) => void;
}

export function AgreementList(props: AgreementListProps) {
    const [agreementCount, setAgreementCount] = useState(() => 0);

    const {onAllAgreementChange} = props;
    const allAgreed = agreementCount === props.statements.length;
    useEffect(() => onAllAgreementChange(allAgreed), [onAllAgreementChange, allAgreed]);

    const onChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setAgreementCount(agreementCount + 1);
        } else {
            setAgreementCount(agreementCount - 1);
        }
    }

    return <Space direction="vertical" className="agreement-list" size="large">
        {props.statements.map((statement, index) => {
            return (
                <Space key={`statement-${index}`} direction="vertical">
                    <div dangerouslySetInnerHTML={{__html: statement}}></div>
                    <Checkbox onChange={onChange}>Yes, I agree</Checkbox>
                </Space>
            );
        })}
    </Space>
}