import {CourseAvailStatement} from '../models'
import {SessionService} from "./SessionService";
import {wsErrorFromResponse} from "../errors";

function decodeResponse(response: Response) {
    if (!response.ok) {
        throw wsErrorFromResponse(response);
    }
    if(response.status === 204) {
        return Promise.resolve();
    }
    return response.json();
}

export default class CourseAvailabilityService implements ICourseAvailabilityService {

    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async getCourseAvailabilityStatementsByType(siteType: string): Promise<CourseAvailStatement[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/availabilitystatements/${siteType}`, {
             headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        return await decodeResponse(response);
    }

    async publishCourseByCourseId(courseId: number): Promise<boolean> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}`, {
            method: 'PUT',
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                accept: "application/json",
                "Content-Type": "application/json",
                body: '{ "setupSate" : "available" }'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
        }
        return true;
    }
}

export interface ICourseAvailabilityService {

}