import { InboxOutlined } from "@ant-design/icons";
import {Button, Form, Modal, Progress, Spin, Upload, UploadFile, UploadProps, message, Input} from "antd";
import { Course, Section, StaticStudentSectionEnrol } from "./models";
import { read, utils } from "xlsx";
import {useReducer} from "react";
import CourseService from "./services/CourseService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import Message from "./Message";

const { Dragger } = Upload;

export interface UpdateStaticStudentSectionModelProps {
    courseService: CourseService;
    open: boolean;
    fetchingCourse: boolean;
    currentCourse?: Course;
    fetchingSection: boolean;
    currentSection?: Section;
    onCancel: () => void;
    onUpdateStaticStudentSectionOperationPosted: () => void;
    onCloseAnimationComplete?: () => void;
}

export interface UpdateStaticStudentSectionModelState {
    students: StaticStudentSectionEnrol[];
    fileList: UploadFile[];
    processedStudents: number;
    enrollmentChanges: number;
    isHelpModalOpen: boolean;
    results?: EnrollmentResults;
}

interface EnrollmentResults {
    enrollmentsAdded: StaticStudentSectionEnrol[];
    enrollmentsRemoved: StaticStudentSectionEnrol[];
    enrollmentErrors: StaticStudentSectionEnrol[];
}

interface SetFileList {
    name: "SetFileList";
    fileList: UploadFile[];
}

interface SetStudents {
    name: "SetStudents";
    students: StaticStudentSectionEnrol[];
}

interface IncrementProcessedStudents {
    name: "IncrementProcessedStudents";
}

interface SetEnrollmentChanges {
    name: "SetEnrollmentChanges";
    enrollmentChanges: number;
}

interface OpenHelpModal {
    name: "OpenHelpModal";
}

interface CloseHelpModal {
    name: "CloseHelpModal";
}

interface SetResults {
    name: "SetResults";
    results: EnrollmentResults;
}

export type UpdateStaticStudentSectionModalAction =
    | SetFileList
    | SetStudents
    | IncrementProcessedStudents
    | SetEnrollmentChanges
    | OpenHelpModal
    | CloseHelpModal
    | SetResults;

function UpdateStaticStudentSectionStateReducer(state: UpdateStaticStudentSectionModelState, action: UpdateStaticStudentSectionModalAction): UpdateStaticStudentSectionModelState {
    switch (action.name) {
        case "SetStudents":
            return {
                ...state,
                students: action.students
            }
        case "SetFileList":
            return {
                ...state,
                fileList: action.fileList
            }
        case "IncrementProcessedStudents":
            return {
                ...state,
                processedStudents: state.processedStudents + 1
            }
        case "SetEnrollmentChanges":
            return {
                ...state,
                enrollmentChanges: action.enrollmentChanges
            }
        case "OpenHelpModal":
            return {
                ...state,
                isHelpModalOpen: true
            }
        case "CloseHelpModal":
            return {
                ...state,
                isHelpModalOpen: false
            }
        case "SetResults":
            return {
                ...state,
                results: action.results
            }
        default:
            return state;
    }
}

interface CreateEnrollmentParams {
    sectionId: number;
    studentId: string;
}

interface RemoveEnrollmentParams {
    sectionId: number;
    enrolmentId: number;
}

interface UpdateSectionParams {
    canvasCourseId: number;
    canvasSectionId: number;
    newName: string;
}

type F = () => Promise<void>;

async function promisePool(functions: F[], n: number): Promise<any> {
    async function evaluateNext() {
        const fn = functions.shift();
        if (!fn){
            return;
        }
        await fn();
        await evaluateNext();
    }
    const nPromises = Array(n).fill(0).map(evaluateNext);
    await Promise.all(nPromises);
};

export const UpdateStaticStudentSectionModal = (props: UpdateStaticStudentSectionModelProps) => {
    const [updateSectionForm] = Form.useForm()
    const sectionName = Form.useWatch('section-name', updateSectionForm);
    const sectionNameHasChanged: boolean = sectionName !== props.currentSection?.name;
    
    const queryClient = useQueryClient();

    const [state, dispatch] = useReducer(UpdateStaticStudentSectionStateReducer, { fileList: [], students: [], processedStudents: 0, enrollmentChanges: 0, isHelpModalOpen: false });

    const { isFetching: sectionEnrolmentsFetching, refetch } = useQuery(
        ["sectionEnrolments", props.currentSection?.canvasId],
        () => {
            if (!props.currentSection) {
                throw new Error("No section id specified");
            }
            return props.courseService.getStaticStudentEnrolments(props.currentSection.canvasId);
        },
        { enabled: false }
    );

    const { mutateAsync: postSectionEnrollment } = useMutation(
        ({ sectionId, studentId }: CreateEnrollmentParams) => {
            return props.courseService.postSectionEnrollment(sectionId, studentId);
        }, {});

    const { mutateAsync: removeSectionEnrollment } = useMutation(
        ({ sectionId, enrolmentId }: RemoveEnrollmentParams) => {
            return props.courseService.deleteSectionEnrollment(sectionId, enrolmentId);
        }, {});
    
    const { mutateAsync: updateSectionName, isLoading: updateSectionNameLoading, isSuccess: updateSectionNameSucceeded} = useMutation(
        ({ canvasCourseId, canvasSectionId, newName}: UpdateSectionParams) => {
            return props.courseService.putStaticStudentSectionName(canvasCourseId, canvasSectionId, newName);
        }, {
            onSuccess: (updatedSection: Section) => {
                queryClient.setQueryData(["sections", props.currentCourse!.canvasId], (oldSections: Section[]|undefined) => {
                    if (!oldSections) {
                        return [];
                    }
                    return oldSections.map((section) => {
                        if (section.canvasId === updatedSection.canvasId) {
                            return updatedSection;
                        }
                        return section;
                    });
                });
            }
        });

    const fileList = state.fileList;
    const fileUploadProps: UploadProps = {
        name: 'file',
        accept: '.csv',
        multiple: false,
        maxCount: 1,
        async beforeUpload(file) {
            const fileArray = await file.arrayBuffer();
            const wb = read(fileArray);
            const data = utils.sheet_to_json<StaticStudentSectionEnrol>(wb.Sheets[wb.SheetNames[0]]);
            const validFile = data.length > 0 && data.reduce((curr, student) => {
                if (!student.UserSisId) {
                    return false;
                }
                return curr;
            }, true)
            if (!validFile) {
                message.error("Invalid file format");
                return false;
            }
            dispatch({ name: "SetStudents", students: data });
            dispatch({ name: "SetFileList", fileList: [file] });
            return false;
        },
        onRemove(file) {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            dispatch({ name: "SetFileList", fileList: newFileList });
            dispatch({ name: "SetStudents", students: [] });
        },
        fileList
      };
    
    const updateEnrollments = async () => {
        try {
            const { data: sectionEnrolments } = await refetch();
            
            if (!sectionEnrolments) {
                throw new Error("No section enrolments loaded");
            }
            
            const enrollmentsToAdd = state.students.filter((student) => {
                return !sectionEnrolments?.some(
                    (enrolment) => enrolment.userSisId === student.UserSisId
                );
            });
            
            const enrollmentsToRemove = sectionEnrolments.filter((enrolment) => {
                return !state.students.some((student) => student.UserSisId === enrolment.userSisId);
            });
            
            dispatch({ name: "SetEnrollmentChanges", enrollmentChanges: enrollmentsToAdd.length + enrollmentsToRemove.length });
            
            const enrollmentErrors: StaticStudentSectionEnrol[] = [];
            
            const addFunctions = enrollmentsToAdd.map((enrollment) => {
                return () => new Promise<void>(async (resolve) => {
                    try {
                        await postSectionEnrollment({ sectionId: props.currentSection!.canvasId, studentId: enrollment.UserSisId });
                    } catch (e) {
                        console.error(e);
                        enrollmentErrors.push(enrollment);
                        enrollmentsToAdd.splice(enrollmentsToAdd.indexOf(enrollment), 1);
                    } finally {
                        dispatch({ name: "IncrementProcessedStudents" });
                        resolve();
                    }
                });
            });
            
            const removeFunctions = enrollmentsToRemove.map((enrollment) => {
                return () => new Promise<void>(async (resolve) => {
                    try {
                        await removeSectionEnrollment({ sectionId: props.currentSection!.canvasId, enrolmentId: enrollment.id });
                    } catch (e) {
                        console.error(e);
                        enrollmentErrors.push({
                            UserSisId: enrollment.userSisId!,
                            RoleId: enrollment.roleId,
                        });
                        enrollmentsToRemove.splice(enrollmentsToRemove.indexOf(enrollment), 1);
                    } finally {
                        dispatch({ name: "IncrementProcessedStudents" });
                        resolve();
                    }
                });
            });
            
            await promisePool([...addFunctions, ...removeFunctions], 5);
            
            const results = {
                enrollmentsAdded: enrollmentsToAdd,
                enrollmentsRemoved: enrollmentsToRemove.map((enrollment) => {
                    return {
                        UserSisId: enrollment.userSisId!,
                        RoleId: enrollment.roleId,
                    };
                }),
                enrollmentErrors,
            };
            
            dispatch({ name: "SetResults", results });
        } catch (e) {
            console.error(e);
            message.error("Error updating static student section");
            props.onCancel();
        }
    };
    
    const onOk = async (): Promise<any> => {
        if (!props.currentCourse) {
            throw new Error("No course loaded");
        }
        
        if (!state.fileList || state.fileList.length === 0) {
            if (sectionNameHasChanged) {
                await updateSectionName({
                    canvasCourseId: props.currentCourse.canvasId,
                    canvasSectionId: props.currentSection?.canvasId!,
                    newName: updateSectionForm.getFieldValue('section-name')
                });
            } else {
                throw new Error("No student list file provided");
            }
        } else {
            if (sectionNameHasChanged) {
                await updateSectionName({
                    canvasCourseId: props.currentCourse.canvasId,
                    canvasSectionId: props.currentSection?.canvasId!,
                    newName: updateSectionForm.getFieldValue('section-name')
                });
            }
            await updateEnrollments();
        }
    };


    let content, okButtonText, okButtonFcn, footer;

    if (!props.currentCourse || !props.currentSection || props.fetchingCourse || props.fetchingSection || updateSectionNameLoading) {
        content = <Spin/>;
        footer = null;
    } else if (props.currentCourse.endAt && moment(props.currentCourse.endAt).isBefore(moment())) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Course {props.currentCourse.name} has ended.</div>
            <div>Course end date: {moment(props.currentCourse.endAt).format("MM/DD/YYYY")}</div>
        </div>
        footer = null;
    } else if (sectionEnrolmentsFetching) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Gathering section enrollments for {props.currentSection.name} in course, {props.currentCourse.name}.</div>
            <Spin/>
        </div>
        footer = null;
    } else if (state.processedStudents < state.enrollmentChanges) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Updating section enrollments for {props.currentSection.name} in course {props.currentCourse.name}.</div>
            <Progress type="line" percent={Math.round((state.processedStudents / state.enrollmentChanges) * 100)} />
        </div>
        footer = null;
    } else if (state.results) {
        content = <div>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>Updated section enrollments for {props.currentSection.name} in course {props.currentCourse.name}.</div>
            <div style={{marginBottom: "1em"}}>Added {state.results.enrollmentsAdded.length} enrollments</div>
            <div style={{marginBottom: "1em"}}>Removed {state.results.enrollmentsRemoved.length} enrollments</div>
            <div style={{marginBottom: "1em"}}>Encountered {state.results.enrollmentErrors.length} errors</div>
            {state.results.enrollmentErrors.length > 0 && <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "14px"}}>Errors</div>
                <ul>
                    {state.results.enrollmentErrors.map((enrollment) => <li>{enrollment.UserSisId}</li>)}
                </ul>
            </div>}
        </div>
        footer = <Button onClick={props.onUpdateStaticStudentSectionOperationPosted}>Close</Button>
    } else if (updateSectionNameSucceeded && !state.results) {
        content =  <div style={{fontWeight: "500", marginBottom: "1em", fontSize: "14px"}}>Sucessfully updated section name for {props.currentSection.name} in course {props.currentCourse.name}.</div>
        footer = <Button onClick={props.onUpdateStaticStudentSectionOperationPosted}>Close</Button>
    } else {
        content = <Form
            layout={"vertical"}
            form={updateSectionForm}>
            <div style={{fontWeight: "700", marginBottom: "1em", fontSize: "16px"}}>{props.currentCourse.name}</div>
            <Form.Item
                name={"section-name"}
                label={"Section Name"}
                initialValue={props.currentSection.name}
                rules={[{ required: true}]}>
                <Input />
            </Form.Item>
            <Form.Item name={"student-list"} label={"Student List File"}>
                <Dragger {...fileUploadProps} >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Files must be in CSV format.
                    </p>
                </Dragger>
            </Form.Item>
            <Form.Item name={"file-help"}>
            <div style={{fontStyle: "italic"}}>For an example of the accepted file format, please <Button type="link" onClick={() => dispatch({name: "OpenHelpModal"})} style={{padding: 0, fontStyle: 'italic'}}>click here</Button>.</div>
                <Modal title="Import Static Student File Help" open={state.isHelpModalOpen} onCancel={() => dispatch({name: "CloseHelpModal"})} onOk={() => dispatch({name: "CloseHelpModal"})} footer={<Button type="primary" onClick={() => dispatch({name: "CloseHelpModal"})}>Ok</Button>}>
                    <Message messageKey={"staticStudentFileHelp"} />
                </Modal>
            </Form.Item>
        </Form>
        okButtonText = state.fileList.length ? "Upload" : "Save";
        okButtonFcn = onOk;
        footer = undefined;
    }

    return <Modal
        title={"Update Static Student Section"}
        destroyOnClose={true}
        open={props.open}
        footer={footer}
        onCancel={props.onCancel}
        onOk={okButtonFcn}
        okText={okButtonText}
        okButtonProps={{
            disabled: !sectionNameHasChanged && state.fileList.length === 0,
        }}
        afterClose={props.onCloseAnimationComplete}>
            {content}
    </Modal>
}