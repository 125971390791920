import {Alert, List, Space} from "antd";
import "./addmirroredsectionconfirm.css"
import {Section} from "./models";

export interface AddMirroredSectionConfirmProps {
    sectionsToMirror: Section[],
    fromTerm?: string,
    sourceCourseName: string,
    sourceCourseSisId: string,
    toName: string,
    toTerm?: string,
    toSisId?: string,
    error?: string,
}

export const AddMirroredSectionConfirm = ({...props}: AddMirroredSectionConfirmProps) => {
    return <Space direction="vertical" className="addmirroredsection-confirm">
        {props.error ? <Alert type="error" message={props.error} showIcon/> : <></>}
        <div className="addmirroredsection-confirm-to-label">Mirror to course:</div>
        <List>
            <List.Item>
                <List.Item.Meta title={props.toName}
                                description={
                                    <><span>{props.toTerm}</span><span>{props.toSisId}</span></>
                                }/>
            </List.Item>
        </List>

        <div className="addmirroredsection-sectiontomirror-label">From Course:</div>
        <List>
            <List.Item>
                <List.Item.Meta title={props.sourceCourseName}
                                description={
                                    <><span>{props.sourceCourseSisId}</span></>
                                }/>
            </List.Item>
        </List>

        <div className="addmirroredsection-sectiontomirror-label">Sections:</div>
        <List
            size="large"
            dataSource={props.sectionsToMirror}
            renderItem={section => (
                <List.Item>
                    <List.Item.Meta title={section.name}
                                    description={
                                        <>
                                            <span>{section.sisSectionId}</span>
                                        </>
                                    }/>
                </List.Item>
            )}
        />


    </Space>
}