import {CourseDto, CoursesPageDto, SectionDto, TermDto, UserDTO} from "../dtos";
import {wsErrorFromResponse} from "../errors";
import {CourseSetupState, Term} from "../models";
import {SessionService} from "./SessionService";
import parseISO from 'date-fns/parseISO'
import {chunks} from "../utils/ArrayUtils";

type CourseProgress = {
    canvasId: number,
    sisCourseId?: string,
    name: string,
    courseCode: string,
    setupState: CourseSetupState,
    startAt?: Date,
    endAt?: Date,
    canvasCourseUrl: string,
    siteType: string,
    term?: Term,
    termId: number,
    termYear?: string,
    transitionDates: {
        [key in CourseSetupState]?: Date
    },
    staff?: CourseProgressStaffMap,
}

type CourseProgressStaffMap = {
    [role: string]: CourseProgressStaffDetails|undefined,
};

type CourseProgressStaffDetails = {
    name: string,
    sisId?: string,
    email?: string,
};

interface SectionDetail {
    sectionCanvasId: number,
    sectionSisId?: string,
    sectionName: string,
    courseCanvasId: number,
    courseSisId?: string,
    courseName: string,
}

function decodeResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        console.log('SummaryService status=' + response.status);
        console.log('SummaryService statusText=' + response.statusText);
        throw wsErrorFromResponse(response);
    }
    return response.json();
}

export default class SummaryService {
    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async getTermsByYear(year: string): Promise<Term[]> {

        const response = await fetch(`${this.apiBaseUrl}/api/terms?year=${year}`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        const remoteTerms = await decodeResponse<TermDto[]>(response);

        const terms: Term[] = remoteTerms.map(function (remoteTerm) {
            return {
                canvasId: remoteTerm.canvasId,
                name: remoteTerm.name,
                sisTermId: remoteTerm.sisTermId,
                endAt: !!remoteTerm.endAt ? parseISO(remoteTerm.endAt) : undefined,
                startAt: !!remoteTerm.startAt ? parseISO(remoteTerm.startAt) : undefined,
                workflowState: remoteTerm.workflowState,
                courseStats: remoteTerm.courseStats
            };
        });
        return terms;
    }

    async getYears(): Promise<string[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/years`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        const remoteYears = await decodeResponse<string[]>(response);

        return remoteYears;
    }

    async getCourseProgressForTerm(enrollmentTermId: number, includedRoles: string[]): Promise<CourseProgress[]> {

        const termIdParam = (enrollmentTermId ? `&enrollmentTermId=${enrollmentTermId}` : '');

        let lastEvaluatedCourseId: number | undefined = undefined;

        const courses: CourseDto[] = [];

        do {
            let lastEvaluatedCourseIdParam: string = lastEvaluatedCourseId ? `&lastEvaluatedCourseId=${lastEvaluatedCourseId}` : '';
            const response = await fetch(`${this.apiBaseUrl}/api/courses?ignored=${termIdParam}${lastEvaluatedCourseIdParam}&includeDeleted=true&includeConcluded=true`, {
                headers: {
                    Authorization: await this.getAuthorizationHeader()
                }
            });

            const remoteCoursePage = await decodeResponse<CoursesPageDto>(response);

            courses.push(...remoteCoursePage.courses);

            lastEvaluatedCourseId = remoteCoursePage.lastEvaluatedCourseId;

        } while (lastEvaluatedCourseId);

        const staffCanvasIds = courses.flatMap(c => c.teachers?.filter(t => includedRoles.includes(t.role))?.map(t => t.userId)).filter((t): t is number => !!t);
        const chunkedStaffIds = [...chunks(staffCanvasIds, 100)];

        const staffPromises = chunkedStaffIds.map(async (staffCanvasIds) => {
            const response = await fetch(`${this.apiBaseUrl}/api/users?canvasIds=${staffCanvasIds.join(",")}`, {
                headers: {
                    Authorization: await this.getAuthorizationHeader()
                }
            });

            const remoteUsers = await decodeResponse<UserDTO[]>(response);
            return remoteUsers.map(u => [u.canvasId, u] as const);
        });
        const staffMap = new Map((await Promise.all(staffPromises)).flat());

        return courses.map(c => this.mapRemoteCourseToCourseProgress(c, staffMap, includedRoles));
    }

    private mapRemoteCourseToCourseProgress(remoteCourse: CourseDto, userMap: Map<number, UserDTO>, includedRoles: string[]): CourseProgress {
        const staff: CourseProgress['staff'] = remoteCourse.teachers
            ?.filter(t => includedRoles.includes(t.role))
            ?.reduce((result: CourseProgressStaffMap, teacher): CourseProgressStaffMap => {
                const user = userMap.get(teacher.userId);
                if (!user) {
                    return result;
                }

                const previous = result[teacher.role];

                const roleDetails: CourseProgressStaffDetails = {
                    name: previous?.name ? `${previous.name}, ${teacher.displayName}` : teacher.displayName,
                    sisId: teacher.sisUserId ?
                        previous?.sisId ? `${previous.sisId}, ${teacher.sisUserId}` : teacher.sisUserId :
                        undefined,
                    email: user?.email ?
                        previous?.email ? `${previous.email}, ${user?.email}` : user?.email :
                        undefined,
                }

                return {
                    ...result,
                    [teacher.role]: roleDetails,
                };
            }, {});

        return {
            canvasId: remoteCourse.canvasId,
            sisCourseId: remoteCourse.sisCourseId,
            name: remoteCourse.name,
            courseCode: remoteCourse.courseCode,
            setupState: remoteCourse.setupState,
            startAt: remoteCourse.startAt ? parseISO(remoteCourse.startAt) : undefined,
            endAt: remoteCourse.endAt ? parseISO(remoteCourse.endAt) : undefined,
            canvasCourseUrl: remoteCourse.canvasCourseUrl,
            siteType: remoteCourse.siteType,
            term: remoteCourse.term ? {
                canvasId: remoteCourse.term.canvasId,
                name: remoteCourse.term.name,
                startAt: remoteCourse.term.startAt ? parseISO(remoteCourse.term.startAt) : undefined,
                endAt: remoteCourse.term.endAt ? parseISO(remoteCourse.term.endAt) : undefined,
                workflowState: remoteCourse.term.workflowState,
                sisTermId: remoteCourse.term.sisTermId,
            } : undefined,
            termId: remoteCourse.termId,
            termYear: remoteCourse.termYear,
            transitionDates: Object.values(CourseSetupState).reduce((stateDates, state) => {
                const dateStr = remoteCourse.transistionDates?.[state];
                return ({
                    ...stateDates,
                    [state]: dateStr ? parseISO(dateStr) : undefined
                });
            },{}),
            staff,
        }
    }

    async getSectionsForTerm(enrollmentTermId: number): Promise<SectionDetail[]> {

        const termIdParam = (enrollmentTermId ? `&enrollmentTermId=${enrollmentTermId}` : '');

        let lastEvaluatedCourseId: number | undefined = undefined;

        const sections: SectionDetail[] = [];

        do {
            let lastEvaluatedCourseIdParam: string = lastEvaluatedCourseId ? `&lastEvaluatedCourseId=${lastEvaluatedCourseId}` : '';
            const response = await fetch(`${this.apiBaseUrl}/api/courses?ignored=${termIdParam}${lastEvaluatedCourseIdParam}&includeDeleted=true&includeConcluded=true`, {
                headers: {
                    Authorization: await this.getAuthorizationHeader()
                }
            });

            const remoteCoursePage = await decodeResponse<CoursesPageDto>(response);
            
            sections.push(...remoteCoursePage.courses.flatMap(course => {
                return this.mapRemoteCourseToSectionDetail(course);
            }));

            lastEvaluatedCourseId = remoteCoursePage.lastEvaluatedCourseId;

        } while (lastEvaluatedCourseId)

        return sections;
    }
    
    private mapRemoteCourseToSectionDetail(course: CourseDto): SectionDetail[] {
        if (!course.sections) {
            return [];
        }
        return course.sections.map((section: SectionDto) => {
            return {
                sectionCanvasId: section.canvasId,
                sectionSisId: section.sisSectionId,
                sectionName: section.name,
                courseCanvasId: course.canvasId,
                courseSisId: course.sisCourseId,
                courseName: course.name,
                // additional fields from Mitch's code
                courseCode: course.courseCode,
                startAt: section.startAt ? parseISO(section.startAt) : undefined,
                endAt: section.endAt ? parseISO(section.endAt) : undefined,
                canvasCourseUrl: course.canvasCourseUrl,
            };
        });
    }
}