import {Account, Term} from '../models'
import {SessionService} from "./SessionService";
import parseISO from 'date-fns/parseISO'
import {wsErrorFromResponse} from "../errors";
import {TermDto} from "../dtos";

function decodeResponse(response: Response) {
    if (!response.ok) {
        throw wsErrorFromResponse(response);

    }
    if(response.status === 204) {
        return Promise.resolve();
    }
    return response.json();
}

export default class ReferenceDataService {

    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async getAllAccounts(): Promise<Account[]> {
        return fetch(`${this.apiBaseUrl}/api/accounts`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);
    }
    async getAllTerms(): Promise<Term[]> {
        let remoteTerms:TermDto[] = await fetch(`${this.apiBaseUrl}/api/terms`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);

        const terms: Term[] = remoteTerms.map(function (remoteTerm) {
            return {
                canvasId: remoteTerm.canvasId,
                name: remoteTerm.name,
                sisTermId: remoteTerm.sisTermId,
                endAt: !!remoteTerm.endAt ? parseISO(remoteTerm.endAt) : undefined,
                startAt: !!remoteTerm.startAt ? parseISO(remoteTerm.startAt) : undefined,
                workflowState: remoteTerm.workflowState
            };
        });
        return terms;
    }
    async getTermsForYear(year:string): Promise<Term[]> {
        let remoteTerms:TermDto[] = await fetch(`${this.apiBaseUrl}/api/terms?year=${year}`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);

        const terms: Term[] = remoteTerms.map(function (remoteTerm) {
            return {
                canvasId: remoteTerm.canvasId,
                name: remoteTerm.name,
                sisTermId: remoteTerm.sisTermId,
                endAt: !!remoteTerm.endAt ? parseISO(remoteTerm.endAt) : undefined,
                startAt: !!remoteTerm.startAt ? parseISO(remoteTerm.startAt) : undefined,
                workflowState: remoteTerm.workflowState
            };
        });
        return terms;
    }

    async getYears(): Promise<string[]> {
        let remoteYears:string[] = await fetch(`${this.apiBaseUrl}/api/years`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);


        return remoteYears;
    }
}

