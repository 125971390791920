import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Badge, Empty } from "antd";
import { useEffect, useReducer } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { CourseSetupState, Term } from "./models";
import "./termsummarycard.css";
import { mapCourseStateToColourCode, mapCourseStateToLabel } from "./utils/CourseSetupState";
import TermSummaryExport from "./TermSummaryExport";
import SectionExport from "./SectionsExport";

export interface TermSummaryCardProps {
    title: string;
    current: boolean;
    expanded?: boolean;
    term: Term;
    stats: {
        [key in CourseSetupState]?: number
    }
    showPercentages: boolean;
}

export interface TermSummaryCardState {
    current?: boolean;
    expanded?: boolean;
}

interface SetExpanded {
    name: "SetExpanded";
    expanded: boolean;
}

interface SetCurrent {
    name: "SetCurrent";
    current: boolean;
}

export type TermSummaryCardAction =
    | SetExpanded
    | SetCurrent

ChartJS.register(ArcElement, Tooltip);

const summaryCardStateReducer = (state: TermSummaryCardState, action: TermSummaryCardAction): TermSummaryCardState => {
    switch(action.name) {
        case "SetExpanded":
            return {
                ...state,
                expanded: action.expanded
            };
        case "SetCurrent":
            if (state.current !== action.current) {
                return {
                    ...state,
                    current: action.current
                }
            }
            return state;            
        default:
            return state;
    }
}

export const TermSummaryCard = ({title, expanded, current, stats, term, showPercentages}: TermSummaryCardProps) => {

    const initialState: TermSummaryCardState = {expanded: !!expanded, current: !!current};

    const [state, dispatch] = useReducer(summaryCardStateReducer, initialState);

    const titleBarClick = () => {
        dispatch({ name: "SetExpanded", expanded: !state.expanded });
    }

    useEffect(() => {
        if(current) {
            dispatch({ name: "SetExpanded", expanded: current });
        }
    }, [current])

    let collapsed = (state.expanded)? '': 'collapsed';

    const noOfCourses = Object.values(stats).reduce((prev, curr) => prev + curr, 0);

    const getPercentage = (count: number): number => {
        if (noOfCourses === 0 || count === 0) {
            return 0;
        } else {
            return Math.round((count / noOfCourses) * 100);
        }
    }

    const formatPercentage = (value: number): string => {
        return value === 0 ? '-' : `${value}%`;
    };
    
    return (
        <div className={"termcard"}>
            <div className={["termcard-titlebar",collapsed].join(" ")}
                onClick={titleBarClick}>
                <div className={'termcard-titlebar-horizontalspace'}>
                    <div className={"termcard-titlecontainer"}>
                        <h1>{title}</h1>
                    </div>
                    {
                        <div className={"termcard-expandbutton"}>
                            { state.expanded ? <UpOutlined/> : <DownOutlined/> }
                        </div>
                    }
                </div>
            </div>
            {state.expanded ?
                <>
                    <div className={'termcard-body'}>
                        <div className={'termcard-display'}>
                            <div className={"termcard-graph-wrapper"}>
                                {(noOfCourses > 0)? <Pie
                                    data={{
                                        labels: Object.values(CourseSetupState).map(state => mapCourseStateToLabel(state)),
                                        datasets: [{
                                            label: showPercentages ? " % of all courses" : " # of courses",
                                            data: showPercentages 
                                                ? Object.values(CourseSetupState).map(courseState => getPercentage(stats[courseState] ?? 0))
                                                : Object.values(CourseSetupState).map(courseState => stats[courseState] ?? 0),
                                            backgroundColor: Object.values(CourseSetupState).map(courseState => mapCourseStateToColourCode(courseState))
                                        }]
                                    }}/>: <Empty/>
                                }
                            </div>
                            <div className={"termcard-coursestats"}>
                                {
                                    Object.values(CourseSetupState).map(courseState => {
                                        return <div key={courseState}>
                                            <Badge 
                                                count={showPercentages ? formatPercentage(getPercentage(stats[courseState] ?? 0)) : stats[courseState] ?? 0}
                                                color={mapCourseStateToColourCode(courseState)} 
                                                showZero overflowCount={9999}
                                            />
                                            <span className={"termcard-coursestats-label"}>{mapCourseStateToLabel(courseState)}</span>
                                        </div>
                                    })
                                }
                            </div>
                            <div className={"termcard-courseNumber"}>
                                <strong>{noOfCourses}</strong> Courses
                            </div>
                        </div>
                    </div>
                    <div className={'termcard-bottombar'}>
                        <SectionExport term={term} />
                        <TermSummaryExport term={term} />
                    </div>
                </> : <></>
            }
        </div>
    )
};

