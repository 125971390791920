import {Account} from '../models'
import {SessionService} from "./SessionService";
import {wsErrorFromResponse} from "../errors";

function decodeResponse(response: Response) {
    if (!response.ok) {
        throw wsErrorFromResponse(response);
    }
    if(response.status === 204) {
        return Promise.resolve();
    }
    return response.json();
}

export default class AccountService {

    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async getAllAccounts(): Promise<Account[]> {
        return fetch(`${this.apiBaseUrl}/api/accounts`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        }).then(decodeResponse);
    }
}
