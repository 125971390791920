import {RefObject, useState} from "react";
import useResizeObserver from "@react-hook/resize-observer";

const calcIsOverflowing = (el: HTMLElement): boolean => {
    return el.offsetWidth < el.scrollWidth || el.offsetHeight < el.scrollHeight;
}

const useIsOverflowing = (target: RefObject<HTMLElement>) => {
    const [isOverflowing, setIsOverflowing] = useState<boolean>();

    // Seems to work without this, but I'm leaving it here in case we find we need it in the future.
    // useLayoutEffect(() => {
    //     setIsOverflowing(target.current ? calcIsOverflowing(target.current) : undefined);
    // }, [target.current]);

    useResizeObserver(target, (entry) => {
        setIsOverflowing((entry.target instanceof HTMLElement) ? calcIsOverflowing(entry.target) : undefined)
    });

    return isOverflowing ?? false;
}

export default useIsOverflowing;