import {Alert, Input, Modal, Result, Space, Spin, Tag} from "antd";
import {Course, EmailNotification} from "./models";
import {useMutation, useQuery} from "@tanstack/react-query";
import CourseService from "./services/CourseService";
import {useEffect, useState} from "react";
import "./coursereleasemodal.css";
import {usePublicClientConfig} from "./services/PublicClientConfigService";
import {unique} from "./utils/ArrayUtils";
import {useMessages} from "./utils/useMessages";
import Message from "./Message";

export interface CourseReleaseModalProps {
    courseService: CourseService;
    fetchingCourse: boolean;
    currentCourse?: Course;
    onCancel: () => void;
    onReleaseOperationPosted: () => void;
    onCloseAnimationComplete?: () => void;
    open: boolean;
}

interface PostReleaseOperationVars {
    currentCourseId: number,
    emailNotification: EmailNotification,
}

export const CourseReleaseModal = ({...props}: CourseReleaseModalProps) => {
    const [emailNotification, setEmailNotification] = useState<EmailNotification>();
    const [modalDescription] = useMessages(
        [ "courseReleaseModalDescription"])
    const {
        mutate: postReleaseOperation,
        isLoading: postingRelease,
        isError: postReleaseOperationIsError
    } = useMutation(
        ["postreleaseoperation", props.currentCourse?.canvasId],
        (vars: PostReleaseOperationVars) => props.courseService.postReleaseOperation(vars.currentCourseId, vars.emailNotification),
        {
            onSuccess: () => {
                props.onReleaseOperationPosted();
            }
        }
    );

    const {
        data: template,
        isFetching: fetchingEmailNotification,
        isError: isFetchingEmailNotificationError
    } = useQuery<EmailNotification, Error>(["coursereleaseemail", props.currentCourse?.canvasId],
        () => {
            if (props.currentCourse) {
                return props.courseService.getCourseNotificationEmailTemplate(props.currentCourse.canvasId);
            } else {
                throw new Error("Current course ID is null");
            }
        }, {
            enabled: !!props.currentCourse?.canvasId,
        });

    useEffect(() => {
        setEmailNotification(template);
    }, [template]);

    const clientConfig = usePublicClientConfig();

    let content: JSX.Element;
    let displayFooter = false;
    if (isFetchingEmailNotificationError) {
        content = <Result status="error" title="Failed to load the email notification template"/>
    } else if (props.fetchingCourse || !props.currentCourse || fetchingEmailNotification || !emailNotification) {
        content = <Spin/>
    } else {
        const notifiedUsers = (props.currentCourse.teachers ?? []).filter(t => clientConfig.releaseNotifyRoles.includes(t.role));
        const recipients = unique(notifiedUsers, t => t.userId).map(t => t.displayName);

        content = <Space direction="vertical" style={{ maxWidth: '100%' }}>
            <div className="course-release-heading" dangerouslySetInnerHTML={{__html: modalDescription.replace('{{courseTitle}}', props.currentCourse.name)}} />
            {recipients.length > 0 ? <div
                className="course-release-to-list">To: {recipients.map(displayName =>
                <Tag>{displayName}</Tag>)}</div> : <></>}
            { postReleaseOperationIsError ? <Alert type="error" message={<Message messageKey={"courseReleaseModalReleaseError"} />} showIcon/> : <></> }
            <Input.TextArea
                className="course-release-modal-email"
                defaultValue={emailNotification.bodyTemplate}
                onChange={event => setEmailNotification({
                    ...emailNotification,
                    bodyTemplate: event.target.value,
                })}/>

        </Space>
        displayFooter = true;
    }

    const onOk = () => {
        if (!props.currentCourse) {
            throw new Error("No course to release");
        } else if (!emailNotification) {
            throw new Error("No email notification")
        } else {
            return postReleaseOperation({
                currentCourseId: props.currentCourse.canvasId,
                emailNotification
            })
        }
    }

    return <Modal title={<Message messageKey={"courseReleaseModalTitle"}/>}
                  destroyOnClose={true}
                  open={props.open}
                  footer={displayFooter ? undefined : null} // undefined is default OK and cancel buttons, null is no footer
                  onCancel={props.onCancel}
                  onOk={onOk}
                  closable={!postingRelease}
                  maskClosable={!postingRelease}
                  cancelButtonProps={{disabled: postingRelease}}
                  okButtonProps={{disabled: postingRelease, loading: postingRelease}}
                  okText="Release"
                  afterClose={props.onCloseAnimationComplete}>
        {content}
    </Modal>
}

