/*
 * This file is "owned" by the UI package, and then copied into the Admin UI package.  If you need to modify them,
 * please do so in the UI package, and then copy the modified file into the Admin UI package using the shared-files.sh
 * script.
 */

export class WsError extends Error {
}

export class SessionExpiredError extends Error {
}

export class GenericWsError extends WsError {
    public readonly status: number;

    constructor(status: number, message: string) {
        super(message);
        this.status = status;
    }
}

export const wsErrorFromResponse = (response: Response): WsError => {
    if(response.status === 401 || response.status === 403) {
        return new SessionExpiredError();
    }
    return new GenericWsError(response.status, response.statusText);
}