import {useQuery} from "@tanstack/react-query";
import {Course, Operation} from "./models";
import {Spin} from "antd";
import {CourseCard} from "./CourseCard";
import {CourseModals} from "./CourseModals";
import CourseService from "./services/CourseService";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import CourseAvailabilityService from "./services/CourseAvailabilityService";
import UserService from "./services/UserService";
import RoleService from "./services/RoleService";
import {useEffect} from "react";
import {useCoursesListDispatch} from "./CoursesContext";

type Props = {

    courseId: number
    embeddedInCanvas?: boolean
    urlStem: string

    courseAvailabilityService: CourseAvailabilityService
    courseService: CourseService
    userService: UserService
    roleService: RoleService
}
const StandaloneCourseManager = (props: Props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const coursesListDispatch = useCoursesListDispatch();

    useEffect(() => {
        coursesListDispatch({ name: "SetExpandedCourseId", courseId: props.courseId });
    }, [props.courseId, coursesListDispatch]);

    const {data: course, isLoading: loadingCourse, refetch} = useQuery<Course, Error>(["course", props.courseId],
        () => {
            return props.courseService.getCourse(props.courseId);
        }, {
            refetchInterval: (course) => course?.copyInProgress ? 5000 : false
        });

    const closeModal = () => {
        navigate({
            pathname: `${props.urlStem}/${props.courseId}`,
            search: searchParams.toString(),
        }, {
            state: location.state,
        });
    }

    const initiateOperation = (operation: Operation) => {
        navigate({
            pathname: `${props.urlStem}/${props.courseId}/${operation}`,
            search: searchParams.toString(),
        }, {
            state: location.state,
        });
    };

    const initiateSectionOperation = (canvasSectionId: number, operation: Operation) => {
        navigate({
            pathname: `${props.urlStem}/${props.courseId}/sections/${canvasSectionId}/${operation}`,
            search: searchParams.toString()
        }, {
            state: location.state,
        });
    };

    return loadingCourse || !course ? <Spin/> :
        <>
            <CourseCard canToggleExpanded={false}
                        courseService={props.courseService}
                        canvasCourseId={course.canvasId}
                        courseCode={course.courseCode}
                        title={course.name}
                        expiresAt={course.expiresAt}
                        state={course.setupState}
                        canvasCourseUrl={course.canvasCourseUrl}
                        copyInProgress={course.copyInProgress}
                        expanded={true}
                        accountName={course.account?.name}
                        teachingPeriod={course.term?.name}
                        teachers={course.teachers}
                        sisId={course.sisCourseId}
                        onInitiateSkipCopy={async () => {
                            await refetch();
                        }}
                        onInitiateCopyContent={() => initiateOperation("copy")}
                        onInitiateCourseRelease={() => initiateOperation("release")}
                        onInitiateMakeAvailable={() => initiateOperation("makeAvailable")}
                        onInitiateManageUsers={() => initiateOperation("manageUsers")}
                        onInitiateAddMirroredSection={() => initiateOperation("addMirroredSection")}
                        onInitiateAddStaticSection={() => initiateOperation("addStaticStudentSection")}
                        onInitiateUpdateStaticSection={(_, canvasSectionId) => initiateSectionOperation(canvasSectionId, "updateStaticStudentSection")}
                        onInitiateAddStudentToStaticSection={(_, canvasSectionId) => initiateSectionOperation(canvasSectionId,"addStudentToStaticStudentSection")}
                        embeddedInCanvas={props.embeddedInCanvas ?? false}
                        commentCount={course.commentCount}
            />
            <CourseModals onCancel={closeModal}
                          onSuccess={async () => {
                              closeModal();
                              await refetch();
                          }}
                          courseAvailabilityService={props.courseAvailabilityService}
                          courseService={props.courseService}
                          userService={props.userService}
                          roleService={props.roleService}
            />
        </>
}

export default StandaloneCourseManager;