import {Avatar, AvatarProps} from "antd";

const getAvatarInitials = (name: string) => {
    const names = name.split(" ");
    if (names.length === 1) {
        return names[0].charAt(0);
    }
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
};

const generateColourCode = (name: string, saturation: number, luminosity: number) => {
    const hue = [...name].map(c => c.charCodeAt(0)).reduce((hash, val) => (val + hash) % 360, 0);
    return `hsl(${hue}, ${saturation}%, ${luminosity}%)`;
}

type NameAvatarProps = {
    name: string;
    saturation?: number;
    luminosity?: number;
} & AvatarProps;

const defaultProps = {
    saturation: 30,
    luminosity: 50,
    size: "large",
}

const NameAvatar = (props: NameAvatarProps) => {
    const propsWithDefaults = { ...defaultProps, ...props };
    return (
        <Avatar {...propsWithDefaults as AvatarProps} style={{ backgroundColor: generateColourCode(propsWithDefaults.name, propsWithDefaults.saturation, propsWithDefaults.luminosity) }}>
            {getAvatarInitials(props.name)}
        </Avatar>
    );
}

export default NameAvatar;