import {
    BlueprintSubscription,
    ContentMigration,
    Course, CourseComment, CourseSetupState,
    CoursesPage,
    EmailNotification,
    Enrollment,
    Section
} from '../models'
import {ContentMigrationDto, CourseDto, CoursesPageDto, EnrollmentDto, SectionDto} from "../dtos";
import {SessionService} from "./SessionService";
import parseISO from 'date-fns/parseISO'
import {wsErrorFromResponse} from "../errors";
import {exportToCSV } from '../utils/export';
import {handleClientError} from '../utils/clientErrorHandler';

function decodeResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        console.log('CourseService status=' + response.status);
        console.log('CourseService statusText=' + response.statusText);
        throw wsErrorFromResponse(response);
    }

    return response.json();
}

export default class CourseService implements ICourseService {

    private readonly apiBaseUrl: string;
    private readonly sessionService: SessionService;

    constructor(sessionService: SessionService, apiBaseUrl?: string) {
        this.sessionService = sessionService;
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
    }

    private async getAuthorizationHeader() {
        const session = await this.sessionService.getSession();
        return `Bearer ${session.bearerToken}`;
    }

    async getAllCourses(year?: string,
                        enrollmentTermId?: number,
                        accountTreePath?: string,
                        searchTerm?: string,
                        teacherId?: number,
                        state?: CourseSetupState,
                        includeDeleted?: boolean,
                        includeConcluded?: boolean,
                        siteTypes?: string[],
                        lastEvaluatedCourseId?: number|undefined
                        ): Promise<CoursesPage> {
        const yearParam = (year ? `&year=${year}` : '');
        const termIdParam = (enrollmentTermId ? `&enrollmentTermId=${enrollmentTermId}` : '');
        const accountParam = (accountTreePath ? `&accountTreePath=${accountTreePath}` : '');
        const searchParam = (searchTerm ? `&searchTerm=${searchTerm}` : '');
        const teacherParam = (teacherId ? `&teacher=${teacherId}` : '');
        const stateParam = (state ? `&setupState=${state}` : '');
        const includeDeletedParam = (includeDeleted ? `&includeDeleted=${includeDeleted}` : '');
        const includeConcludedParam = (includeConcluded ? `&includeConcluded=${includeConcluded}` : '');
        const lastEvaluatedCourseIdParam = (lastEvaluatedCourseId ? `&lastEvaluatedCourseId=${lastEvaluatedCourseId}` : '');
        const siteTypesParam = (siteTypes ? `&siteTypes=${siteTypes.join(',')}` : '');
        const response = await fetch(`${this.apiBaseUrl}/api/courses?ignored=${yearParam}${termIdParam}${accountParam}${searchParam}${teacherParam}${stateParam}${lastEvaluatedCourseIdParam}${siteTypesParam}${includeDeletedParam}${includeConcludedParam}`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });
        const remoteCoursePage = await decodeResponse<CoursesPageDto>(response);
        return {
            lastEvaluatedCourseId: remoteCoursePage.lastEvaluatedCourseId,
            courses: remoteCoursePage.courses.map(this.mapRemoteCourseToCourse),
        }
    }

    async getBlueprintSubscriptions(courseId: number): Promise<BlueprintSubscription[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/blueprints`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        return decodeResponse(response);
    }

    async getContentMigrations(courseId: number): Promise<ContentMigration[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/contentmigrations`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        if (response.status === 404) {
            return [];
        }

        const remoteMigrations = await decodeResponse<ContentMigrationDto[]>(response);

        return remoteMigrations.map( (rm) => {
            return {
                canvasId: rm.canvasId,
                attachment: rm.attachment,
                userId: rm.userId,
                migrationType: rm.migrationType,
                migrationIssuesUrl: rm.migrationIssuesUrl,
                migrationTypeTitle: rm.migrationTypeTitle,
                progressUrl: rm.progressUrl,
                workflowState: rm.workflowState,
                preAttachment: rm.preAttachment,
                finishedAt: !rm.finishedAt ? rm.finishedAt : parseISO(rm.finishedAt),
                startedAt: !rm.startedAt ? rm.startedAt : parseISO(rm.startedAt),
                settings: rm.settings,
                createdAt: rm.createdAt,
                migrationIssuesCount: rm.migrationIssuesCount
            } as ContentMigration
        });
    }

    async getSections(courseId: number): Promise<Section[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/sections`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        if (response.status === 404) {
            return [];
        }

        const sectionDtoList = await decodeResponse<SectionDto[]>(response);

        return sectionDtoList.map(rs => this.mapSectionDtoToSection(rs));
    }

    async getComments(courseId: number): Promise<CourseComment[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/comments`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        if (response.status === 404) {
            return [];
        }

        const comments: CourseComment[] = await decodeResponse(response);
        return comments;
    }

    async postCourseComment(courseId: number, newComment: string): Promise<CourseComment> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/comments`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'text/plain',
            },
            body: newComment,
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
        return (await decodeResponse(response))
    }

    async putCourseComment(courseId: number, commentId: string, newComment: string): Promise<CourseComment> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/comments/${commentId}`, {
            method: "PUT",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'text/plain',
            },
            body: newComment,
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
        return (await decodeResponse(response))
    }

    async deleteCourseComment(courseId: number, commentId: string): Promise<void> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/comments/${commentId}`, {
            method: "DELETE",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
        }
    }

    async getPreviousCourses(courseId: number): Promise<Course[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/previous`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        return (await decodeResponse<CourseDto[]>(response)).map(this.mapRemoteCourseToCourse);
    }

    async getCourse(courseId: number): Promise<Course> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });
        await handleClientError(response);
        return this.mapRemoteCourseToCourse(await decodeResponse<CourseDto>(response));
    }

    async getCourseEnrollments(courseId: number): Promise<Enrollment[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/enrollments`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
        return decodeResponse(response);
    }

    async addCourseEnrollment(courseId: number, userId: number, roleId: number): Promise<Enrollment> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/enrollments`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userId": userId,
                "roleId": roleId,
            }),
        });
        await handleClientError(response);
        return decodeResponse(response);
    }

    async deleteCourseEnrollment(courseId: number, enrollmentId: number) {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/enrollments/${enrollmentId}`, {
            method: "DELETE",
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });
        await handleClientError(response);
    }

    async updateCourseEnrollment(courseId: number, enrollmentId: number, userId: number, enrollmentType: string): Promise<Enrollment> {

        // Delete enrollment
        const responseDelete = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/enrollments/${enrollmentId}`, {
            method: "DELETE",
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });
        await handleClientError(responseDelete);

        // Add enrollment
        const responseAdd = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/enrollments`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userId": userId,
                "enrollmentType": enrollmentType
            }),
        });
        await handleClientError(responseAdd);
        return decodeResponse(responseAdd);
    }

    async getCourseNotificationEmailTemplate(courseId: number): Promise<EmailNotification> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/notification`, {
            headers: {
                Authorization: await this.getAuthorizationHeader()
            }
        });

        return decodeResponse(response);
    }

    async postCopyOperation(destinationCourseId: number, sourceCourseId: number): Promise<void> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${destinationCourseId}/copyoperations`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "sourceCourseId": sourceCourseId,
            }),
        });
        await handleClientError(response);
        return Promise.resolve();
    }

    async updateCourseSetupState(courseId: number, setupState: CourseSetupState): Promise<Course> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}`, {
                method: "PUT",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    "setupState": setupState,
            }),
        });
        await handleClientError(response);
        return this.mapRemoteCourseToCourse(await decodeResponse<CourseDto>(response));
    }

    async postReleaseOperation(currentCourseId: number, emailNotification?: EmailNotification): Promise<void> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${currentCourseId}/releaseoperations`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "emailNotification": emailNotification,
            }),
        });
        await handleClientError(response);
        return Promise.resolve();
    }

    async postCourse(siteType: string, name: string, expiresAt: string): Promise<Course> {

        const response = await fetch(`${this.apiBaseUrl}/api/courses`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "siteType": siteType,
                "name": name,
                "expiresAt": expiresAt,
            }),
        });
        await handleClientError(response);
        return this.mapRemoteCourseToCourse(await decodeResponse<CourseDto>(response));
    }

    async updateCourseTitle(courseId: number, title: string): Promise<Course> {

        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}`, {
            method: "PUT",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": title,
            }),
        });
        await handleClientError(response);
        return this.mapRemoteCourseToCourse(await decodeResponse<CourseDto>(response));
    }

    async updateCourseExpiresAt(courseId: number, expiresAt: Date): Promise<Course> {

        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}`, {
            method: "PUT",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "expiresAt": expiresAt,
            }),
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
        return this.mapRemoteCourseToCourse(await decodeResponse<CourseDto>(response));
    }

    private mapRemoteCourseToCourse(remoteCourse: CourseDto): Course {
        return {
            canvasId: remoteCourse.canvasId,
            sisCourseId: remoteCourse.sisCourseId,
            name: remoteCourse.name,
            courseCode: remoteCourse.courseCode,
            setupState: remoteCourse.setupState,
            teachers: remoteCourse.teachers,
            startAt: remoteCourse.startAt ? parseISO(remoteCourse.startAt) : undefined,
            endAt: remoteCourse.endAt ? parseISO(remoteCourse.endAt) : undefined,
            expiresAt: remoteCourse.expiresAt ? parseISO(remoteCourse.expiresAt) : undefined,
            concluded: remoteCourse.concluded,
            canvasCourseUrl: remoteCourse.canvasCourseUrl,
            copyInProgress: remoteCourse.copyInProgress,
            siteType: remoteCourse.siteType,
            mirroredSections: remoteCourse.mirroredSections,
            account: remoteCourse.account,
            term: remoteCourse.term ? {
                canvasId: remoteCourse.term.canvasId,
                name: remoteCourse.term.name,
                startAt: remoteCourse.term.startAt ? parseISO(remoteCourse.term.startAt) : undefined,
                endAt: remoteCourse.term.endAt ? parseISO(remoteCourse.term.endAt) : undefined,
                workflowState: remoteCourse.term.workflowState,
                sisTermId: remoteCourse.term.sisTermId,
            } : undefined,
            termId: remoteCourse.termId,
            termYear: remoteCourse.termYear,
            commentCount: remoteCourse.commentCount,
        }
    }
    
    private mapSectionDtoToSection (rs: SectionDto): Section {
        return {
            canvasId: rs.canvasId,
            endAt: !rs.endAt ? undefined : parseISO(rs.endAt),
            startAt: !rs.startAt ? undefined : parseISO(rs.startAt),
            canvasCourseId: rs.courseId,
            name: rs.name,
            sisCourseId: rs.sisCourseId ?? undefined,
            sisSectionId: rs.sisSectionId ?? undefined,
            totalStudents: rs.totalStudents ?? undefined,
            deletingStatus: rs.deletingStatus,
        } as Section;
    };

    async postStaticStudentSection(courseId: number, name: string): Promise<Section> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/staticsections`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "sectionName": name,
            }),
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
        const secDto = await decodeResponse<SectionDto>(response);
        
        return this.mapSectionDtoToSection(secDto);
    }
    
    async putStaticStudentSectionName(canvasCourseId: number, canvasSectionId: number, newName: string): Promise<Section> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${canvasCourseId}/staticsections`, {
            method: "PUT",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "canvasSectionId": canvasSectionId,
                "newName": newName,
            }),
        });
        
        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
        const secDto = await decodeResponse<SectionDto>(response);
        
        return this.mapSectionDtoToSection(secDto)
    }

    async getStaticStudentEnrolments(sectionId: number) {
        const response = await fetch(`${this.apiBaseUrl}/api/sections/${sectionId}/enrollments`, {
            method: "GET",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }

        return await decodeResponse<EnrollmentDto[]>(response);
    }

    async postSectionEnrollment(sectionId: number, userSisId: string): Promise<Enrollment> {
        const response = await fetch(`${this.apiBaseUrl}/api/sections/${sectionId}/enrollments`, {
            method: "POST",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userSisId": userSisId,
            }),
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
        return await decodeResponse<Enrollment>(response);
    }

    async deleteSectionEnrollment(sectionId: number, enrollmentId: number): Promise<void> {
        const response = await fetch(`${this.apiBaseUrl}/api/sections/${sectionId}/enrollments/${enrollmentId}`, {
            method: "DELETE",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }
    }

    async postMirrorSectionOperation(destinationCourseId: number, sectionsToMirrorIds: number[]): Promise<void> {

        const fetchPromises: Promise<any>[] = [];
        for (const sectionToMirrorId of sectionsToMirrorIds) {
            const fetchPromise = fetch(`${this.apiBaseUrl}/api/courses/${destinationCourseId}/mirroredsections`, {
                method: "POST",
                headers: {
                    Authorization: await this.getAuthorizationHeader(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "sectionToMirrorId": sectionToMirrorId,
                }),
            });
            fetchPromises.push(fetchPromise);
        }

        const responses = await Promise.all(fetchPromises);

        for (const response of responses) {
            if (!response.ok) {
                const errJson = await response.json();
                if (errJson && errJson.message) {
                    throw new Error(errJson.message);
                } else {
                    throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
                }
            }
        }


        return Promise.resolve();
    }

    async downloadStaticSection(section: Section) {
        const response = await fetch(`${this.apiBaseUrl}/api/sections/${section.canvasId}/enrollments`, {
            method: "GET",
            headers: {
                Authorization: await this.getAuthorizationHeader(),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }

        const remoteEnrollments = await decodeResponse<EnrollmentDto[]>(response);

        const enrollmentsExport = remoteEnrollments.map((remoteEnrollment) => {
            return {
                UserSisId: remoteEnrollment.userSisId,
                RoleId: remoteEnrollment.roleId
            }
        });

        exportToCSV(enrollmentsExport, `static-section-${section.sisSectionId}`);
    }

    /**
     * Deletes a section from a course and returns the updated list of sections from canvas.
     * @param courseId the canvas course id (not SIS ID)
     * @param sectionId the canvas section id (not SIS ID)
     */
    async deleteSection(courseId: number, sectionId: number): Promise<Section[]> {
        const response = await fetch(`${this.apiBaseUrl}/api/courses/${courseId}/sections/${sectionId}`, {
            method: "DELETE",
            headers: {
                'Authorization': await this.getAuthorizationHeader(),
                'Accept': 'application/json'
            }
        });

        if (!response.ok) {
            const errJson = await response.json();
            if (errJson && errJson.message) {
                throw new Error(errJson.message);
            } else {
                throw new Error(`HTTP Error ${response.status} ${response.statusText}`);
            }
        }

        const sectionDtoList = await decodeResponse<SectionDto[]>(response);

        return sectionDtoList.map(sec => this.mapSectionDtoToSection(sec));
    }
}



export interface ICourseService {

}