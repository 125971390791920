import { createContext, ReactElement, useContext, useReducer} from 'react';
import {BlueprintSubscription, ContentMigration, CourseComment, Section} from "./models";
import {EditableFieldValue} from "./EditableField";

export const CoursesContext = createContext<CoursesListState|null>(null);

export const CoursesListDispatchContext = createContext<React.Dispatch<CoursesListAction>|null>(null);

export type FeatureProps = { children: ReactElement  }
export function CoursesListProvider(props: FeatureProps) {
  const [coursesListState, dispatch] = useReducer(
      coursesListReducer,
      initialCoursesListState
  );

  return (
    <CoursesContext.Provider value={coursesListState}>
      <CoursesListDispatchContext.Provider value={dispatch}>
        {props.children}
      </CoursesListDispatchContext.Provider>
    </CoursesContext.Provider>
  );
}

export function useCoursesList():CoursesListState {
  return useContext(CoursesContext) as CoursesListState;
}

export function useCoursesListDispatch(): React.Dispatch<CoursesListAction> {
  return useContext(CoursesListDispatchContext) as React.Dispatch<CoursesListAction>;
}

const initialCoursesListState: CoursesListState = {
  expandedCourseCardState: {},
  expandedCourseId: undefined,
  editingCourseCardState: {}
};


export interface CourseCardState {
  blueprintsLoading?: boolean;
  blueprints?: BlueprintSubscription[];
  contentMigrations?: ContentMigration[];
  sections?: Section[];
  comments?: CourseComment[];
  commentIdBeingEdited?: string;
}

export interface EditingCourseCardState {
  courseTitle?: EditableFieldValue;
  expiresAt?: EditableFieldValue;
  courseTitleIsEditing: boolean;
  expiresAtIsEditing: boolean;
}

export interface CoursesListState {
  expandedCourseId?: number;
  expandedCourseCardState: CourseCardState;
  editingCourseCardState: { [courseId: number]: EditingCourseCardState };
}

export function coursesListReducer(state: CoursesListState, action: CoursesListAction): CoursesListState {
  switch (action.name) {
    case "SetExpandedCourseId":
      if (state.expandedCourseId !== action.courseId) {
        return {
          ...state,
          expandedCourseId: action.courseId,
          expandedCourseCardState: {},
        };
      }
      return state;
    case "ResetExpandedCourseDetails":
      return {
        ...state,
        expandedCourseCardState: {},
      };
    case "ResetCourseDetailsContentMigrations":
      return {
        ...state,
        expandedCourseCardState: {...state.expandedCourseCardState, contentMigrations: undefined}
      };
    case "SetCourseBlueprints":
      if (state.expandedCourseId === action.courseId) {
        return {
          ...state,
          expandedCourseCardState: {...state.expandedCourseCardState, blueprints: action.blueprints},
        };
      }
      return state;
    case "SetCourseContentMigrations":
      if (state.expandedCourseId === action.courseId) {
        return {
          ...state,
          expandedCourseCardState: {...state.expandedCourseCardState, contentMigrations: action.contentMigrations},
        };
      }
      return state;
    case "SetCourseTitle":
      return {
        ...state,
        editingCourseCardState: {
          [action.courseId]: {
            ...state.editingCourseCardState?.[action.courseId],
            courseTitle: action.editedValue,
          },
        },
      };
    case "SetExpiresAt":
      return {
        ...state,
        editingCourseCardState: {
          [action.courseId]: {
            ...state.editingCourseCardState?.[action.courseId],
            expiresAt: action.editedValue,
          },
        },
      };
    case "SetCourseTitleIsEditing":
      return {
        ...state,
        editingCourseCardState: {
          [action.courseId]: {
            ...state.editingCourseCardState?.[action.courseId],
            courseTitleIsEditing: action.isEditing,
          },
        },
      };
      case "SetExpiresAtIsEditing":
        return {
          ...state,
          editingCourseCardState: {
            [action.courseId]: {
              ...state.editingCourseCardState?.[action.courseId],
              expiresAtIsEditing: action.isEditing,
            },
          },
        };
    case "SetCommentIdBeingEdited":
      return state.expandedCourseId === action.courseId ?
        {
          ...state,
          expandedCourseCardState: {
            ...state.expandedCourseCardState,
            commentIdBeingEdited: action.commentId
          },
        } : state;
    case "ClearCommentIdBeingEdited":
        return {
          ...state,
          expandedCourseCardState: {
            ...state.expandedCourseCardState,
            commentIdBeingEdited: undefined
          },
        };
  }
}

interface SetExpandedCourseId {
  name: "SetExpandedCourseId";
  courseId: number;

}
interface ResetExpandedCourseDetails {
  name: "ResetExpandedCourseDetails";

}

interface ResetCourseDetailsContentMigrations {
  name: "ResetCourseDetailsContentMigrations"
}

interface SetCourseBlueprints {
  name: "SetCourseBlueprints";
  courseId: number;
  blueprints: BlueprintSubscription[];
}

interface SetCourseContentMigrations {
  name: "SetCourseContentMigrations";
  courseId: number;
  contentMigrations: ContentMigration[];
}

interface SetCourseTitle {
  name: "SetCourseTitle";
  courseId: number;
  editedValue: EditableFieldValue;
}

interface SetExpiresAt {
  name: "SetExpiresAt";
  courseId: number;
  editedValue: EditableFieldValue;
}
interface SetExpiresAtIsEditing {
  name: "SetExpiresAtIsEditing";
  courseId: number;
  isEditing: boolean;
}

interface SetCourseTitleIsEditing {
  name: "SetCourseTitleIsEditing";
  courseId: number;
  isEditing: boolean;
}

interface SetCommentIdBeingEdited {
  name: "SetCommentIdBeingEdited";
  courseId: number;
  commentId?: string;
}

interface ClearCommentIdBeingEdited {
  name: "ClearCommentIdBeingEdited";
}

export type CoursesListAction =
    | SetExpandedCourseId
    | ResetExpandedCourseDetails
    | ResetCourseDetailsContentMigrations
    | SetCourseBlueprints
    | SetCourseContentMigrations
    | SetCourseTitle
    | SetExpiresAt
    | SetCourseTitleIsEditing
    | SetExpiresAtIsEditing
    | SetCommentIdBeingEdited
    | ClearCommentIdBeingEdited;