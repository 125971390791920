import { Alert } from "antd";

type SuccessAlertProps = {
    isSuccess: boolean;
    message: string;    
}

export default function SuccessAlert(props: SuccessAlertProps) { 

    if (!props.isSuccess) return null;
    
    return (
        <Alert
            message={props.message}
            type="success"
            showIcon
        />
  )
}
