/*
 * This file is "owned" by the UI package, and then copied into the Admin UI package.  If you need to modify them,
 * please do so in the UI package, and then copy the modified file into the Admin UI package using the shared-files.sh
 * script.
 */

import {SessionExpiredError, wsErrorFromResponse} from "../errors";
import {createContext, useContext} from "react";
import {SessionDto} from "../dtos";
import {SamRole} from "../models";

export interface Session {
    bearerToken?: string,
    samRoles: SamRole[],
    reauthenticationHint?: ReauthenticateHint,
}

export type ReauthenticateHint = {
    reauthenticateUrl: string,
    type: "lti"|"oidc",
}

export class SessionService {

    private readonly apiBaseUrl: string;
    private readonly canvasDomain: string;
    private sessionCache?: Session = undefined;
    private promiseCache?: Promise<Session> = undefined;

    constructor(canvasDomain: string, apiBaseUrl?: string) {
        this.apiBaseUrl = apiBaseUrl ? apiBaseUrl : "";
        this.canvasDomain = canvasDomain;
    }

    async getSession(): Promise<Session> {
        const session = await this.getSessionWithoutValidation()

        if(!session.bearerToken) {
            throw new SessionExpiredError();
        }

        return session;
    }

    async getReauthenticationHint(): Promise<ReauthenticateHint|undefined> {
        return (await this.getSessionWithoutValidation())?.reauthenticationHint;
    }

    private async getSessionWithoutValidation(): Promise<Session> {
        if(this.sessionCache) {
            return this.sessionCache;
        }
        if(this.promiseCache) {
            return Promise.resolve(this.promiseCache);
        }

        this.promiseCache = this.getSessionFromCanvasDataOrServer();
        this.sessionCache = await this.promiseCache;

        return this.sessionCache;
    }

    private async getSessionFromCanvasDataOrServer(): Promise<Session> {
        const sessionFromCanvas = await this.getSessionFromCanvasData();
        if(sessionFromCanvas) {
            return sessionFromCanvas;
        }

        const response = await fetch(`${this.apiBaseUrl}/auth/token`);
        if (!response.ok) {
            if(response.status === 401 || response.status === 403) {
                // If the session is expired, the session (assuming one exists) is still sent back,
                // without a bearerToken. This is because it contains hints for reauthenticating.
                const session = (await response.json()) as SessionDto|undefined;
                if(session) {
                    return session as Session;
                }
            }
            throw wsErrorFromResponse(response);
        }
        return (await response.json()) as Session;
    }

    private async getSessionFromCanvasData(): Promise<Session|undefined> {
        // If this is not in a frame, it cannot be embedded in Canvas
        if(window.top === window.self) {
            return Promise.resolve(undefined);
        } else {
            try {
                // If the top frame is the app itself, it cannot be Canvas (and is probably Storybook)
                if(window.top?.origin === window.self?.origin) {
                    return Promise.resolve(undefined);
                }
            } catch (e) {
                // If we cannot access to top, then presumably the app is embedded in Canvas, which is ensured by
                // the origin checks in the messages.
            }
        }

        return new Promise((resolve, reject) => {
            const timeout = window.setTimeout(() => {
                reject(new Error("Timed out waiting for bearer token from Canvas"));
            }, 5000);
            const getBearerTokenMessageId = crypto.randomUUID();

            const listener = (event: WindowEventMap["message"]) => {
                window.clearTimeout(timeout);
                window.removeEventListener("message", listener);

                if (!event.origin || event.origin !== `https://${this.canvasDomain}`) {
                    reject(new Error("Unexpected origin"));
                    return;
                }

                if (!event.data) {
                    reject(new Error("No data"));
                    return;
                }

                if (event.data.message_id !== getBearerTokenMessageId) {
                    reject(new Error("Message ID doesn't match"));
                    return;
                }

                if (!event.data.value) {
                    resolve(undefined);
                    return;
                }

                const session = JSON.parse(event.data.value) as Session;
                resolve(session);
            }

            window.addEventListener("message", listener);
            window?.top?.postMessage(
                {
                    subject: 'org.imsglobal.lti.get_data',
                    key: 'sam-session',
                    message_id: getBearerTokenMessageId,
                },
                `https://${this.canvasDomain}`,
            )
        });
    }



}

export const SessionContext = createContext<Session>({
    bearerToken: "",
    samRoles: [],
});

export const useSession = () => {
    return useContext(SessionContext);
}
