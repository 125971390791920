import {Alert, Modal, Spin} from "antd";
import "./copycontentsearch.css"
import {Course, CoursesPage} from "./models";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {CopyContentSearch} from "./CopyContentSearch";
import CourseService from "./services/CourseService";
import {useReducer} from "react";
import {CopyContentConfirm} from "./CopyContentConfirm";

export interface CopyContentModalProps {
    courseService: CourseService;
    fetchingCourse: boolean;
    currentCourse?: Course;
    onCancel: () => void;
    onCopyOperationPosted: () => void;
    onCloseAnimationComplete?: () => void;
    open: boolean;
}

interface SetItems {
    name: "SetItems";
    items: Course[];
}

interface SetSourceCourse {
    name: "SetSourceCourse";
    sourceCourse: Course;
}

interface ConfirmCopy {
    name: "ConfirmCopy"
}

interface ConfirmCopyToConcludedCourse {
    name: "ConfirmCopyToConcludedCourse";
    confirmCopyToConcludedCourse: boolean;
}
interface SetSearchTerm {
    name: "SetSearchTerm";
    searchTerm: string;
}

export type CopyContentModalAction =
    | SetItems
    | SetSourceCourse
    | ConfirmCopy
    | ConfirmCopyToConcludedCourse
    | SetSearchTerm

function copyContentModalReducer(state: CopyContentModalState, action: CopyContentModalAction): CopyContentModalState {
    switch (action.name) {
        case "SetItems":
            return {
                ...state,
                items: action.items,
            };
        case "SetSourceCourse":
            return {
                ...state,
                sourceCourse: action.sourceCourse,
            };
        case "ConfirmCopy":
            return {
                ...state,
                confirmed: true,
            }
        case "ConfirmCopyToConcludedCourse":
            return {
                ...state,
                confirmCopyToConcludedCourse: true,
            }
        case "SetSearchTerm":
            return {
                ...state,
                searchTerm: action.searchTerm,
            }
    }
}

interface CopyContentModalState {
    items?: Course[],
    pageCount?: number,
    pageNumber?: number,
    sourceCourse?: Course,
    confirmed: boolean,
    confirmCopyToConcludedCourse: boolean,
    searchTerm?: string,
}

interface PostCopyOperationVars {
    sourceCourseId: number,
    destinationCourseId: number
}

export const CopyContentModal = ({...props}: CopyContentModalProps) => {
    const [state, dispatch] = useReducer(copyContentModalReducer, {confirmed: false, confirmCopyToConcludedCourse: false})

    useQueryClient();

    const { data: previousCourses, isFetching: fetchingPreviousCourses, isError: fetchingPreviousCoursesError} = useQuery<Course[], Error>(["copymodal-previouscourses", props.currentCourse?.canvasId],
        () => {
            if (props.currentCourse) {
                return props.courseService.getPreviousCourses(props.currentCourse.canvasId);
            } else {
                throw new Error("Current course ID is null");
            }
        }, {
            enabled: !!props.currentCourse?.canvasId && !state.searchTerm,
            // onSuccess: data => dispatch({name: "SetItems", items: data}),
        });

    const { data: searchResults, isFetching: fetchingSearchCourses, isError: fetchingSearchCoursesError} = useQuery<CoursesPage, Error>(["copymodal-searchcourses", state.searchTerm, state.pageNumber],
        () => {
            return props.courseService.getAllCourses(undefined, undefined, undefined, state.searchTerm, undefined, undefined, undefined, true);
        }, {
            enabled: !!state.searchTerm,
            // onSuccess: data => dispatch({name: "SetItems", items: data.courses.filter(c => props.currentCourse?.canvasId !== c.canvasId)}),
        });

    const { mutate: postCopyOperation, isLoading: postCopyOperationIsLoading, isError: postCopyOperationError } = useMutation(
        ["postcopyoperation", props.currentCourse?.canvasId, state.sourceCourse?.canvasId],
        (vars: PostCopyOperationVars) => props.courseService.postCopyOperation(vars.destinationCourseId, vars.sourceCourseId),
        {
            onSuccess: () => {
                props.onCopyOperationPosted();
            },
        }
    );

    let content: JSX.Element;
    let displayFooter = false;
    let okText = "Perform Copy";
    if (props.fetchingCourse || !props.currentCourse || postCopyOperationIsLoading) {
        content = <Spin/>
    } else if (props.currentCourse.concluded  && !state.confirmCopyToConcludedCourse) {
        content = <Alert type="warning" message="The destination course is concluded." description="Are you sure you wish to continue the copy content operation?" showIcon/>
        okText = "Continue";
        displayFooter = true;
    } else if (!state.sourceCourse) {
        content = <CopyContentSearch
            courseName={props.currentCourse.name}
            courseId={props.currentCourse.canvasId}
            fetchingItems={fetchingPreviousCourses || fetchingSearchCourses}
            errorFetchingItems={fetchingSearchCoursesError || (!state.searchTerm && fetchingPreviousCoursesError) }
            items={searchResults?.courses || previousCourses}
            onSelectSourceCourse={course => dispatch({name: "SetSourceCourse", sourceCourse: course})}
            onSearch={searchTerm => dispatch({name: "SetSearchTerm", searchTerm})}
        />
    } else {
        content = <CopyContentConfirm
            fromName={state.sourceCourse.name}
            fromTerm={state.sourceCourse.term?.name}
            fromSisId={state.sourceCourse.sisCourseId}
            toName={props.currentCourse.name}
            toTerm={props.currentCourse.term?.name}
            toSisId={props.currentCourse.sisCourseId}
            error={postCopyOperationError ? "Failed to copy content" : undefined}
        />;
        displayFooter = true;
    }

    const onOk = () => {
        if (props.currentCourse?.concluded && !state.confirmCopyToConcludedCourse) {
            dispatch({name: "ConfirmCopyToConcludedCourse", confirmCopyToConcludedCourse: true})            
        } else if (!state.sourceCourse) {
            throw new Error("No source course selected");
        } else if (!props.currentCourse) {
            throw new Error("No destination course loaded");
        } else {
            return postCopyOperation({
                sourceCourseId: state.sourceCourse.canvasId,
                destinationCourseId: props.currentCourse.canvasId
            });
        }
    }

    return <Modal title="Copy Content"
                  destroyOnClose={true}
                  open={props.open}
                  footer={displayFooter ? undefined : null}
                  onCancel={props.onCancel}
                  onOk={onOk}
                  okText={okText}
                  afterClose={props.onCloseAnimationComplete}>
        {content}        
    </Modal>
}

