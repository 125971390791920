import { Alert, Empty, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { Term } from "./models";
import { TermSummaryCard } from "./TermSummaryCard";

interface TermSummaryListProps {
    terms?: Term[];
    error?: Error | null;
    fetchingTerms: boolean;
    showPercentages: boolean;
}

export const TermSummaryList = (props : TermSummaryListProps) => {

    if (props.fetchingTerms) {
       return <Spin/>
    }

    if (props.error) {
        return <Alert
                    message="Error"
                    description={props.error?.message}
                    type="error"
                    showIcon
                    />
    }

    const currentDate = new Date();

    const activeTerms: Term[] = [];

    const inActiveTerms: Term[] = [];

    for (const term of props.terms ?? []) {
        (term.startAt && term.endAt && (term.startAt < currentDate && term.endAt > currentDate))?
            activeTerms.push(term) : inActiveTerms.push(term);
    }

    return <div>
                {(activeTerms.length > 0)? <div>
                    <Title level={4}>Active Terms</Title>
                    {activeTerms.map((term) => {
                        return <TermSummaryCard
                        title={`Teaching Period: ${term.name}`}
                        stats={term.courseStats ?? {}}
                        current={true}
                        expanded={false}
                        term={term}
                        key={term.canvasId}
                        showPercentages={props.showPercentages}
                    />
                    })}
                </div> : <></> }
                <div>
                    <Title level={4}>Inactive Terms</Title>
                    {(inActiveTerms.length > 0)? inActiveTerms.map((term) => {
                        return <TermSummaryCard
                        title={`Teaching Period: ${term.name}`}
                        stats={term.courseStats ?? {}}
                        current={false}
                        expanded={false}
                        term={term}
                        key={term.canvasId}
                        showPercentages={props.showPercentages}
                    />
                    }): <Empty/>}
                </div>
            </div>
};