import {MessagesConfig} from "../models";
import {usePublicClientConfig} from "../services/PublicClientConfigService";
import {defaultMessages} from "./defaultMessages";


export const getMessageOrDefault = (messages: MessagesConfig | undefined, messageKey: keyof MessagesConfig) => {
    return messages?.[messageKey] ?? defaultMessages[messageKey];
}

export const useMessage = (messageKey: keyof MessagesConfig): string => {
    const publicClientConfig = usePublicClientConfig();
    return getMessageOrDefault(publicClientConfig?.messages, messageKey);
}

export const useMessages = (keys: Array<keyof MessagesConfig>): Array<string>  => {
    const publicClientConfig = usePublicClientConfig();

    return keys.map(k => getMessageOrDefault(publicClientConfig?.messages, k));
}