/*
 * This file is "owned" by the UI package, and then copied into the Admin UI package.  If you need to modify them,
 * please do so in the UI package, and then copy the modified file into the Admin UI package using the shared-files.sh
 * script.
 */

import {ActivityLogEntryDto,  MirroredSectionDto, UserDTO} from "./dtos";
import {Duration} from "date-fns";

export type Config = {
    domain: string;
    canvasHostDomain: string;
    ltiJwksCacheMinutes: number;
    canvasInstanceId: number;
    sessionDuration: Duration;
    // The session should be retained after it is deleted so that the re-authentication URL is accessible.
    sessionDeleteDuration: Duration;
    ltiLoginStateDuration: Duration;
    emailNotification: EmailNotificationConfig;
    userManagement: UserManagementConfig;
    adminRoles?: string[],
    courseAdminRoles?: string[],
    canvasClientIds: string[],
    sisCourseCodePattern?: string,
    siteTypes?: SiteTypeConfigMap,
    timezone: string,
    courseMenu: CourseMenuItemConfig[]
    oidc?: OidcConfig,
    messages?: MessagesConfig,
    courseCopyContentTypes?: string[],
    integrationUserId?: number,
    systemAdmins?: string[];
}

export type ProviderConfig = {
    key: string,
    issuer: string,
    clientMetadata: string,
    authorizationParameters: string,
    sisUserIdClaim?: string;
}

export type IssuerConfig = {
    issuer: string,
    deletable: boolean,
}

export type OidcConfig = {
    providers: {
        [providerKey: string]: ProviderConfig|undefined,
    }
}

export type EmailNotificationSettings = {
    fromAddress?: string
    replyAddress?: string,
}

export type EmailNotificationConfig = EmailNotificationSettings & {
    releaseEmail: EmailTemplateConfig
    enrollmentEmail: EmailTemplateConfig
}

export type UserManagementSisIdPatternsConfig = {
    managedSectionSisIdTemplate?: string,
    managedSectionSisIdPattern?: string,
    userSisIdPattern?: string,
}

export type SisIdPatternsConfig = UserManagementSisIdPatternsConfig & {
    sisCourseCodePattern?: string,
}

export type UserManagementConfig = UserManagementSisIdPatternsConfig & {
    roles: RoleConfigMap,
    rolePermissions: {
        [actingRole: string]: RolePermissionMap,
    },
}

export enum CourseSetupState {
    AWAITING_COPY = 'awaiting_copy',
    AWAITING_REVIEW = 'awaiting_review',
    RELEASED = 'released',
    AVAILABLE = 'available',
    DELETED = 'deleted',
    COMPLETED = 'completed',
}

export interface Course {
    canvasId: number,
    sisCourseId?: string,
    name: string,
    courseCode: string,
    setupState: CourseSetupState,
    teachers?: CourseUser[],
    startAt?: Date,
    endAt?: Date,
    concluded?: boolean,
    expiresAt?: Date,
    canvasCourseUrl: string,
    copyInProgress: boolean,
    siteType: string,
    mirroredSections?: {[sisSectionId: string]: MirroredSection},
    account?: Account,
    term?: Term,
    termId: number,
    termYear?: string,
    commentCount: number,
}

export interface MirroredSection extends MirroredSectionDto {}

export interface CoursesPage {
    lastEvaluatedCourseId?: number,
    courses: Course[],
}



export interface CourseUser {
    enrollmentId: number,
    userId: number,
    roleId: number,
    role: string,
    sisUserId?: string,
    displayName: string,
    sortableName: string,
    shortName: string,
    enrollmentState: "active" | "invited" | "inactive" | "completed" | "deleted" | "creation_pending",
}

export interface Enrollment {
    id: number,
    displayName: string,
    role: string,
    userId?: number
    roleId: number,

}

export interface Account {
    parentAccountId?: number | null,
    name: string,
    canvasId: number,
    treePath: string,
}

export interface Term {
    canvasId: number;
    name: string;
    startAt?: Date;
    endAt?: Date;
    workflowState: 'active'|'deleted'
    sisTermId?: string;
    courseStats?: {
        [key in CourseSetupState]?: number
    }
}

export interface Section {
    canvasId: number;
    name: string;
    sisSectionId?: string | null;
    canvasCourseId: number
    sisCourseId?: string,
    startAt?: Date | null;
    endAt?: Date | null;
    totalStudents?: number;
    deletingStatus: "not-deleting" | "deleting" | "error",
}


export interface BlueprintSubscription {
    canvasId: number;
    /** The ID of the blueprint template the associated course is subscribed to */
    templateId: number;
    /** The blueprint course subscribed to */
    blueprintCourse: {
        id: number;
        name: string;
        courseCode: string;
        termName:string
    }
}


export interface ContentMigration {
    canvasId: number,
    migrationType: string,
    migrationTypeTitle: string,
    migrationIssuesUrl: string,
    progressUrl: string,
    userId: number,
    workflowState: "pre_processing" | "pre_processed" | "running" | "waiting_for_select" | "completed" | "failed",
    startedAt?: Date,
    finishedAt?: Date,
    createdAt?: Date,
    migrationIssuesCount?: number,
    settings?: {
        sourceCourseId?: number,
        sourceCourseName?: string,
        sourceCourseHtmlUrl?: string,
    },
    preAttachment?: {
        uploadUrl: string,
        message: string,
        uploadParams: {
            [key: string]: string,
        }
    }
    attachment?: {
        url: string
    },

}

export interface User extends UserDTO {} //Currently these two types have the same fields, so we'll just extend one from the other.

export interface EmailNotification {
    subjectTemplate?: string,
    bodyTemplate?: string,
}

export interface CourseAvailStatement {
    id: string,
    description: string,
}

export interface Role {
    label: string,
    role: string,
    roleId: number,
    userCanEdit: boolean,
    sortOrder?: number,
}

export enum CourseMenuItemIcon {
    Read = "Read",
    NodeIndex = "NodeIndex",
    Shop = "Shop",
    Diff = "Diff",
    Rocket = "Rocket",
    Swap = "Swap",
    Book = "Book",
    PlayCircle = "PlayCircle",
    QuestionCircle = "QuestionCircle",
    CheckSquare = "CheckSquare",
    InfoCircle = "InfoCircle",
    Copy = "Copy",
    Aim = "Aim",
    Bank = "Bank",
    Calculator = "Calculator",
    Bulb = "Bulb",
    Camera = "Camera",
    Comment = "Comment",
    Code = "Code",
    Compass = "Compass",
    Dashboard = "Dashboard",
    Experiment = "Experiment",
    FundProjectionScreen = "FundProjectionScreen",
    Global = "Global",
    History = "History",
    Layout = "Layout",
    MedicineBox = "MedicineBox",
    Notification = "Notification",
    ShoppingCart = "ShoppingCart",
    Shopping = "Shopping",
    Star = "Star",
    Team = "Team",
    Tag = "Tag",
    Tool = "Tool",
    Trophy = "Trophy",
    User = "User",
    VideoCamera = "VideoCamera",
    Unlock = "Unlock",
    Lock = "Lock",
    Home = "Home"
}

export type CourseMenuItem = {
    name: string;
    siteTypes: string[];
    sortOrder: number;
    hideYearAndTermFilters?: boolean;
    defaultAccountId?: number;
    icon?: CourseMenuItemIcon;
}

export type PublicClientConfig = {
    canvasHostDomain: string;
    releaseNotifyRoles: string[];
    siteTypeToPublishRoleMap: {[key: string]: string};
    includedInTermSummaryReportRoles: string[];
    courseMenu: CourseMenuItem[]
    userCreatableSiteTypes: { key: string, label: string, sortOrder: number }[];
    messages?: MessagesConfig
}

export type ActivityLogEntry = ActivityLogEntryDto & {
    logTimeJsDate?: Date,
}

export type ActivityLogsPage = {
    lastEvaluatedLogEntryId?: number,
    logs: ActivityLogEntry[],
};

export interface StaticStudentSectionEnrol {
    UserSisId: string;
    RoleId: number;
}

export type Operation = "copy" | "makeAvailable" | "release" | "manageUsers" | "addMirroredSection" | "addStaticStudentSection" | "updateStaticStudentSection" | "addStudentToStaticStudentSection"

export const AdminRole = "admin"
export const SystemAdminRole = "system_admin"
export type SamRole = typeof AdminRole | typeof SystemAdminRole

export interface CourseAvailStatementConfig {
    id: string,
    description: string,
}

export type SiteTypeConfig = {
    code: string,
    name: string,
    regex: string,
    sortOrder: number,
    courseAvailabilityStatements?: CourseAvailStatementConfig[],
    roleRequiredForCoursePublishing?: string,
} & ({
    userCreatable: true,
    newSiteAccountId?: number,
    newSiteBlueprintId?: number,
} | {
    userCreatable: false,
})

export type SiteTypeConfigMap = {[siteType: string]: SiteTypeConfig};

export type CourseMenuItemConfig = {
    name: string;
    siteTypes: string[];
    sortOrder: number;
    hideYearAndTermFilters?: boolean;
    defaultAccountId: number;
    icon?: CourseMenuItemIcon;
}

export type MessagesConfig = {
    ltiUnauthorized?: string;
    contactInformation?: string,
    courseStateDescriptionAwaitingCopy?: string,
    courseStateDescriptionAwaitingReview?: string,
    courseStateDescriptionReleased?: string,
    courseStateDescriptionAvailable?: string,
    courseStateDescriptionCompleted?: string,
    staffEnrollmentToolsCourseConcluded?: string,
    coursePublishInvalidStaffWarning?: string,
    coursePublishInvalidStaffWarningConfirm?: string,
    staticStudentFileHelp?: string,
    courseReleaseModalTitle?: string,
    courseReleaseModalDescription?: string,
    courseReleaseModalReleaseError?: string,
    courseCardActionButtonSkip?: string,
    courseCardActionButtonCopy?: string,
    courseCardActionButtonRelease?: string,
    courseCardActionButtonPublish?: string,
    courseCardActionButtonManageStaff?: string,
    courseCardActionButtonManageStudents?: string,
    expiredSessionTitle?: string,
    expiredSessionLti?: string,
    staffEnrolmentToolHelpText?: string,
}

export type EmailTemplateConfig = {
    subjectTemplate: string,
    bodyTemplate: string,
}

export type RoleConfig = {
    sortOrder: number,
    displayInCourseCard: boolean,
    includedInTermSummaryReport: boolean,
    receivesReleaseEmail: boolean,
}

export type RoleConfigMap = {
    [role: string]: RoleConfig,
}

export type RolePermissionConfig = {
    view: boolean,
    edit: boolean,
};

export type RolePermissionMap = {
    [permissionRole: string]: RolePermissionConfig,
};

export type NamedRoleConfig = RoleConfig & {
    name: string,
}

export type NamedRolePermissionsList = {
    name: string,
    permissions: RolePermissionMap
}

export type CourseComment = {
    courseId: number,
    commentId: string,
    sisUserId: string,
    timestamp: string,
    name: string,
    content: string,
    editable: boolean,
    deletable: boolean,
    edited: boolean,
}

