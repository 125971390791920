import './auditlogentry.css';
import './auditlogs.css';
import {AuditLogEntryComponent} from "./AuditLogEntryComponent";
import AuditLogsService from "./services/AuditLogsService";
import {useInfiniteQuery, useQueryClient} from "@tanstack/react-query";
import {ActivityLogsPage} from "./models";
import {useEffect, useReducer} from "react";
import {Alert, Button, Divider, Empty, Space, Spin, Typography} from 'antd';
import AuditLogFilters from "./AuditLogFilters";
import { useSearchParams } from "react-router-dom";
// import { exportToXLSX } from './utils/exportToXLSX';
import InfiniteScroll from "react-infinite-scroll-component";
import {ExportOutlined} from "@ant-design/icons";
import {exportToXLSX} from "./utils/export";


const { Title } = Typography;

export interface AuditLogsProps {
    auditLogsService: AuditLogsService;


}

export interface AuditLogsState {
    courseIdInputValue?: string;
    sectionIdInputValue?: string;
    targetUserIdInputValue?: string;
    principalIdInputValue?: string;
    eventSourceInputValue?: string;
    targetSystemInputValue?: string;
    excludeIntegrationUserInputValue: boolean;
    targetUserQueryValue?: string;
    targetCourseQueryValue?: string;
    targetSectionQueryValue?: string;
    principalQueryValue?: string;
    eventSourceQueryValue?: string;
    targetSystemQueryValue?: string;
    excludeIntegrationUserQueryValue: boolean;
    isExportingLogs: boolean;
}

type SetCourseIdInputValue = {
    name: "SetCourseIdInputValue",
    value: string
}
type SetSectionIdInputValue = {
    name: "SetSectionIdInputValue",
    value: string
}
type SetTargetUserIdInputValue = {
    name: "SetTargetUserIdInputValue",
    value: string
}
type SetPrincipalIdInputValue = {
    name: "SetPrincipalIdInputValue",
    value: string
}
type SetEventSourceInputValue = {
    name: "SetEventSourceInputValue",
    value: string
}
type SetTargetSystemInputValue = {
    name: "SetTargetSystemInputValue",
    value: string
}
type SetExcludeIntegrationUserInputValue = {
    name: "SetExcludeIntegrationUserInputValue",
    value: boolean
}

type SetTargetCourseQueryValue = {
    name: "SetTargetCourseQueryValue",
    value?: string
}
type SetTargetSectionQueryValue = {
    name: "SetTargetSectionQueryValue",
    value?: string
}
type SetTargetUserQueryValue = {
    name: "SetTargetUserQueryValue",
    value?: string
}
type SetPrincipalQueryValue = {
    name: "SetPrincipalQueryValue",
    value?: string
}
type SetEventSourceQueryValue = {
    name: "SetEventSourceQueryValue",
    value?: string
}
type SetTargetSystemQueryValue = {
    name: "SetTargetSystemQueryValue",
    value?: string
}
type SetExcludeIntegrationUserQueryValue = {
    name: "SetExcludeIntegrationUserQueryValue",
    value?: boolean
}
type SetExportingLogs = {
    name: "SetExportingLogs",
    exporting: boolean,
}

export type AuditLogsStateAction =
    | SetCourseIdInputValue | SetSectionIdInputValue
    | SetTargetUserIdInputValue | SetPrincipalIdInputValue | SetTargetCourseQueryValue
    | SetTargetSectionQueryValue | SetTargetUserQueryValue | SetPrincipalQueryValue
    | SetEventSourceInputValue | SetEventSourceQueryValue
    | SetTargetSystemInputValue | SetTargetSystemQueryValue
    | SetExcludeIntegrationUserInputValue | SetExcludeIntegrationUserQueryValue
    | SetExportingLogs;

function auditLogsStateReducer(state: AuditLogsState, action: AuditLogsStateAction): AuditLogsState {
    switch (action.name) {
        case "SetCourseIdInputValue":
            return {...state, courseIdInputValue: action.value};
        case "SetSectionIdInputValue":
            return {...state, sectionIdInputValue: action.value};
        case "SetPrincipalIdInputValue":
            return {...state, principalIdInputValue: action.value};
        case "SetTargetUserIdInputValue":
            return {...state, targetUserIdInputValue: action.value};
        case "SetEventSourceInputValue":
            return {...state, eventSourceInputValue: action.value};
        case "SetTargetSystemInputValue":
            return {...state, targetSystemInputValue: action.value};
        case "SetExcludeIntegrationUserInputValue":
            return {...state, excludeIntegrationUserInputValue: action.value};
        case "SetPrincipalQueryValue":
            return state.principalQueryValue !== action.value ? {...state, principalQueryValue: action.value, principalIdInputValue: action.value} : state;
        case "SetTargetCourseQueryValue":
            return state.targetCourseQueryValue !== action.value ? {...state, targetCourseQueryValue: action.value, courseIdInputValue: action.value} : state;
        case "SetTargetSectionQueryValue":
            return state.targetSectionQueryValue !== action.value ? {...state, targetSectionQueryValue: action.value, sectionIdInputValue: action.value} : state;
        case "SetTargetUserQueryValue":
            return state.targetUserQueryValue !== action.value ? {...state, targetUserQueryValue: action.value, targetUserIdInputValue: action.value} : state;
        case "SetEventSourceQueryValue":
            return state.eventSourceQueryValue !== action.value ? {...state, eventSourceQueryValue: action.value, eventSourceInputValue: action.value} : state;
        case "SetTargetSystemQueryValue":
            return state.targetSystemQueryValue !== action.value ? {...state, targetSystemQueryValue: action.value, targetSystemInputValue: action.value} : state;
        case "SetExcludeIntegrationUserQueryValue":
            return state.excludeIntegrationUserQueryValue !== action.value ? {...state, excludeIntegrationUserQueryValue: action.value ?? true, excludeIntegrationUserInputValue: action.value ?? true} : state;
        case "SetExportingLogs":
            return {...state, isExportingLogs: action.exporting }
        }
}
export const AuditLogs = ({auditLogsService} : AuditLogsProps) => {

    let [searchParams, setSearchParams] = useSearchParams();

    const targetCourseId = searchParams.get('targetCourseId') ?? undefined;
    const targetSectionId = searchParams.get('targetSectionId') ?? undefined;
    const targetUserId = searchParams.get('targetUserId') ?? undefined;
    const principalId = searchParams.get('principalId') ?? undefined;
    const eventSource = searchParams.get('eventSource') ?? undefined;
    const targetSystem = searchParams.get('targetSystem') ?? undefined;
    const excludeIntegrationUser = searchParams.get('excludeIntegrationUser') !== "false";

    useEffect(()=>{
        dispatch({name: "SetTargetCourseQueryValue", value: targetCourseId});
        dispatch({name: "SetTargetSectionQueryValue", value: targetSectionId});
        dispatch({name: "SetTargetUserQueryValue", value: targetUserId});
        dispatch({name: "SetPrincipalQueryValue", value: principalId});
        dispatch({name: "SetEventSourceQueryValue", value: eventSource});
        dispatch({name: "SetTargetSystemQueryValue", value: targetSystem});
        dispatch({name: "SetExcludeIntegrationUserQueryValue", value: excludeIntegrationUser});
    }, [targetCourseId, targetSectionId, targetUserId, principalId, eventSource, targetSystem, excludeIntegrationUser]);

    useQueryClient();

    const initialState: AuditLogsState = { isExportingLogs: false, excludeIntegrationUserQueryValue: true, excludeIntegrationUserInputValue: true };
    const [state, dispatch] = useReducer(auditLogsStateReducer, initialState);

    const {
        data: logPages,
        isLoading: loadingLogs,
        error: logLoadingError,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<ActivityLogsPage, Error>(
        ["alllogs", state.principalQueryValue, state.targetUserQueryValue, state.targetCourseQueryValue, state.targetSectionQueryValue, state.eventSourceQueryValue, state.targetSystemQueryValue, state.excludeIntegrationUserQueryValue],
        ({pageParam: lastEvaluatedLogEntryId}) => {
            return auditLogsService.getAuditLogs(lastEvaluatedLogEntryId,
                state.targetCourseQueryValue,
                state.targetSectionQueryValue,
                state.targetUserQueryValue,
                state.principalQueryValue,
                state.eventSourceQueryValue,
                state.targetSystemQueryValue,
                state.excludeIntegrationUserQueryValue);
        }, {
            getNextPageParam: lastPage => lastPage.lastEvaluatedLogEntryId,
        });

    const logs = logPages?.pages.flatMap(page => page.logs);

    let auditLogListContent;
    if (loadingLogs) {
        auditLogListContent = <Spin></Spin>
    } else if (logLoadingError) {
        auditLogListContent = <Alert
            message={logLoadingError.message}
            type="error"
            showIcon
        />
    } else if (logs === undefined) {
        auditLogListContent = <></>;
    } else if (logs.length === 0) {
        auditLogListContent = <Empty />;
    } else {
        auditLogListContent = <InfiniteScroll next={fetchNextPage} hasMore={!!hasNextPage} loader={<Spin />} dataLength={logs.length}>
            {logs.map((l) => {return <AuditLogEntryComponent auditLogEntry={l}/>;})}
        </InfiniteScroll>
    }

    const exportLogs = async () => {
        if(!logs) {
            return;
        }

        dispatch({name: "SetExportingLogs", exporting: true});
        let hasNextPage = undefined;
        do {
            const result = await fetchNextPage();
            hasNextPage = result.hasNextPage
        } while (hasNextPage);

        const nameParams = [
            state.targetCourseQueryValue,
            state.targetSectionQueryValue,
            state.targetUserQueryValue,
            state.principalQueryValue,
            state.eventSourceQueryValue,
            state.targetSystemQueryValue,
        ].filter(v => v !== undefined && v !== null && v.trim() !== "");
        exportToXLSX(logs, nameParams.join('-') + '_AuditLogs_' + new Date().toISOString());
        dispatch({name: "SetExportingLogs", exporting: false});
    }

    return <div className="auditlogs">
            <Title>Audit Logs</Title>
            <AuditLogFilters
                onCourseIdChanged={(val) => {dispatch({name: "SetCourseIdInputValue", value: val});}}
                courseId={state.courseIdInputValue }
                onSectionIdChanged={(val) => {dispatch({name: "SetSectionIdInputValue", value: val});}}
                sectionId={state.sectionIdInputValue}
                onTargetUserIdChanged={(val) => {dispatch({name: "SetTargetUserIdInputValue", value: val});}}
                userId={state.targetUserIdInputValue}
                onPrincipalIdChanged={(val) => {dispatch({name: "SetPrincipalIdInputValue", value: val});}}
                principalId={state.principalIdInputValue}
                onEventSourceChanged={(val) => {dispatch({name: "SetEventSourceInputValue", value: val});}}
                eventSource={state.eventSourceInputValue}
                onTargetSystemChanged={(val) => {dispatch({name: "SetTargetSystemInputValue", value: val});}}
                targetSystem={state.targetSystemInputValue}
                onExcludeIntegrationUserChanged={(val) => {dispatch({name: "SetExcludeIntegrationUserInputValue", value: val});}}
                excludeIntegrationUser={state.excludeIntegrationUserInputValue}
                onSearchClicked={() => {
                    const updatedSearchParams = new URLSearchParams();
                    if (state.courseIdInputValue && state.courseIdInputValue.trim().length > 0) {
                        updatedSearchParams.set("targetCourseId", state.courseIdInputValue);
                    }
                    if (state.sectionIdInputValue && state.sectionIdInputValue.trim().length > 0) {
                        updatedSearchParams.set("targetSectionId", state.sectionIdInputValue);
                    }
                    if (state.targetUserIdInputValue && state.targetUserIdInputValue.trim().length > 0) {
                        updatedSearchParams.set("targetUserId", state.targetUserIdInputValue);
                    }
                    if (state.principalIdInputValue && state.principalIdInputValue.trim().length > 0) {
                        updatedSearchParams.set("principalId", state.principalIdInputValue);
                    }
                    if (state.eventSourceInputValue && state.eventSourceInputValue.trim().length > 0) {
                        updatedSearchParams.set("eventSource", state.eventSourceInputValue);
                    }
                    if (state.targetSystemInputValue && state.targetSystemInputValue.trim().length > 0) {
                        updatedSearchParams.set("targetSystem", state.targetSystemInputValue);
                    }
                    if(!state.excludeIntegrationUserInputValue) {
                        updatedSearchParams.set("excludeIntegrationUser", "false");
                    }
                    setSearchParams(updatedSearchParams);}}
            />
        <Divider />
        <Space direction="vertical" className="auditlogs-results">
            <div className="auditlogs-actions">
                <Button icon={<ExportOutlined/>} onClick={exportLogs} disabled={!logs || logs.length === 0} loading={state.isExportingLogs}>
                    Export
                </Button>
            </div>
            {auditLogListContent}
        </Space>
    </div>

}
