import {useMessage} from "./utils/useMessages";
import {MessagesConfig} from "./models";

type MessageProps = {
    messageKey: keyof MessagesConfig,
    layout?: "inline"|"block",
}

const defaultProps = {
    layout: "block",
}

const Message = (props: MessageProps) => {
    const propsWithDefaults = {
        ...defaultProps,
        ...props,
    }

    const message = useMessage(propsWithDefaults.messageKey);

    const elementProperties = {
        dangerouslySetInnerHTML: { __html: message },
        "data-message-key": propsWithDefaults.messageKey
    }

    return propsWithDefaults.layout === "block" ?
        <div {...elementProperties} /> :
        <span {...elementProperties} />
};

export default Message;