import {createContext, PropsWithChildren, ReactNode, useCallback, useContext, useState} from "react";

type UploadStatus = "uploading" | "complete" | "error";

export type SectionUploadJob = {
    sectionId: number;
    sectionName: string;
    canvasCourseId: number;
    status: UploadStatus;
    progress: number;
};

export type UpdateJobFunction = (
    sectionName: string,
    newStatus: UploadStatus,
    newProgress: number
) => void;

export type SectionUploads = {
    uploadJobs: SectionUploadJob[];
    addUploadJob: (job: SectionUploadJob) => void;
    updateUploadJob: UpdateJobFunction;
    removeUploadJob: (sectionId: number) => void;
};

const initialUploadsState: SectionUploads = {
    uploadJobs: [],
    addUploadJob: () => {},
    updateUploadJob: () => {},
    removeUploadJob: () => {}
};
export const SectionUploadsContext = createContext<SectionUploads>(initialUploadsState);

type SectionUploadsProviderProps = {
    initialJobs?: SectionUploadJob[];
}

export const SectionUploadsProvider = (props: PropsWithChildren<SectionUploadsProviderProps>): ReactNode => {
    const [sectionUploads, setSectionUploads] = useState(props.initialJobs ?? []);
    
    const addJob = useCallback((job: SectionUploadJob) => {
        setSectionUploads((prevUploads) => [...prevUploads, job]);
    }, [setSectionUploads]);
    
    const updateJob: UpdateJobFunction = useCallback(
        (sectionName, newStatus, newProgress) => {
            setSectionUploads((prevUploads) => prevUploads.map((job) =>
                job.sectionName === sectionName
                    ? {...job, status: newStatus, progress: newProgress}
                    : job));
        }, [setSectionUploads]);
    
    const removeJob = useCallback((sectionId: number) => {
        setSectionUploads((prevUploads) => prevUploads.filter((job) => job.sectionId !== sectionId));
        
    }, [setSectionUploads]);
    
    return (
        <SectionUploadsContext.Provider
            value={{
                uploadJobs: sectionUploads,
                addUploadJob: addJob,
                updateUploadJob: updateJob,
                removeUploadJob: removeJob,
            }}
        >
            {props.children}
        </SectionUploadsContext.Provider>
    );
}

export function useSectionUploads() {
    const context = useContext(SectionUploadsContext);
    if (context === undefined) {
        throw new Error("useSectionUploads must be used within a SectionUploadsProvider");
    }
    return context;
}