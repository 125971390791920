import { useQuery } from "@tanstack/react-query";
import { Button } from "antd";
import { useContext } from "react";
import { Term } from "./models";
import { SummaryServiceContext } from "./ProgressSummary";
import { exportToXLSX } from "./utils/export";

interface MirroredSectionExportProps {
    term: Term
}

export default function SectionExport({ term }: MirroredSectionExportProps) {

    const summaryService = useContext(SummaryServiceContext);

    const { refetch, isFetching } = useQuery(["sectionsForTerm", term?.canvasId],
        () => {
            return summaryService?.getSectionsForTerm(term?.canvasId);
        }, {
            enabled: false,
            onSuccess(courses) {
                if (courses) exportToXLSX(courses, term?.sisTermId + '_Sections_' + new Date().toISOString());
            }
        });

    return (
        <Button onClick={(term)? () => refetch() : undefined} loading={isFetching}>Download cohort mapping data</Button>
    )
}