import {Button, Space, Spin, Col, Row, Tooltip, Dropdown, MenuProps, List, Tag, Alert} from 'antd';
import './coursecard.css';
import {
    BlueprintSubscription,
    ContentMigration,
    Section,
    CourseSetupState,
    CourseUser,
    Course, CourseComment, CoursesPage,
} from './models';
import Icon, {
    DownOutlined,
    UpOutlined,
    SyncOutlined,
    EditOutlined,
    EllipsisOutlined, AuditOutlined, InfoCircleOutlined
} from '@ant-design/icons';
import {ReactComponent as OpenInNewIcon} from './icons/open_in_new_FILL0_wght400_GRAD0_opsz48.svg';
import {useNavigate, useSearchParams} from "react-router-dom";
import {InfiniteData, useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import CourseService from "./services/CourseService";
import {usePublicClientConfig} from "./services/PublicClientConfigService";
import {mapCourseStateToLabel} from "./utils/CourseSetupState";
import {useCoursesList, useCoursesListDispatch} from "./CoursesContext";
import {useEffect, useRef} from "react";
import {CourseCardSectionListItem} from "./CourseCardSectionListItem";
import EditableField from './EditableField';
import {unique} from "./utils/ArrayUtils";
import Message from "./Message";
import CourseComments from "./components/CourseComments";
import {getMiddleWareErrorObj} from "./utils/errorUtils";
import CopyToClipboardButton from "./components/CopyToClipboardButton";


export interface CourseCardProps {
    courseService: CourseService;
    canvasCourseId: number;
    courseCode: string;
    title: string;
    expiresAt?: Date;
    concluded?: boolean;
    expanded: boolean;
    canToggleExpanded: boolean;
    accountName?: string | undefined;
    state: CourseSetupState;
    sisId?: string;
    teachers?: CourseUser[] | undefined;
    teachingPeriod?: string;
    canvasCourseUrl: string;
    onInitiateSkipCopy?: (canvasCourseId: number) => void;
    onInitiateCopyContent?: (canvasCourseId: number) => void;
    onInitiateCourseRelease?: (canvasCourseId: number) => void;
    onInitiateMakeAvailable?: (canvasCourseId: number) => void;
    onInitiateManageUsers?: (canvasCourseId: number) => void;
    onInitiateAddMirroredSection?: (canvasCourseId: number) => void;
    onInitiateAddStaticSection?: (canvasCourseId: number) => void;
    onInitiateUpdateStaticSection?: (canvasCourseId: number, canvasSectionId: number) => void;
    onInitiateAddStudentToStaticSection?: (canvasCourseId: number, canvasSectionId: number) => void;
    onCourseUpdated?: (course: Course) => void;
    copyInProgress: boolean;
    onToggleDetailView?: (canvasCourseId: number) => void;
    embeddedInCanvas: boolean;
    commentCount: number;
}

interface UpdateCourseSetupStateVars {
    courseId: number,
    setupState: CourseSetupState,
}


const allCoursesCommentCountUpdater = (courseId: number, commentCountUpdate: (previousCount: number) => number) => (
    infiniteData: InfiniteData<CoursesPage> | undefined
): InfiniteData<CoursesPage> | undefined => {
    if (!infiniteData) {
        return undefined;
    }

    return {
        ...infiniteData,
        pages: infiniteData.pages.map((p) => ({
            ...p,
            courses: p.courses.map((c) => {
                if (c.canvasId !== courseId) {
                    return c;
                }
                return {
                    ...c,
                    commentCount: commentCountUpdate(c.commentCount),
                };
            }),
        })),
    };
};

const useAllCoursesCommentCountUpdater = (courseId: number, commentCountUpdate: (previousCount: number) => number) => {
    return allCoursesCommentCountUpdater(courseId, commentCountUpdate);
};

export const CourseCard = (props: CourseCardProps) => {
    const modeCss = 'coursecard-state-' + props.state;
    const [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const ref = useRef<HTMLDivElement>(null);

    const coursesList = useCoursesList();
    const coursesListDispatch = useCoursesListDispatch();

    const clientConfig = usePublicClientConfig();

    useEffect(() => {
        if(props.canvasCourseId === coursesList.expandedCourseId) {
            const bounds = ref.current?.getBoundingClientRect();
            if(bounds && (bounds.top < 0 || bounds.top > window.innerHeight)) {
                ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }
    }, [coursesList.expandedCourseId, props.canvasCourseId]);

    let errContent: JSX.Element = <></>;

    useEffect(() => {coursesListDispatch({name: "ResetCourseDetailsContentMigrations"})}, [props.copyInProgress, coursesListDispatch]);

    const {isFetching: fetchingBlueprintSubscriptions, error: blueprintsError} = useQuery<BlueprintSubscription[], Error>(["blueprintSubscriptions", props.canvasCourseId, coursesList.expandedCourseCardState.blueprints ],
        async () => {
            return props.courseService.getBlueprintSubscriptions(props.canvasCourseId);
        }, {
            enabled: (props.expanded && coursesList.expandedCourseCardState.blueprints === undefined),
            onSuccess: (data: BlueprintSubscription[]) => {
                coursesListDispatch({name: "SetCourseBlueprints", courseId: props.canvasCourseId, blueprints: data});
            },
        });


    const {isFetching: fetchingContentMigrations, isError: isContentMigrationsError, error: contentMigrationsError} = useQuery<ContentMigration[], Error>(
        ["contentmigrations", props.canvasCourseId, coursesList.expandedCourseCardState.contentMigrations],
        () => {
            return props.courseService.getContentMigrations(props.canvasCourseId);
        }, {
            enabled: (props.expanded && coursesList.expandedCourseCardState.contentMigrations === undefined),
            onSuccess: (data: ContentMigration[]) => {
                coursesListDispatch({name: "SetCourseContentMigrations", courseId: props.canvasCourseId, contentMigrations: data});
            },
        });


    const { isFetching: fetchingSections, error: sectionsError, data: sections} = useQuery<Section[], Error>(["sections", props.canvasCourseId],
        () => {
            return props.courseService.getSections(props.canvasCourseId);
        }, {
            enabled: (props.expanded && coursesList.expandedCourseCardState.sections === undefined),
        });

    const {isFetching: fetchingComments, error: commentsError, data: comments} = useQuery<CourseComment[], Error>(["comments", props.canvasCourseId],
        async () => {
            const response = await props.courseService.getComments(props.canvasCourseId);
            return response.sort((a, b) => {
                return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
            });
        }, {
            enabled: (props.expanded && coursesList.expandedCourseCardState.comments === undefined),
            onSuccess: (data: CourseComment[]) => {
                queryClient.setQueriesData(["allcourses"], allCoursesCommentCountUpdater(props.canvasCourseId, () => data.length));
                queryClient.setQueriesData(["course", props.canvasCourseId], (existingCourse: Course | undefined) => {
                    return existingCourse ? {...existingCourse, commentCount: data.length } : undefined;
                });
            }
        });

    const {mutate: updateCourseSetupState, isLoading: updateStateIsLoading, isError: isSkipCopyError, error: skipCopyError} = useMutation(
        ["updatecoursesetupstate"],
        (vars: UpdateCourseSetupStateVars) => props.courseService.updateCourseSetupState(vars.courseId, vars.setupState),
        {
            onSuccess: (course: Course) => props.onInitiateSkipCopy?.(course.canvasId),
        }
    );

    const {mutate: updateCourseTitle, isLoading: updateCourseTitleIsLoading, isError: isModifyTitleError, error: modifyTitleErr} = useMutation(
        ["updatecoursetitle"],
        (vars: { courseId: number, title: string } ) => props.courseService.updateCourseTitle(vars.courseId, vars.title),
        {
            onSuccess: props.onCourseUpdated,
        }
    );

    const {mutate: updateCourseExpiresAt, isLoading: updateCourseExpiresAtIsLoading} = useMutation(
        ["updatecourseexpiresat"],
        (vars: { courseId: number, expiresAt: Date } ) => props.courseService.updateCourseExpiresAt(vars.courseId, vars.expiresAt),
        {
            onSuccess: props.onCourseUpdated,
        }
    );

    const addCommentUpdater = useAllCoursesCommentCountUpdater(props.canvasCourseId, count => count + 1);
    const {mutate: addCourseComment, isLoading: addCourseCommentIsLoading, isSuccess: addCourseCommentIsSuccess} = useMutation(
        (vars: { courseId: number, newComment: string } ) => props.courseService.postCourseComment(vars.courseId, vars.newComment),
        {
            onSuccess: async (newComment) => {
                queryClient.setQueryData(["comments", props.canvasCourseId], (existingComments: CourseComment[] | undefined) => {
                    return existingComments ? [...existingComments, newComment] : undefined;
                });
                queryClient.setQueriesData(["allcourses"], addCommentUpdater);
                queryClient.setQueriesData(["course", props.canvasCourseId], (existingCourse: Course | undefined) => {
                    return existingCourse ? {...existingCourse, commentCount: existingCourse.commentCount + 1} : undefined;
                });
            }
        }
    );

    const {mutate: updateCourseComment, isLoading: updateCourseCommentIsLoading} = useMutation(
        (vars: { courseId: number, commentId: string, newComment: string } ) => props.courseService.putCourseComment(vars.courseId, vars.commentId, vars.newComment),
        {
            onSuccess: async (updatedComment) => {
                queryClient.setQueryData(["comments", props.canvasCourseId], (existingComments: CourseComment[] | undefined) => {
                    return existingComments ? existingComments.map((c) => c.commentId === updatedComment.commentId ? updatedComment : c) : undefined;
                });
                coursesListDispatch({name: "SetCommentIdBeingEdited", courseId: props.canvasCourseId, commentId: undefined});
            }
        }
    );

    const deleteCommentUpdater = useAllCoursesCommentCountUpdater(props.canvasCourseId, count => count - 1)
    const {mutate: deleteCourseComment, isLoading: deleteCourseCommentIsLoad } = useMutation(
        ["deletecoursecomment"],
        (vars: { courseId: number, commentId: string } ) => props.courseService.deleteCourseComment(vars.courseId, vars.commentId),
        {
            onSuccess: (_, mutationProps) => {
                queryClient.setQueryData(["comments", props.canvasCourseId], (existingComments: CourseComment[] | undefined) => {
                    return existingComments ? existingComments.filter((c) => c.commentId !== mutationProps.commentId) : undefined;
                });
                queryClient.setQueriesData(["allcourses"], deleteCommentUpdater);
                queryClient.setQueriesData(["course", props.canvasCourseId], (existingCourse: Course | undefined) => {
                    return existingCourse ? {...existingCourse, commentCount: existingCourse.commentCount - 1} : undefined;
                });
            }
        }
    );

    const disableActions = props.copyInProgress || updateStateIsLoading || props.concluded;

    const stateString = mapCourseStateToLabel(props.state);
    let actionButtons;
    switch (props.state) {
        case CourseSetupState.AWAITING_COPY:
            actionButtons = <Space direction="horizontal">
                <Button
                    onClick={() => {
                        updateCourseSetupState({
                            courseId: props.canvasCourseId,
                            setupState: CourseSetupState.AWAITING_REVIEW,
                        });
                    }}
                    disabled={disableActions}
                    loading={updateStateIsLoading}><Message messageKey="courseCardActionButtonSkip" layout="inline" /></Button>
                <Button type="primary" onClick={() => props.onInitiateCopyContent?.(props.canvasCourseId)}>
                    <Message messageKey={"courseCardActionButtonCopy"} layout="inline" />
                </Button>
            </Space>
            break;
        case CourseSetupState.AWAITING_REVIEW:
            const disableReleaseButton = !props.teachers || !props.teachers.find(t => clientConfig.releaseNotifyRoles.includes(t.role));
            actionButtons = <>
                <Button type="primary" disabled={disableReleaseButton}
                        onClick={() => props.onInitiateCourseRelease?.(props.canvasCourseId)}><Message messageKey={"courseCardActionButtonRelease"} layout={"inline"} /></Button>
            </>
            break;
        case CourseSetupState.RELEASED:
            actionButtons = <>
                <Button type="primary" disabled={props.concluded} onClick={() => props.onInitiateMakeAvailable?.(props.canvasCourseId)}><Message messageKey={"courseCardActionButtonPublish"} layout={"inline"} /></Button>
            </>
            break;
    }

    let contentMigrationEl = <Spin size="small"/>;
    if (isContentMigrationsError) {
        contentMigrationEl = <>Error fetching content migrations for course! ({contentMigrationsError.message})</>
    } else if (props.expanded && !fetchingContentMigrations) {
        if (!coursesList.expandedCourseCardState?.contentMigrations || coursesList.expandedCourseCardState.contentMigrations.length === 0) {
            contentMigrationEl = <>No Content Migrations associated with this course.</>
        } else {
            const mostRecentCM = coursesList.expandedCourseCardState.contentMigrations[0];
            const spinner = (mostRecentCM.workflowState !== "completed" && mostRecentCM.workflowState !== "failed") ?
                <> <Spin size="small" indicator={<SyncOutlined spin/>}/></> :
                <></>
            if (mostRecentCM.migrationType === "course_copy_importer") {
                contentMigrationEl = <div>{mostRecentCM.settings?.sourceCourseName}{spinner}</div>
            } else {
                contentMigrationEl = <div>{mostRecentCM.migrationTypeTitle}{spinner}</div>
            }
        }
        contentMigrationEl = <>{contentMigrationEl}
            <div>
                {props.embeddedInCanvas ?
                    <a target={"_top"} href={`${props.canvasCourseUrl}/content_migrations`}>Open Content
                        Migrations</a> :
                    <a target={"_blank"}  rel="noreferrer" href={`${props.canvasCourseUrl}/content_migrations`}>Open Content Migrations in
                        Canvas <Icon component={OpenInNewIcon}/></a>}
            </div>
        </>
    }
    const titleBarOnClick = props.canToggleExpanded ? () => {
        if (props.expanded) {
            searchParams.delete("expandedCourse")
        } else {
            searchParams.set("expandedCourse", "" + props.canvasCourseId);
        }
        setSearchParams(searchParams);
    } : undefined;

    if (isModifyTitleError) {
        const clientError: any = getMiddleWareErrorObj(modifyTitleErr);
        errContent = <Space direction="vertical" className="site-management-alert-container">
            <Alert
                message={clientError.errorMessage}
                description={clientError.errorDesc}
                type="error"
                showIcon
            /></Space>;
    }

    if (isContentMigrationsError) {
        const clientError: any = getMiddleWareErrorObj(contentMigrationsError);
        errContent = <Space direction="vertical" className="site-management-alert-container">
            <Alert
                message={clientError.errorMessage}
                description={clientError.errorDesc}
                type="error"
                showIcon
            /></Space>;
    }

    if (isSkipCopyError) {
        const clientError: any = getMiddleWareErrorObj(skipCopyError);
        errContent = <Space direction="vertical" className="site-management-alert-container">
            <Alert
                message={clientError.errorMessage}
                description={clientError.errorDesc}
                type="error"
                showIcon
            /></Space>;
    }

    const manageStudentsMenuItems: MenuProps['items'] = [];
    manageStudentsMenuItems.push({
        key: 'addmirroredsection',
        label: ("Add Mirrored Section"),
        onClick: () => props.onInitiateAddMirroredSection?.(props.canvasCourseId),
        disabled: !props.onInitiateAddMirroredSection,
    });
    manageStudentsMenuItems.push({
        key: 'addstaticsection',
        label: ("Add Static Section"),
        onClick: () => props.onInitiateAddStaticSection?.(props.canvasCourseId),
        disabled: !props.onInitiateAddStaticSection,
    });

    const navigate = useNavigate();
    const overflowMenuItems: MenuProps['items'] = [
    ]
    if(!props.embeddedInCanvas) {
        overflowMenuItems.push({
            icon: <AuditOutlined />,
                key: 'auditlogs',
            label: "Audit Logs",
            onClick: () => navigate(`/auditlogs?targetCourseId=${props.sisId}`),
        });
    }

    let blueprintsContent = <></>;
    if (fetchingBlueprintSubscriptions) {
        blueprintsContent = <Spin size="small"/>;
    } else if (blueprintsError) {
        blueprintsContent = <>Error fetching blueprints for course! ({blueprintsError.message})</>
    } else if (!coursesList.expandedCourseCardState?.blueprints || coursesList.expandedCourseCardState.blueprints.length === 0) {
        blueprintsContent = <>No Blueprints associated with this course.</>;
    } else {
        blueprintsContent = <>{coursesList.expandedCourseCardState?.blueprints?.map((bp) => {
            return <div key={bp.canvasId}>{bp.blueprintCourse.name}</div>
        })}</>
    }

    let sectionsContent: JSX.Element;
    if (fetchingSections) {
        sectionsContent = <Spin size="small"/>;
    } else if (sectionsError) {
        sectionsContent = <>Error fetching sections for course! ({sectionsError.message})</>
    } else {
        sectionsContent = <List itemLayout={"horizontal"} size={"small"}>{sections?.map(
            (cs) => {
                return <CourseCardSectionListItem section={cs} courseService={props.courseService}
                            onInitiateUpdateStaticSection={(canvasSectionId) => props.onInitiateUpdateStaticSection?.(props.canvasCourseId, canvasSectionId)}
                            onInitiateAddStudentToStaticSection={(canvasSectionId) => props.onInitiateAddStudentToStaticSection?.(props.canvasCourseId, canvasSectionId)}
                            key={cs.canvasId} />
            })}</List>
    }
    let commentsContent: JSX.Element;
    if (fetchingComments) {
        commentsContent = <Spin size="small"/>;
    } else if (commentsError) {
        commentsContent = <>Error fetching comments for course! ({commentsError.message})</>
    } else {
        commentsContent = (<>
                <CourseComments
                    canvasCourseId={props.canvasCourseId}
                    comments={comments ?? []}
                    commentIdBeingEdited={coursesList.expandedCourseCardState.commentIdBeingEdited}
                    isFetchingComments={fetchingComments}
                    isDeletingComment={deleteCourseCommentIsLoad}
                    isAddingComment={addCourseCommentIsLoading}
                    isUpdatingComment={updateCourseCommentIsLoading}
                    isSuccessfulSubmit={addCourseCommentIsSuccess}
                    onAddComment={newComment => addCourseComment({ courseId: props.canvasCourseId, newComment })}
                    onUpdateComment={(commentId, newComment) => updateCourseComment({ courseId: props.canvasCourseId, commentId, newComment })}
                    onDeleteComment={(commentId) => deleteCourseComment({ courseId: props.canvasCourseId, commentId })}
                    maxDisplayedComments={6}
                />
            </>
        )
    }

    return (
        <div className={['coursecard', modeCss].join(' ')} key={props.canvasCourseId} ref={ref}>
            {errContent}
            <Row wrap={false} className={'coursecard-titlebar'}>
                {titleBarOnClick ?
                    <Col flex="45px" className={'coursecard-expandbutton'}>{(!props.expanded ? <DownOutlined/> :
                        <UpOutlined/>)}</Col> : <></>
                }
                <Col flex={"auto"} className={`coursecard-titlecontainer ${titleBarOnClick ? 'coursecard-titlecontainer-expandable' : ''}`} onClick={titleBarOnClick}>
                    <EditableField
                        value={props.title}
                        onEditComplete={async (editedValue) => {
                            typeof editedValue === "string" && updateCourseTitle({ courseId: props.canvasCourseId, title: editedValue });
                        }}
                        type="text"
                        className="coursecard-title"
                        onContainerClick={titleBarOnClick}
                        isSaving={updateCourseTitleIsLoading}
                        isEditing={coursesList.editingCourseCardState?.[props.canvasCourseId]?.courseTitleIsEditing}
                        editedValue={coursesList.editingCourseCardState?.[props.canvasCourseId]?.courseTitle ?? props.title}
                        setIsEditing={(isEditing) => {
                                coursesListDispatch({ name: "SetCourseTitleIsEditing", courseId: props.canvasCourseId, isEditing });
                            }
                        }
                        setEditedValue={(editedValue) =>
                            coursesListDispatch({ name: "SetCourseTitle", courseId: props.canvasCourseId, editedValue })
                        }
                    />
                </Col>
                {overflowMenuItems.length > 0 ?
                    <Col flex={"45px"} className={'coursecard-overflowbuttoncontainer'}>
                        <Dropdown menu={{items: overflowMenuItems}} placement="bottomRight" trigger={["click"]}>
                            <div className={'coursecard-overflowbutton'}><EllipsisOutlined/></div>
                        </Dropdown>
                    </Col> : <></>}
            </Row>
            <div className={'coursecard-body'}>
                <div className={'coursecard-summaryarea'}>
                    <Row>
                        <Col span={14} className={'coursecard-subtitle'}>
                            {props.sisId} <CopyToClipboardButton textToCopy={props.sisId ? props.sisId : ""} />
                        </Col>
                        <Col span={10} className={'coursecard-teachingperiod'}>{props.teachingPeriod ?? "No Term"}</Col>
                    </Row>
                    <Row align={"top"}>
                        <Col span={4} className={'coursecard-canvaslink'}>
                            {!props.embeddedInCanvas ?
                                <><a target={"_blank"} rel="noreferrer" href={props.canvasCourseUrl ? props.canvasCourseUrl : "" }>Open in Canvas <Icon
                                    component={OpenInNewIcon}/></a>
                                </>
                                : <></>}
                        </Col>
                        <Col span={10} className={'coursecard-school'}>
                            <strong>Account:</strong> {props.accountName ? props.accountName : "Unknown"}<br />
                            <strong>Canvas ID:</strong> <span>{props.canvasCourseId}</span>
                            <CopyToClipboardButton textToCopy={props.canvasCourseId.toString()} style={{margin: "5px"}} />
                        </Col>
                        <Col span={10} className={'coursecard-teachers'}>
                            <Space direction="vertical" size={"small"}>
                                <div><strong>Staff:</strong> {buildTeachersDom(props.teachers)}</div>
                                <Button onClick={() => props.onInitiateManageUsers?.(props.canvasCourseId)}
                                        icon={<EditOutlined />}
                                        disabled={props.concluded}>
                                    <Message messageKey={"courseCardActionButtonManageStaff"} layout={"inline"} />
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </div>


                {props.expanded ?
                    <div className={'coursecard-expandedarea'}>
                        {props.expiresAt ?
                        <Row>
                            <Col span={3} order={1} className={'coursecard-expandedarea-sectionheading coursecard-expiresat-label'}>Expiry Date:</Col>
                            <Col span={21} order={2}>
                                <EditableField
                                    value={props.expiresAt}
                                    onEditComplete={async (editedValue) => {
                                        editedValue instanceof Date && updateCourseExpiresAt({ courseId: props.canvasCourseId, expiresAt: editedValue });
                                    }}
                                    type="date"
                                    className="coursecard-expiresat"
                                    isSaving={updateCourseExpiresAtIsLoading}
                                    isEditing={coursesList.editingCourseCardState?.[props.canvasCourseId]?.expiresAtIsEditing}
                                    editedValue={coursesList.editingCourseCardState?.[props.canvasCourseId]?.expiresAt || props.expiresAt}
                                    setIsEditing={(isEditing) => {
                                            coursesListDispatch({ name: "SetExpiresAtIsEditing", courseId: props.canvasCourseId, isEditing });
                                        }
                                    }
                                    setEditedValue={(editedValue) =>
                                        coursesListDispatch({ name: "SetExpiresAt", courseId: props.canvasCourseId, editedValue })
                                    }
                                />
                            </Col>
                        </Row>
                           : <></>
                        }
                        <Row>
                            <Col span={4} order={1} className={'coursecard-expandedarea-sectionheading'}>
                                Blueprints:
                            </Col>
                            <Col span={14} order={2}>
                                {blueprintsContent}
                            </Col>
                            <Col span={6} order={3} className="coursecard-actionbuttoncontainer">
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} order={1} className={'coursecard-expandedarea-sectionheading'}>
                                Content From:
                            </Col>
                            <Col span={14} order={2}>
                                {contentMigrationEl}
                            </Col>
                            <Col span={6} order={3} className="coursecard-actionbuttoncontainer">
                                <Button onClick={() => props.onInitiateCopyContent?.(props.canvasCourseId)}>
                                    <Message messageKey={"courseCardActionButtonCopy"} layout="inline" />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} order={1} className={'coursecard-expandedarea-sectionheading'}>
                                Sections:
                            </Col>
                            <Col span={12} order={2} className="coursecard-actionbuttoncontainer">
                                <Dropdown menu={{ items: manageStudentsMenuItems }} placement="bottomLeft" trigger={["click"]} disabled={props.concluded}>
                                    <Button><Message messageKey={"courseCardActionButtonManageStudents"} layout={"inline"} /></Button>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {sectionsContent}
                            </Col>
                        </Row>
                    </div>
                    : <></>
                }
            </div>
            {props.expanded ?
                <div className={'coursecard-comments'}>
                    {commentsContent}
                </div>

                : <></>
            }
            <div className={'coursecard-bottombar'}>
                <Space className={'coursecard-statetextcolor-' + props.state}>
                    {stateString} {props.copyInProgress ?
                    <span className="coursecard-copyinprogress">
                        (Copy In Progress <Spin size="small" indicator={<SyncOutlined spin/>}/>)
                    </span> : ''}
                    {props.concluded ? <Tag color="green">Course Concluded &nbsp;
                        <Tooltip title={"Courses with end dates (either course or term) that have passed, or have been ended in the course settings area."} >
                            <InfoCircleOutlined />
                        </Tooltip></Tag> : undefined}
                    {props.commentCount > 0 ? <Tag color="blue">{props.commentCount} Comments</Tag> : undefined}
                </Space>
                <div className={'coursecard-bottombarbuttons'}>
                    {actionButtons}
                </div>
            </div>
        </div>
    );
};

const buildTeachersDom = (teachers: CourseUser[] | null | undefined) => {
    if (!teachers || teachers.length === 0) {
        return <>No Staff</>
    }

    const teacherNames = unique(teachers ?? [], t => t.userId).map(t => t.displayName);

    const displayCount = 3;

    const displayedDom = <>{teacherNames.slice(0, displayCount).map((teacherName) => {
        return <div className="coursecard-teacher">{teacherName}</div>
    })}</>

    if (teacherNames.length <= displayCount) {
        return displayedDom;
    }

    const extraTeachers = teacherNames.slice(displayCount);
    return <>
        {displayedDom}
        <Tooltip className="coursecard-teacher-tooltip" title={extraTeachers.map((teacherName) => {
            return <div>{teacherName}</div>
        })}>
            + {extraTeachers.length} more
        </Tooltip>
    </>;
}